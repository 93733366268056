<nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row default-layout-navbar">
  <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
    <a class="navbar-brand brand-logo" href="#"><img src="../../../assets/imgs/logo.svg" alt="logo" /></a>
    <a class="navbar-brand brand-logo-mini" href="#"><img src="../../../assets/imgs/bixi-logo.png" alt="logo" /></a>
  </div>

  <div class="navbar-menu-wrapper d-flex align-items-stretch bg-light shadowTopbarMenu">
    <ul class="navbar-nav navbar-nav-left mt-2">
      <ol *ngIf="pageName == 'home'" class="breadcrumb border-0">
        <li class="breadcrumb-item text-primary"><b>Sélection</b></li>
      </ol>
      <ol *ngIf="pageName == 'openWorkorder'" class="breadcrumb border-0">
        <li class="breadcrumb-item text-primary"><b>Bons de travail</b></li>
      </ol>
      <ol *ngIf="pageName == 'detailWorkorder'" class="breadcrumb border-0">
        <li class="breadcrumb-item">
          <a (click)="onBTClick('/openworkorder/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo +
          '' +
          woParameter)" href="javascript:">Bon de travail</a>
        </li>
        <li class="breadcrumb-item text-primary">
          <b>Éditer le bon de travail</b>
        </li>
      </ol>
      <ol *ngIf="pageName == 'archiveWorkorder'" class="breadcrumb border-0">
        <li class="breadcrumb-item">
          <a (click)="onBTClick('/openworkorder/' +
            currentUserCompany.id +
            '/' +
            currentProfile.id +
            '/serial-number/' +
            equipementNo +
            '' +
            woParameter)" href="javascript:">Bon de travail</a>
        </li>
        <li class="breadcrumb-item text-primary"><b>Archives</b></li>
      </ol>
      <ol *ngIf="pageName == 'templates'" class="breadcrumb border-0">
        <li class="breadcrumb-item">
          <a (click)="onBTClick('/openworkorder/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo +
          '' +
          woParameter)" href="javascript:">Bon de travail</a>
        </li>
        <li class="breadcrumb-item text-primary"><b>Templates</b></li>
      </ol>
      <ol *ngIf="pageName == 'templateMaker'" class="breadcrumb border-0">
        <li class="breadcrumb-item">
          <a href="#" [routerLink]="
              '/templates/' +
              currentUserCompany.id +
              '/' +
              currentProfile.id +
              '/serial-number/' +
              equipementNo
            ">Templates</a>
        </li>
        <li class="breadcrumb-item text-primary"><b>Template</b></li>
      </ol>
      <ol *ngIf="pageName == 'validationWorkorder'" class="breadcrumb border-0">
        <li class="breadcrumb-item">
          <a href="#" [routerLink]="
              '/home/' + currentUserCompany.id + '/' + currentProfile.id
            ">Sélection</a>
        </li>
        <li class="breadcrumb-item text-primary"><b>Validation</b></li>
      </ol>
      <ol *ngIf="pageName == 'bikepartslist'" class="breadcrumb border-0">
        <li class="breadcrumb-item">
          <a href="javascript: void(0)" (click)="goToDetailworkorder()">Bon de travail</a>
        </li>
        <li class="breadcrumb-item text-primary"><b>Pièces</b></li>
      </ol>
    </ul>
    <ul class="navbar-nav navbar-nav-right"></ul>
    <div class="btn-group float-right">
      <div class="list d-flex align-items-center py-2 mr-3" id="menuDropdown" data-bs-toggle="dropdown"
        data-bs-auto-close="true" aria-expanded="false" style="cursor: pointer">
        <i class="fa fa-id-badge menu-icon" [ngClass]="{
            textColorMec: currentProfile.profil === 'Mechanic',
            'textColorEval ': currentProfile.profil === 'Evaluator',
            'textColorSup ': currentProfile.profil === 'Superviseur'
          }" style="font-size: 32px"></i>
        <div class="wrapper w-100 ml-2">
          <p class="mb-0">
            <i class="fa fa-lock text-danger mr-1" *ngIf="isUserBlocked"></i>
            <b>{{ BIXI_userAuthObject.account.name }}</b>
          </p>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <p class="mb-0">
                {{ currentUserCompany.name }}
                <span *ngIf="currentProfile.profil === 'Supervisor'">[Superviseur]</span>
                <span *ngIf="currentProfile.profil === 'Mechanic'">[Mécanicien]</span>
                <span *ngIf="currentProfile.profil === 'Evaluator'">[Evaluateur]</span>
                <span *ngIf="currentProfile.profil === 'Administrator'">[Administrateur]</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ul class="dropdown-menu dropdown-menu-end border border-light rounded-0 bg-white shadowTopbarMenu"
        style="width: 210px" aria-labelledby="menuDropdown">
        <li class="nav-item dropdown ml-4">
          <div *ngFor="let userProfile of userProfiles; let l = index" class="form-check">
            <label class="form-check-label" [ngClass]="{
                'text-primary fw-bold':
                  currentProfile.profil === userProfile.profil,
                '': currentProfile.profil !== userProfile.profil
              }">
              <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios1"
                (change)="changeProfile(userProfile.profil)" [disabled]="
                  currentProfile.profil == userProfile.profil ? true : false
                " [checked]="
                  currentProfile.profil == userProfile.profil ? true : false
                " />
              {{
              userProfile.profil === "Supervisor"
              ? "Superviseur"
              : userProfile.profil === "Mechanic"
              ? "Mécanicien"
              : userProfile.profil === "Evaluator"
              ? "Evaluateur"
              : userProfile.profil === "Administrator"
              ? "Administrateur"
              : ""
              }}
              <i class="input-helper"></i></label>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link mt-3" (click)="logout()">
            <span class="btn btn-sm btn-primary d-block"><i class="fa fa-power-off mr-2"></i> Se déconnecter</span>
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf="pageName == 'detailWorkorder'" class="btn-group dropstart mr-2">
      <button class="btn border-0 p-0" id="menuDropdown" aria-expanded="false" data-bs-toggle="dropdown">
        <i class="fa fa-ellipsis-v"></i>
      </button>
      <ul class="dropdown-menu border border-light shadowTopbarMenu" style="margin-top: 60px">
        <li>
          <a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#noteModal">
            <span class="btn btn-outline-primary btn-sm d-block"><i class="fa fa-edit mr-1"></i> Notes<span
                class="badge badge-pill badge-outline-info ml-1 py-0 px-2">{{ noteData.length }}</span>
            </span>
          </a>
        </li>
        <li>
          <a class="nav-link" href="#" [routerLink]="
              '/bikepartslist/' +
              currentUserCompany.id +
              '/' +
              currentProfile.id +
              '/serial-number/' +
              equipementNo
            ">
            <span class="btn btn-outline-primary btn-sm d-block"><i class="fa fa-list mr-1"></i> Liste des pièces
            </span>
          </a>
        </li>
        <li>
          <a class="nav-link" (click)="printDetailBt()">
            <span class="btn btn-outline-primary btn-sm d-block"><i class="fa fa-print mr-1"></i>
              Imprimer détail BT
            </span>
          </a>
        </li>
        <li>
          <a class="nav-link" (click)="printWorkOrderLabel()">
            <span class="btn btn-outline-primary btn-sm d-block"><i class="fa fa-barcode mr-1"></i>
              Imprimer étiquette
            </span>
          </a>
        </li>
        <li *ngIf="headerFeatures.validate">
          <a class="nav-link d-block text-start" (click)="validateAllTasks()">
            <span class="btn btn-outline-primary btn-sm d-block"
              [ngClass]="isUserBlocked? 'disabled border-primary': ''"><i class="fa fa-check mr-1"></i>
              Valider toutes les tâches
            </span>
          </a>
        </li>
        <li *ngIf="headerFeatures.reopenValidatedTask">
          <a class="nav-link d-block text-start" (click)="reopenAllValidatedTasks()">
            <span class="btn btn-outline-primary btn-sm d-block"
              [ngClass]="isUserBlocked? 'disabled border-primary': ''"><i class="fa fa-reply-all mr-1"></i>
              Rouvrir toutes les tâches validées
            </span>
          </a>
        </li>
        <li *ngIf="headerFeatures.terminate">
          <a class="nav-link d-block text-start" (click)="endAllTasks()">
            <span class="btn btn-outline-primary btn-sm d-block"
              [ngClass]="isUserBlocked ? 'disabled border-primary': ''"><i class="fa fa-save mr-1"></i>
              Terminer toutes les tâches
            </span>
          </a>
        </li>
        <li *ngIf="headerFeatures.reopenTerminatedTask">
          <a class="nav-link d-block text-start" (click)="reopenAllFinishedTasks()">
            <span class="btn btn-outline-primary btn-sm d-block"
              [ngClass]="isUserBlocked ? 'disabled border-primary': ''"><i class="fa fa-reply mr-1"></i>
              Rouvrir toutes les tâches terminées
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="modal fade" id="noteModal" tabindex="-1" aria-labelledby="noteModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="width: 70%">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-customized-title" id="noteModalLabel">
          Note / Message
        </h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body py-2">
        <div class="accordion mt-2 accordion-solid-header" id="modalAccordion2" role="tablist">
          <div class="card border">
            <div class="accordion-item card-header">
              <h2 class="accordion-header my-0" id="headingFour">
                <a class="accordion-button py-2" type="button" data-bs-toggle="collapse"
                  data-bs-target="#modalCollapseFour" aria-expanded="true" aria-controls="modalCollapseFour">
                  <i class="fa fa-edit mr-2"></i>Notes
                  <button class="btn btn-sm btn-light float-right pt-1 pb-1" style="margin-top: -5px"
                    (click)="createNewNote()">
                    <i class="fa fa-plus-square mr-2 small"></i><span style="font-size: 12px">Nouvelle note</span>
                  </button>
                </a>
              </h2>
            </div>
            <div id="modalCollapseFour" class="accordion-collapse collapse show" aria-labelledby="headingFour"
              data-bs-parent="#modalAccordion2">
              <div class="accordion-body">
                <div class="table-responsive">
                  <ejs-grid #notesGrid [dataSource]="noteData" gridLines="Horizontal" showColumnChooser="false"
                    [allowSelection]="true" [allowSorting]="true" rowHeight="30" [allowTextWrap]="true" html>
                    <e-columns>
                      <e-column field="number" headerText="N° Note" width="100" [visible]="false"></e-column>
                      <e-column field="comment" headerText="Note" width="160" [visible]="true"
                        [disableHtmlEncode]="false"></e-column>
                      <e-column field="modifiedBy" headerText="Modifié par" width="100"></e-column>
                      <e-column field="lastModifiedAt" headerText="Modifié le" width="100"><ng-template #template
                          let-data>
                          {{ formatDateString(data.lastModifiedAt) }}
                        </ng-template></e-column>
                      <e-column headerText="Action" textAlign="Center" width="100">
                        <ng-template #template let-data>
                          <button class="btn btn-sm btn-outline-primary mr-2 px-2 py-1">
                            <i class="fas fa-pencil-alt" (click)="editNote(data.id)"></i>
                          </button>
                          <button class="btn btn-sm btn-outline-primary px-2 py-1" (click)="deleteNote(data.id)">
                            <i class="fa fa-trash"></i>
                          </button>
                        </ng-template>
                      </e-column>
                    </e-columns>
                  </ejs-grid>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="NgxEditor__Wrapper mt-2" style="min-height: 200px" *ngIf="showNoteRicthText">
          <ngx-editor [editor]="editor" [(ngModel)]="noteContent" [disabled]="disableNoteRicthText"
            [placeholder]="'Saisir une note...'">
          </ngx-editor>
        </div>
        <div class="row pl-0 pr-0" *ngIf="showNoteRicthText">
          <div class="template-demo pl-0 pr-0">
            <a class="nav-link float-right pl-0" *ngIf="viewModeNote == 'A'" (click)="cancelNote()"><span
                class="btn btn-primary btn-sm"><i class="fa fa-times mr-2"></i><small> Annuler la
                  note</small></span></a>
            <a class="nav-link float-right pl-0" *ngIf="viewModeNote !== 'A'" (click)="cancelNote()"><span
                class="btn btn-primary btn-sm"><i class="fa fa-times mr-2"></i><small> Fermer la note</small></span></a>
            <a [class]="
                savingDataByRequest
                  ? 'nav-link float-right pl-0 pr-1 _disable-link_'
                  : 'nav-link float-right pl-0 pr-1'
              " *ngIf="viewModeNote !== 'V'" (click)="saveNote()"><span class="btn btn-outline-primary btn-sm"><i
                  class="fa fa-save mr-2"></i><small> Sauvegarder et fermer</small></span></a>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          Fermer
        </button>
      </div>
    </div>
  </div>
</div>