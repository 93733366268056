import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ProfilComponent } from './pages/profil/profil.component';
import { HomeComponent } from './pages/home/home.component';
import { OpenWorkorderComponent } from './pages/open-workorder/open-workorder.component';
import { DetailWorkorderComponent } from './pages/detail-workorder/detail-workorder.component';
import { ArchiveWorkorderComponent } from './pages/archive-workorder/archive-workorder.component';
import { TemplateMakerComponent } from './pages/template-maker/template-maker.component';
import { TemplatesComponent } from './pages/templates/templates.component';
import { ValidationWorkorderComponent } from './pages/validation-workorder/validation-workorder.component';
import { AccessGuard } from './guard/access.guard';
import { BikePartsListComponent } from './pages/item-list/bike-parts-list.component';

const routes: Routes = [
  {
    path: 'auth/login',
    component: LoginComponent,
    // canActivate: [AccessGuard],
  },
  {
    path: 'auth/profile',
    component: ProfilComponent,
    // canActivate: [AccessGuard],
  },
  {
    path: 'home/:currentCompanyId/:profileId',
    component: HomeComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'archiveworkorder/:currentCompanyId/:profileId/serial-number',
    component: ArchiveWorkorderComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'archiveworkorder/:currentCompanyId/:profileId/serial-number/:equipementNo',
    component: ArchiveWorkorderComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'validationworkorder/:currentCompanyId/:profileId/serial-number',
    component: ValidationWorkorderComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'validationworkorder/:currentCompanyId/:profileId/serial-number/:equipementNo',
    component: ValidationWorkorderComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'templates/:currentCompanyId/:profileId/serial-number',
    component: TemplatesComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'templates/:currentCompanyId/:profileId/serial-number/:equipementNo',
    component: TemplatesComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'templatemaker/:currentCompanyId/:profileId/serial-number',
    component: TemplateMakerComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'templatemaker/:currentCompanyId/:profileId/serial-number/:equipementNo/template/:systemId',
    component: TemplateMakerComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'templatemaker/:currentCompanyId/:profileId/template/:systemId',
    component: TemplateMakerComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'openworkorder/:currentCompanyId/:profileId/serial-number',
    component: OpenWorkorderComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'detailworkorder/:currentCompanyId/:profileId/workOrder',
    component: DetailWorkorderComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'openworkorder/:currentCompanyId/:profileId/serial-number/:equipementNo',
    component: OpenWorkorderComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'bikepartslist/:currentCompanyId/:profileId/serial-number/:equipementNo',
    component: BikePartsListComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'detailworkorder/:currentCompanyId/:profileId/workOrder/:workOrderNo',
    component: DetailWorkorderComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'detailworkorder/:currentCompanyId/:profileId/workOrders/:workOrderNo',
    component: DetailWorkorderComponent,
    canActivate: [AccessGuard],
  },
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
