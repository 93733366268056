import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DetailWorkorderService } from 'src/app/pages/detail-workorder/services/detail-workorder.service';
import * as Notiflix from 'notiflix';
import { Subject, take } from 'rxjs';
import { OpenWorkorderService } from 'src/app/pages/open-workorder/services/open-workorder.service';
import { HomeService } from 'src/app/pages/home/services/home.service';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { CommunService } from 'src/app/services/commun.service';
import { DropDownListComponent, FilteringEventArgs, PopupEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { Query } from '@syncfusion/ej2-data';
import { Router } from '@angular/router';
import { Features } from 'src/app/models/accessGrantObject.model';
import { AccessService } from 'src/app/guard/access.service';
import { StatusProfileBlockAndReturnScan } from 'src/app/core/shared/models/statusProfileBlockAndReturnScan.model';
declare var bootstrap: any;

@Component({
  selector: 'app-detail-tache-modal',
  templateUrl: './detail-tache-modal.component.html',
  styleUrls: ['./detail-tache-modal.component.scss']
})
export class DetailTacheModalComponent implements OnInit  {
  
  @ViewChild('expensesGrid', { static: false }) expensesGrid?: any;
  @ViewChild('itemsGrid', { static: false }) itemsGrid?: any;
  @ViewChild('qtyElement2', { static: false }) qtyElement2?: any;
  @ViewChild('qtyToConsumeElement2', { static: false }) qtyToConsumeElement2?: any;
  @ViewChild('qtyElement1', { static: false }) qtyElement1?: any;
  @ViewChild('qtyToConsumeElement1', { static: false }) qtyToConsumeElement1?: any;
  @ViewChild('uomElement2', { static: false }) uomElement2?: any;
  @ViewChild('uomElement1', { static: false }) uomElement1?: any;
  @ViewChild('createdByProfilElement2', { static: false }) createdByProfilElement2?: any;
  @ViewChild('createdByProfilElement1', { static: false }) createdByProfilElement1?: any;
  @ViewChild('locationCodeElement2', { static: false }) locationCodeElement2?: any;
  @ViewChild('codeTaskCategoryElement2', { static: false }) codeTaskCategoryElement2?: any;
  @ViewChild('codeTaskCategoryElement1', { static: false }) codeTaskCategoryElement1?: any;
  @ViewChild('itemVariantElement2', { static: false }) itemVariantElement2?: any;
  @ViewChild('noElement2') public noElement2?: DropDownListComponent;
  @ViewChild('btScanInput') btScanInput: ElementRef;
  @ViewChild('noElement1') public noElement1?: DropDownListComponent;
  @ViewChild('scanItemInput') scanItemInput: ElementRef; 

  @Output() refreshGetEquipementEvent = new EventEmitter<object>();
  @Input() BIXI_userAuthObject = null
  @Input() currentProfile = null
  @Input() currentProfiles = null
  @Input() currentUserCompany = null
  @Input() allItemVariants = null
  @Input() BTandLines = null
  @Input() editWorkOrderFeatures = null
  @Input('events') events:Subject<any>;
  @Input('onSelectedAllFinishedClickEvent') onSelectedAllFinishedClickEvent:Subject<any>;
  @Input('onReopenGeneralClickEvent') onReopenGeneralClickEvent:Subject<any>;
  @Input('onSelectedAllValidateClickEvent') onSelectedAllValidateClickEvent:Subject<any>;
  @Input() sameTasks = null
  @Input() itemsList = null
  @Input() expensesList = null
  @Input() bikePartsData = null
  @Input() locationsList = null
  @Input() unitsOfMeasureList = null
  
  actionDetailModalTitle = '';
  actionDetailModal = null
  BTRoueOnExpense = null
  btRoueNoToScanValue = "";
  @Input('openDetailTacheModalEvent') openDetailTacheModalEvent:Subject<any>;
  @Input('populateExpensesArrayEvent') populateExpensesArrayEvent:Subject<any>;
  @Input('populateItemsArrayEvent') populateItemsArrayEvent:Subject<any>;
  
  taskDetailSelected = null;
  @Input() expenseWorkOrderLines: any[] = [];
  currentTaskLineObjectClicked: any = null;
  @Input() itemWorkOrderLines: any[] = [];
  @Input() currentWorkorder = null
  currentBTDetailNo = '';
  btRoueScanModal = null
  btRoueToReplace = ''
  dataType = '';
  toggleDataType = false;
  expenseNum = '';
  itemNum = '';
  uomElement1DefaultValue = '';
  itemVariantElement2DefaultValue = '';
  itemsFields: Object = { text: 'description', value: 'no' };
  expensesFields: Object = { text: 'description', value: 'no' };
  progressBarLineTask: any;
  hideItemVariant = true;
  public onFiltering: EmitType<FilteringEventArgs> = (
    e: FilteringEventArgs
  ) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query =
      e.text != ''
        ? query.where('description', 'startswith', e.text, true)
        : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(this.expensesList, query);

    if (this.noElement1.getItems().length == 0) {
      let query_ = new Query();
      e.updateData(this.expensesList, query_);

      //frame the query based on search string with filter type.
      query_ =
        e.text != '' ? query_.where('no', 'startswith', e.text, true) : query_;
      //pass the filter data source, filter query to updateData method.
      e.updateData(this.expensesList, query_);
    }
  };

  public onFiltering_: EmitType<FilteringEventArgs> = (
    e: FilteringEventArgs
  ) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query =
      e.text != ''
        ? query.where('description', 'startswith', e.text, true)
        : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(this.itemsList, query);

    if (this.noElement2.getItems().length == 0) {
      let query_ = new Query();
      e.updateData(this.expensesList, query_);

      //frame the query based on search string with filter type.
      query_ =
        e.text != '' ? query_.where('no', 'startswith', e.text, true) : query_;
      //pass the filter data source, filter query to updateData method.
      e.updateData(this.itemsList, query_);
    }
  };
  
  currentDataTypeClicked = '';
  formTemplateModalTitle = '';
  NbfiledTitle = '';
  editSelectedLineObject: any = {
    description: '',
    no: '',
    quantity: '',
    qtyToConsume: '',
    createdByProfil: '',
  };
  chevronIconClass="fa fa-chevron-circle-down";
  editItemVariantElement2DefaultValue = '';
  editItemVariants = [];
  hideEditItemVariant = true;
  uomElement2DefaultValue = '';
  itemVariants = [];
  itemDesc=""
  itemsDataNgModel = {
    description: '',
    quantity: '',
    qtyToConsume: '',
    variante: '',
  };

  expensesDataNgModel = {
    description: '',
    quantity: '',
    qtyToConsume: '',
  };

  substitutsItemNo = "";
  substitutsItemVariantCode= ""
  substitutsItemSysId = "";
  substitutsItemData = []
  selectedQty = 1;
  qtyPanierInser = 0;
  public editSettings: Object;
  displayReopenTasksBtns = false;
  codeTaskCategory = ''
  taskLine = null
  scanFeatures: Features = null;

  roueBtModalData: any = null;
  onRoueBTClickModal = null;
  workOrder = null

  @Input() isUserBlocked: boolean = true;

  isOpenedFromDetailWo: boolean = false;
  isEraMissingVariantTaskItems: boolean = false;
  isEraVariantExistCurrentItem: boolean = false;
  currentTaskLineSystemId: string = '';

  constructor(
    private detailWorkorderService: DetailWorkorderService,
    private openWorkOrderService: OpenWorkorderService,
    private homeService: HomeService,
    private storageService: StorageService,
    private communService: CommunService,
    private router: Router,
    private accessService: AccessService
  ) {}

  getTaskCategoryBySystemId() {
    this.detailWorkorderService
      .getTaskCategoryBySystemId(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        this.currentTaskLineSystemId
      )
      .subscribe({
        next: (taskCategoryByDoc) => {
          Notiflix.Loading.remove();
          if(taskCategoryByDoc){
            this.isEraMissingVariantTaskItems = taskCategoryByDoc.missingVariant;
          }
        },
        error: (error) => {
          Notiflix.Loading.remove();
        },
      });
  }


  ngOnInit(): void {

    Notiflix.Notify.init({
      position: 'right-bottom',
    });

    this.scanFeatures = this.accessService.getFeatureByProfile(
      this.currentProfile.profil
    );

    this.openDetailTacheModalEvent.subscribe((data: any) => {
      this.isOpenedFromDetailWo = data.isOpenedFromDetailWo;
      // this.isEraMissingVariantTaskItems = data.eraMissingVariant;
      this.currentTaskLineSystemId = data.taskLine?.taskCategoryByDoc?.systemId || '';
      this.codeTaskCategory = data.codeTaskCategory
      this.currentTaskLineObjectClicked = data.taskLine
      this.sameTasks = data.sameTasks
      this.taskLine = data.taskLine;
      if(this.isOpenedFromDetailWo)
          this.getTaskCategoryBySystemId();       
      this.onTaskDetailButtonClick(data.taskLine, data.codeTaskCategory);
    
    });

    this.populateExpensesArrayEvent?.subscribe(data => {
      this.expenseWorkOrderLines = data
    })

    this.populateItemsArrayEvent?.subscribe(data => {
      this.itemWorkOrderLines = data
    })

    this.onSelectedAllFinishedClickEvent?.subscribe((data: any) => { 
      this.currentWorkorder = data.workOrder
      this.onSelectedAllFinishedClick(data.info_2)
    });

    this.onReopenGeneralClickEvent?.subscribe((data: any) => {
      this.currentWorkorder = data.workOrder
      this.onReopenGeneralClick(data.info_1, data.info_2)
    
    });

    this.onSelectedAllValidateClickEvent?.subscribe((data: any) => {
      this.currentWorkorder = data.workOrder
      this.onSelectedAllValidateClick(data.info_2)  
    });

  }

  private displayPermissionErrorMessage() {
    if(this.storageService.getItem('Bixi_BlockAndReturnScan')){
      const statusApiPermissions:StatusProfileBlockAndReturnScan = JSON.parse(this.storageService.getItem("Bixi_BlockAndReturnScan")) || null;
      if(statusApiPermissions && statusApiPermissions.isBlockedAction){
        Notiflix.Notify.warning(
            "Le statut en cours "+ statusApiPermissions.currentStatus+" du bon de travail bloque cette opération pour le profil " +
              this.communService.roleEnglishToFrench[statusApiPermissions.currentProfile],{
              timeout: 3000,
              showOnlyTheLastOne: true,
              width: "400px",
            }
          );
      }
      else {
        Notiflix.Notify.warning(
            "Vous ne pouvez pas effectuer cette action car vous êtes un " +
              this.communService.roleEnglishToFrench[statusApiPermissions.currentProfile],{
              timeout: 3000,
              showOnlyTheLastOne: true,
              width: "400px",
            }
          );
      }
    }
  }  

  addQuantityToInput() {
    this.qtyPanierInser = Number(this.selectedQty);
  }

  getItemVariantCodeDescription(data) {
    const foundData = this.allItemVariants.filter(
      (itemVariant) =>
        itemVariant.code == data.variantCode &&
        itemVariant.itemNumber == data.no
    );

    if (foundData.length > 0) {
      return foundData[0].description;
    } else {
      return '';
    }
  }

  onTaskDetailButtonClick(taskLine, codeTaskCategory){


      this.taskDetailSelected = this.sameTasks[taskLine.systemId]

      if(this.taskDetailSelected?.workOrdersNo?.length == 1){
  
        if(!this.actionDetailModal){
          this.actionDetailModal = new bootstrap.Modal(document.getElementById('actionDetailModal'), {})
          this.actionDetailModal.show() 
        }else if (this.actionDetailModal && !this.actionDetailModal._isShown){
          this.actionDetailModal.show() 
        }
  
        
        this.currentTaskLineObjectClicked = taskLine
        this.expenseWorkOrderLines = []
        this.itemWorkOrderLines = []
        // check if current BT is in array
        if(this.sameTasks[taskLine.systemId] !== undefined){
  
          if(!this.sameTasks[taskLine.systemId].workOrdersNo.includes(this.currentWorkorder.no)){
  
            this.currentBTDetailNo = this.sameTasks[taskLine.systemId].workOrdersNo[0]
      
            this.openWorkOrderService.getWorkorders(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "no eq '"+ this.currentBTDetailNo +"'").subscribe({
              next: (workOrder) => {
          
                this.workOrder = workOrder.value[0]
                this.taskLine = taskLine

                this.currentWorkorder = workOrder.value[0]
                this.getWorkOrderLines(workOrder.value[0], taskLine, '')
      
              },
      
              error: (error) => {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("Une erreur s'est produite pendant le traitement. Veuillez réessayer", {
                  timeout: 3000,
                  showOnlyTheLastOne: true,
                  width: "400px",
                })
              }
      
            })
      
        
            }else{
              this.getWorkOrderLines(this.currentWorkorder, taskLine, '')
            }
  
        }else{
          this.getWorkOrderLines(this.currentWorkorder, taskLine, '')
        }
      }
      else{
        Notiflix.Notify.failure(`Cette tâche existe en double sur ${this.taskDetailSelected?.workOrdersNo?.length} 
        bons de travail: ${this.taskDetailSelected?.workOrdersNo?.toString()}`, {
          timeout: 3000,
          showOnlyTheLastOne: true,
           width: "400px",
        })
      }

  }

  changeTitleModel(title: string) {
    this.actionDetailModalTitle = title;
  }

  onCancelNewLine() {

    if(this.actionDetailModal && !this.actionDetailModal._isShown){
      this.actionDetailModal.show()
    }
     
  }

  private closeModalTask(){
    this.isOpenedFromDetailWo = false;
    this.isEraMissingVariantTaskItems = false;
    this.actionDetailModal.hide();
    this.refreshGetEquipementEvent.emit()
  }

  refreshEquipments(){
    if(this.isOpenedFromDetailWo){
      if(this.isEraMissingVariantTaskItems){
         this.displayConfirmTaskRemovePopup();
      }
      else{
        this.closeModalTask();
      }
    }
    else{
      this.closeModalTask();
    }
  }

  private displayConfirmTaskRemovePopup(){
    Notiflix.Confirm.show(
      'Avertissement',
      'La tâche associée sera supprimée, car il manque des informations sur votre détails BT. Voulez-vous continuer?',
      'Oui',
      'Non',
      () => {
        this.removeTaskWhenMissedVariant();
      },
      () => {},
      {
        width: '450px',
        okButtonBackground: '#04B76B',
        cancelButtonBackground: '#FF5E6D',
        titleColor: '#392C70',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
      }
    );
  }



    removeTaskWhenMissedVariant() {
      const concatenatedUrl = '/taskCategoriesByDocs(' + this.currentTaskLineSystemId + ')';
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff',
      });
      this.detailWorkorderService
        .deleteElement(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany.id,
          concatenatedUrl
        )
        .subscribe({
          next: (resData) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.warning('La tâche a été retirée', {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });
            this.closeModalTask();
          },
          error: (error) => {
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });
            setTimeout(() => {
              Notiflix.Loading.remove();
              this.closeModalTask();
            }, 2000);
          },
        });
    }


  finishBTRoueScanProccess(actionType){
    const concatenatedUrl = "/workOrderLines("+ this.BTRoueOnExpense.systemId +")"    
    this.BTRoueOnExpense.consummedWheelWONo = this.btRoueNoToScanValue
    delete this.BTRoueOnExpense.systemId
    this.detailWorkorderService.editElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.BTandLines.BT.systemId, JSON.stringify(this.BTRoueOnExpense), concatenatedUrl).subscribe({
      next: (resData) => {
          Notiflix.Loading.remove();
          let message = ''
          if(actionType == 'scan'){
            message = 'BT roue scanné avec succès'
          }else if(actionType == 'save'){
            message = 'BT roue retiré avec succès'
          }
          Notiflix.Notify.success(message, {
            timeout: 3000,
            showOnlyTheLastOne: true,
            width: "400px",
           })

           this.onTaskDetailButtonClick(this.currentTaskLineObjectClicked, this.codeTaskCategory)

      },
      error: (error) => {

        Notiflix.Loading.remove();
        Notiflix.Notify.failure("Une erreur s'est produite pendant le traitement. Veuillez réessayer", {
          timeout: 3000,
          showOnlyTheLastOne: true,
          width: "400px",
         })

      }
    })

    if(this.actionDetailModal && !this.actionDetailModal._isShown){
      this.actionDetailModal.show()
    }

  }

  cancelRoueBTScann(){
    if(this.actionDetailModal && !this.actionDetailModal._isShown){
      this.actionDetailModal.show()
    }
     
  }
  
  saveChangedQty($event) {
    this.selectedQty = $event.target.value;
  }

  getLineSubstituts(line:any){

    const lineNo = line.lineNo
    const documentNo = line.documentNo
    this.substitutsItemNo = line?.no;
    this.substitutsItemVariantCode = line?.variantCode;
    this.substitutsItemSysId = line?.systemId;
    this.substitutsItemData = [];
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff'
    });
    this.detailWorkorderService.getSubstitutsItem(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "$filter=lineNo eq " + lineNo +" and documentNo eq '" + documentNo +"'").subscribe({
      next: (resData) => {
       if(resData?.value?.length > 0)
         this.substitutsItemData = resData?.value[0].eraSubstitution;
       Notiflix.Loading.remove()
      },
      error: (error) => {
        Notiflix.Notify.failure(error, {
          timeout: 3000,
          showOnlyTheLastOne: true,
          width: "400px",
        })
        Notiflix.Loading.remove()
      }
    })
  }

  onOpenBTRoueScanModalClick(expense){
    
    this.BTRoueOnExpense = expense
    delete this.BTRoueOnExpense.column;
    delete this.BTRoueOnExpense.foreignKeyData;
    delete this.BTRoueOnExpense.index;
    this.actionDetailModal.hide();
    setTimeout(() => {
      this.btScanInput?.nativeElement?.focus();
    }, 1000)
    this.btRoueScanModal = new bootstrap.Modal(document.getElementById('btRoueScanModal'), {});
    this.btRoueScanModal.show();
  }
  
  onOpenBTRoueScanModalClickEmptyData(expense){
  
    this.roueBtModalData = null
    this.btRoueNoToScanValue = ''
    this.BTRoueOnExpense = expense
    delete this.BTRoueOnExpense.column;
    delete this.BTRoueOnExpense.foreignKeyData;
    delete this.BTRoueOnExpense.index;
    this.actionDetailModal.hide();
    setTimeout(() => {
      this.btScanInput?.nativeElement?.focus();
    }, 1000)
    this.btRoueScanModal = new bootstrap.Modal(document.getElementById('btRoueScanModal'), {});
    this.btRoueScanModal.show();
  }
  
  savedEmptyRoueBT(){
    if(!this.isUserBlocked && this.editWorkOrderFeatures.removeExpenseBtRoue){
         // Loading indicator with a message, and the new options
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff'
      });
      this.finishBTRoueScanProccess('save')
    }
    else{
      this.displayPermissionErrorMessage();
    }
  }

  scanRoueBT(){
      // Loading indicator with a message, and the new options
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff'
      });
      const data = { no:  this.btRoueNoToScanValue, "profil": this.currentProfile.profil, createCorrectiveWO: true, source: false }
      this.homeService.scanner(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, JSON.stringify(data)).subscribe({
        next: (data) => {
          if(data.type == 0){
            Notiflix.Loading.remove();
            setTimeout(() => {
              this.btRoueScanModal.show()
            }, 200)
            Notiflix.Notify.failure('Vous aviez scanner un équipement. Veuillez scanner un BT roue.', {
              timeout: 3000,
              showOnlyTheLastOne: true,
              width: "400px",
             })             
          }else if(data.type == 1){
            this.openWorkOrderService.getWorkorders(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "no eq '"+ this.btRoueNoToScanValue +"'").subscribe({
              next: (workOrder) => {
                if(workOrder.value[0].isBtRoue){
                  const assetNo = workOrder.value[0].assetNo
                  this.storageService.setItem('equipementRoueSerialNumber', assetNo)
                  this.homeService.getStatus(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.btRoueNoToScanValue).subscribe({
                    next: (resData) => {                      
                      const status = resData.value[0]                       
                      if(status.wOWheelfiltre){
                        // if BT roue is Roue Repare
                        this.finishBTRoueScanProccess('scan')
                      }else {
                        Notiflix.Loading.remove();
                        // if quipement roue status is different from ROUE REPARE or is equal to any other status
                        if(this.actionDetailModal && !this.actionDetailModal._isShown){
                          this.actionDetailModal.show() 
                        }                        
                        Notiflix.Notify.failure("La roue associée a ce BT n'a pas encore été réparée.", {
                          timeout: 3000,
                          showOnlyTheLastOne: true, 
                          width: "400px",
                        })                        
                      }
                    },
                    error: (error) => {            
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure("Une erreur s'est produite pendant le traitement. Veuillez réessayer", {
                        timeout: 3000,
                        showOnlyTheLastOne: true, 
                        width: "400px",
                      })            
                    }
                    })
                }else{

                  setTimeout(() => {
                    this.btRoueScanModal.show()
                  }, 200)
                  
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure("Le BT que vous aviez scanner n'est pas un BT roue. Veuillez scanner un BT roue.", {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                   })                
                }     
                },
                error: (error) => {
                }
              })
          }
        },
        error: (error) => {
          setTimeout(() => {
            this.btRoueScanModal.show()
          }, 200)          
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
           })
        }
      }) 
  }

  onDropdownOpened(args: PopupEventArgs, type){
    args.popup.element.classList.add(type)
  }

  reopenTasksBtnsControl(){
    this.displayReopenTasksBtns = !this.displayReopenTasksBtns ;
    this.chevronIconClass = this.displayReopenTasksBtns ? "fa fa-chevron-circle-up" : "fa fa-chevron-circle-down"
  }

  onFinishedClick(e, dataType) {
    if(!this.isUserBlocked && this.editWorkOrderFeatures.terminate){
      let selectedRecords = null;
      let message = '';
      this.dataType = dataType;
      if (dataType == 'expenses') {
        selectedRecords = this.expensesGrid.getSelectedRecords();
        message = 'Dépense(s) terminée(s) avec succès';
      } else if (dataType == 'items') {
        selectedRecords = this.itemsGrid.getSelectedRecords();
        message = 'Article(s) terminé(s) avec succès';
      }

      if (selectedRecords.length > 0) {
        // Loading indicator with a message, and the new options
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff',
        });
        selectedRecords.forEach((selectedRecord, index) => {
          let data = selectedRecord;
          data.eraFinished = true;
          const saveSystemId = data.systemId;
          const concatenatedUrl = '/workOrderLines(' + data.systemId + ')';
          delete data.systemId;
          this.detailWorkorderService
            .editElement(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              this.currentWorkorder.systemId,
              JSON.stringify(data),
              concatenatedUrl
            )
            .subscribe({
              next: (resData) => {
                selectedRecord.systemId = saveSystemId;
                if (index == selectedRecords.length - 1) {
                  Notiflix.Loading.remove();

                  Notiflix.Notify.success('Dépense(s) terminée(s) avec succès', {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                  });

                  this.onTaskDetailButtonClick(this.currentTaskLineObjectClicked, this.codeTaskCategory);
                }
              },
              error: (error) => {
                selectedRecord.systemId = saveSystemId;
                data.eraFinished = false;
                if (index == selectedRecords.length - 1) {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure(error, {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                  });
                }
              },
            });
        });
      } else {
        Notiflix.Notify.failure(
          'Veuillez sélectionner au moins une ligne pour procéder',
          {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }
        );
      }
    }
    else this.displayPermissionErrorMessage();
  }

  onValidateClick(e, dataType) {
    if(!this.isUserBlocked && this.editWorkOrderFeatures.validate){
      let selectedRecords = null;
      let message = '';
      this.dataType = dataType;
      if (dataType == 'expenses') {
        selectedRecords = this.expensesGrid.getSelectedRecords();
        message = 'Dépense(s) validée(s) avec succès';
      } else if (dataType == 'items') {
        selectedRecords = this.itemsGrid.getSelectedRecords();
        message = 'Article(s) validé(s) avec succès';
      }
      if (selectedRecords.length > 0) {
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff',
        });
        selectedRecords.forEach((selectedRecord, index) => {
          let data = selectedRecord;
          data.eraApproved = true;
          const saveSystemId = data.systemId;
          const concatenatedUrl = '/workOrderLines(' + data.systemId + ')';
          delete data.systemId;
          this.detailWorkorderService
            .editElement(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              this.currentWorkorder.systemId,
              JSON.stringify(data),
              concatenatedUrl
            )
            .subscribe({
              next: (resData) => {
                selectedRecord.systemId = saveSystemId;
                if (index == selectedRecords.length - 1) {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.success('Dépense(s) validée(s) avec succès', {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                  });
                  this.onTaskDetailButtonClick(this.currentTaskLineObjectClicked, this.codeTaskCategory);
                }
              },
              error: (error) => {
                selectedRecord.systemId = saveSystemId;
                data.eraApproved = false;
                if (index == selectedRecords.length - 1) {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure(error, {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                  });
                }
              },
            });
        });
      } else {
        Notiflix.Notify.failure(
          'Veuillez sélectionner au moins une ligne pour procéder',
          {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }
        );
      }
    }
    else this.displayPermissionErrorMessage();
  }

  onDeleteClick(e, dataType) {
    if(!this.isUserBlocked && (
      (dataType == 'expenses' && this.editWorkOrderFeatures.removeExpense) || 
      (dataType == 'items' && this.editWorkOrderFeatures.removeItem))){    
          let selectedRecords = null;
          let confirmationMessage = '';
          this.dataType = dataType;
          if (dataType == 'expenses') {
            selectedRecords = this.expensesGrid.getSelectedRecords();
            if (selectedRecords.length > 1 && dataType == 'expenses') {
              confirmationMessage = 'Êtes-vous sûr de vouloir retirer ces dépenses?';
            } else if (selectedRecords.length == 1 && dataType == 'expenses') {
              confirmationMessage = 'Êtes-vous sûr de vouloir retirer cette dépense?';
            }
          } else if (dataType == 'items') {
            selectedRecords = this.itemsGrid.getSelectedRecords();          
            if (selectedRecords.length > 1 && dataType == 'items') {
              confirmationMessage = 'Êtes-vous sûr de vouloir retirer ces articles?';
            } else if (selectedRecords.length == 1 && dataType == 'items') {
              confirmationMessage = 'Êtes-vous sûr de vouloir retirer cet article?';
            }
          }
          if (selectedRecords.length > 0) {
            Notiflix.Confirm.show(
              'Confirmation',
              confirmationMessage,
              'Retirer',
              'Annuler',
              () => {
                // Loading indicator with a message, and the new options
                Notiflix.Loading.standard('Chargement...', {
                  svgColor: '#ffffff',
                });

                selectedRecords.forEach((selectedRecord, index) => {
                  if (
                    selectedRecord.createdByProfil == 'Supervisor' ||
                    selectedRecord.createdByProfil == 'Evaluator'
                  ) {
                    if (
                      this.currentProfile.profil == 'Supervisor' ||
                      this.currentProfile.profil == 'Evaluator'
                    ) {
                      this.canDeleteLine(selectedRecords, selectedRecord, index, dataType);
                    } else {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure(
                        dataType == 'expenses'
                          ? "Vous n'avez pas le droit de supprimer la dépense " +
                              selectedRecord.no +
                              ' car vous êtes un ' +
                              this.communService.roleEnglishToFrench[
                                this.currentProfile.profil
                              ]
                          : "Vous n'avez pas le droit de supprimer l'article " +
                              selectedRecord.no +
                              ' car vous êtes un ' +
                              this.communService.roleEnglishToFrench[
                                this.currentProfile.profil
                              ],
                        {
                          timeout: 3000,
                          showOnlyTheLastOne: true, 
                          width: "400px",
                        }
                      );
                    }
                  } else if (selectedRecord.createdByProfil == 'Mechanic') {
                    this.canDeleteLine(selectedRecords, selectedRecord, index, dataType);
                  }
                });
              },
              () => {},
              {
                width: '450px',
                okButtonBackground: '#04B76B',
                cancelButtonBackground: '#FF5E6D',
                titleColor: '#392C70',
                borderRadius: '5px',
                backgroundColor: '#ffffff',
              }
            );
          } else {
            Notiflix.Notify.failure(
              'Veuillez sélectionner au moins une ligne pour procéder',
              {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              }
            );
          }
    }
    else this.displayPermissionErrorMessage()
  }

  
  onRoueBTClick(data){
    this.roueBtModalData = data;
    this.actionDetailModal.hide()
    this.onRoueBTClickModal = new bootstrap.Modal(document.getElementById('onRoueBTClickModal'), {})
    this.onRoueBTClickModal.show()     
    // Notiflix.Confirm.show(
    //   'Confirmation',
    //   `Souhaitez-vous ouvrir ce bon de travail ou le remplacer ?`,
    //   'Ouvrir',
    //   'Remplacer',
    //   () => {

    //     this.openWorkOrderService.getWorkorders(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "no eq '"+ data.consummedWheelWONo +"'").subscribe({
    //       next: (workOrder) => {
      
  
    //         this.storageService.setItem('equipementRoueSerialNumber', workOrder.value[0].assetNo)

    //         this.storageService.setItem('currentSerialNumber-window-popup-browser', workOrder.value[0].assetNo)
    
    //         const roueAssetNomber = this.storageService.getItem(
    //           'equipementRoueSerialNumber'
    //         );
        
    //           this.storageService.setItem(
    //             'currentSerialNumber-window-popup-browser',
    //             roueAssetNomber
    //           );
    
    //           this.router.navigateByUrl(
    //             `detailworkorder/${this.currentUserCompany.id}/${this.currentProfile.id}/workOrder/${data.consummedWheelWONo}?mode=topup-bt`
    //           );
  
    //       },
  
    //       error: (error) => {
    //         Notiflix.Loading.remove();
    //         Notiflix.Notify.failure(error.error.error, {
    //           timeout: 3000,
    //           showOnlyTheLastOne: true,
    //         })
    //       }
  
    //     })

    //   },
    //   () => {

    //     if(!data.eraFinished){

    //       this.btRoueNoToScanValue = data.consummedWheelWONo
    //       this.onOpenBTRoueScanModalClick(data)

    //     }else {

    //       this.onTaskDetailButtonClick(this.taskLine, this.codeTaskCategory)
          
    //       Notiflix.Notify.failure('Cette tâche est déjà terminée. Veuillez la rouvrir pour retirer la roue Bt', {
    //         timeout: 3000,
    //         showOnlyTheLastOne: true,
    //       });
          
    //     }  


    //   },
    //   {
    //     // width: '450px',
    //     okButtonBackground: '#04B76B',
    //     cancelButtonBackground: '#468a6d',
    //     titleColor: '#392C70',
    //     borderRadius: '5px',
    //     backgroundColor: '#ffffff',
    //   }
    // );
    

  }

  openBTFromRoueBTModal(){
    let data = this.roueBtModalData
    this.openWorkOrderService.getWorkorders(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "no eq '"+ data.consummedWheelWONo +"'").subscribe({
      next: (workOrder) => {
        this.onRoueBTClickModal.hide();
        this.storageService.setItem('equipementRoueSerialNumber', workOrder.value[0].assetNo)
        this.storageService.setItem('currentSerialNumber-window-popup-browser', workOrder.value[0].assetNo)
        const roueAssetNomber = this.storageService.getItem(
          'equipementRoueSerialNumber'
        );
          this.storageService.setItem(
            'currentSerialNumber-window-popup-browser',
            roueAssetNomber
          );
          this.router.navigateByUrl(
            `detailworkorder/${this.currentUserCompany.id}/${this.currentProfile.id}/workOrder/${data.consummedWheelWONo}?mode=topup-bt`
          );  
      },
      error: (error) => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error, {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
        })
      }
    })
  }

  replaceBTFromRoueBTModal(){
    if(!this.isUserBlocked && this.editWorkOrderFeatures.updateExpenseBtRoue){
      let data = this.roueBtModalData
      if(!data.eraFinished){
        this.onRoueBTClickModal.hide();
        this.btRoueNoToScanValue = data.consummedWheelWONo
        this.onOpenBTRoueScanModalClick(data)
      }else {
        this.onTaskDetailButtonClick(this.taskLine, this.codeTaskCategory)
        Notiflix.Notify.failure('Cette tâche est déjà terminée. Veuillez la rouvrir pour retirer la roue Bt', {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
        });
      }
    }
    else this.displayPermissionErrorMessage();
  }

  onScanBTRoueModalClose(){
    this.onTaskDetailButtonClick(this.taskLine, this.codeTaskCategory)
  }

  createNewLine(dataType) {
    let message = '';

    if (this.toggleDataType) {
      message = 'Article ajouté avec succès';
    } else {
      message = 'Dépense ajoutée avec avec succès';
    }

    this.dataType = dataType;

    const data = {
      documentType: 'Order',
      no: this.toggleDataType ? this.itemNum : this.expenseNum,
      description: this.toggleDataType
        ? this.itemsList.filter((item) => item.no == this.itemNum)[0]
            .description
        : this.expensesList.filter(
            (expense) => expense.no == this.expenseNum
          )[0].description,
      quantity: this.toggleDataType
        ? parseInt(this.qtyElement2.nativeElement.value)
        : parseInt(this.qtyElement1.nativeElement.value),
      qtyToConsume: this.toggleDataType
        ? parseInt(this.qtyToConsumeElement2.nativeElement.value)
        : parseInt(this.qtyToConsumeElement1.nativeElement.value),
      unitOfMeasureCode: this.toggleDataType
        ? this.uomElement2.nativeElement.value
        : this.uomElement1.nativeElement.value,
      createdByProfil: this.toggleDataType
        ? this.createdByProfilElement2.nativeElement.value
        : this.createdByProfilElement1.nativeElement.value,
      locationCode: this.toggleDataType
        ? this.locationCodeElement2.nativeElement.value
        : undefined,
      type: this.toggleDataType ? 'Item' : 'Expense',
      codeTaskCategory: this.toggleDataType
        ? this.codeTaskCategoryElement2.nativeElement.value
        : this.codeTaskCategoryElement1.nativeElement.value,
      eraWorkOrderCategoryCode: '',
      eraApproved: false,
      eraFinished: false,
    };

    if (this.toggleDataType) {
      if (this.itemVariantElement2DefaultValue != '') {
        data['variantCode'] = this.itemVariantElement2.nativeElement.value;
      } else {
        data['variantCode'] = '';
      }
    }

    this.hideItemVariant = true;

    this.itemVariantElement2DefaultValue = '';

    const concatenatedUrl =
      '/workOrders(' + this.BTandLines.BT.systemId + ')/workOrderLines';

    // Loading indicator with a message, and the new options
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });

    this.detailWorkorderService
      .createElement(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        JSON.stringify(data),
        concatenatedUrl
      )
      .subscribe({
        next: (resData) => {
          this.qtyElement2.nativeElement.value = '1';
          this.qtyToConsumeElement2.nativeElement.value = '1';

          this.qtyElement1.nativeElement.value = '1';
          this.qtyToConsumeElement1.nativeElement.value = '1';

          this.expenseNum = '';
          (this.noElement1 as any).value = null;

          this.itemNum = '';
          (this.noElement2 as any).value = null;

          Notiflix.Loading.remove();

          Notiflix.Notify.success(message, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });
          if(dataType == 'Item'){
            if(this.isOpenedFromDetailWo)
              // this.getTaskCategoryBySystemId();
            if(this.isOpenedFromDetailWo)
              this.getTaskCategoryBySystemId();
            else 
              this.getMissingVariantInfoForTaskOutsideDetailBT();            
          }           

          this.onTaskDetailButtonClick(this.currentTaskLineObjectClicked, this.codeTaskCategory);

          this.actionDetailModal.show();
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });

          this.onAddNewLineClick(this.currentDataTypeClicked);
        },
      });

    this.toggleDataType = false;
  }

  canDeleteLine(selectedRecords, selectedRecord, index, dataType) {
    let data = selectedRecord;
    data.eraFinished = true;
    const concatenatedUrl =
      '/workOrders(' +
      this.currentWorkorder.systemId +
      ')/workOrderLines(' +
      selectedRecord.systemId +
      ')';
    const save_systemId = data.systemId;
    delete data.systemId;
    this.detailWorkorderService.deleteElement(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        concatenatedUrl
      ).subscribe({
        next: (resData) => {
          data.systemId = save_systemId;
          if (index == selectedRecords.length - 1) {
            Notiflix.Loading.remove();              
            Notiflix.Notify.success(dataType == 'expenses' ? 'Dépense(s) retirée(s) avec succès' : 'Article(s) retiré(s) avec succès', {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });
            if(dataType?.toLowerCase() == 'items'){
              if(this.isOpenedFromDetailWo)
                this.getTaskCategoryBySystemId();
              else 
                this.getMissingVariantInfoForTaskOutsideDetailBT();  
              }
            this.onTaskDetailButtonClick(this.currentTaskLineObjectClicked, this.codeTaskCategory);
          }
        },
        error: (error) => {
          data.systemId = save_systemId;
          data.eraFinished = false;
          if (index == selectedRecords.length - 1) {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });
          }
        },
      });
  }

  cancelCreation() {}

  onAddNewLineClick(dataType) {
    if(!this.isUserBlocked 
      && (
      (dataType == 'expenses' && this.editWorkOrderFeatures.addExpense) || 
      (dataType == 'items' && this.editWorkOrderFeatures.addItem))
    ){
        this.currentDataTypeClicked = dataType;
        this.dataType = dataType;
        if (dataType == 'expenses') {
          this.formTemplateModalTitle = 'Ajouter une nouvelle dépense';
          this.toggleDataType = false;
          this.NbfiledTitle = 'Dépense';
        } 
        else if (dataType == 'items') {
          this.formTemplateModalTitle = 'Ajouter un nouveau article';
          this.toggleDataType = true;
          this.NbfiledTitle = 'Article';
        }
        this.actionDetailModal.hide() 
        var myModal = new bootstrap.Modal(
          document.getElementById('newExpensesAndItemsLine'),
          {}
        );
        myModal.toggle();
        setTimeout(() => { 
          this.scanItemInput.nativeElement.focus()
        },500);
      }
      else this.displayPermissionErrorMessage();
  }

  getWorkOrderLines(workorder: any, taskLine, mode) {
    // Loading indicator with a message, and the new options
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });

    this.BTandLines = { BT: workorder, expenses: [], items: [] };
    this.detailWorkorderService
      .getWorkOrderLines(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        workorder.systemId,
        "$filter=type eq 'Item' and codeTaskCategory eq '" +
          taskLine.codeTaskCategory +
         "'"
      )
      .subscribe({
        next: (resData) => {
          this.itemWorkOrderLines = resData.value;
          this.BTandLines.items = this.itemWorkOrderLines;
          if(!this.isOpenedFromDetailWo){
            this.getMissingVariantInfoForTaskOutsideDetailBT();
          }
          this.detailWorkorderService
            .getWorkOrderLines(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              workorder.systemId,
              "$filter=type eq 'Expense' and codeTaskCategory eq '" +
                taskLine.codeTaskCategory +
                "'"
            )
            .subscribe({
              next: (resData) => {
                Notiflix.Loading.remove();
                this.expenseWorkOrderLines = resData.value;
                this.BTandLines.expenses = this.expenseWorkOrderLines;
                if(mode){
                  switch(mode){
                    case 'ff': 
                      this.onSelectedAllFinishedClick(false); 
                      this.refreshGetEquipementEvent.emit()
                      break;
                    case 'fr': 
                      this.onReopenGeneralClick('finished', false); 
                      this.refreshGetEquipementEvent.emit()
                      break;
                    case 'vv': 
                      this.onSelectedAllValidateClick(false); 
                      this.refreshGetEquipementEvent.emit()
                      break;
                    case 'vr': 
                      this.onReopenGeneralClick('validated', false); 
                      this.refreshGetEquipementEvent.emit()
                      break;
                    default: 
                    break;
                  }
                }
              },
              error: (error) => {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(error, {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                });
              },
            });
        },
        error: (error) => {
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });
        },
      });
  }

  getMissingVariantInfoForTaskOutsideDetailBT(){
    let missingTaskVariantArray = [];
    missingTaskVariantArray = this.itemWorkOrderLines.filter(x=>x?.eraVariantExiste == true && !x?.variantCode);
    this.isEraMissingVariantTaskItems = missingTaskVariantArray.length > 0 ? true : false;
  }

  getWorkOrderLinesWithoutTaskLine(workorder: any, codeTaskCategory, mode) {
    // Loading indicator with a message, and the new options
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });

    this.BTandLines = { BT: workorder, expenses: [], items: [] };
    this.detailWorkorderService
      .getWorkOrderLines(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        workorder.systemId,
        "$filter=type eq 'Item' and codeTaskCategory eq '" +
        codeTaskCategory +
          "'"
      )
      .subscribe({
        next: (resData) => {
          this.itemWorkOrderLines = resData.value;
          this.BTandLines.items = this.itemWorkOrderLines;
          this.detailWorkorderService
            .getWorkOrderLines(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              workorder.systemId,
              "$filter=type eq 'Expense' and codeTaskCategory eq '" +
              codeTaskCategory +
                "'"
            )
            .subscribe({
              next: (resData) => {
                Notiflix.Loading.remove();
                this.expenseWorkOrderLines = resData.value;
                this.BTandLines.expenses = this.expenseWorkOrderLines;
                if(mode){
                  switch(mode){
                    case 'ff': 
                      this.onSelectedAllFinishedClick(false); 
                      this.refreshGetEquipementEvent.emit()
                      break;
                    case 'fr': 
                      this.onReopenGeneralClick('finished', false); 
                      this.refreshGetEquipementEvent.emit()
                      break;
                    case 'vv': 
                      this.onSelectedAllValidateClick(false); 
                      this.refreshGetEquipementEvent.emit()
                      break;
                    case 'vr': 
                      this.onReopenGeneralClick('validated', false); 
                      this.refreshGetEquipementEvent.emit()
                      break;
                    default: break;
                  }
                }
              },
              error: (error) => {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(error, {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                });
              },
            });
        },
        error: (error) => {
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });
        },
      });
  }


  isQtyFiledExpenseItemDisabled(): boolean {
    let isQtyDisable =  (this.isUserBlocked) || (this.currentProfile.profil == 'Mechanic') ||
                        (this.editSelectedLineObject.createdByProfil == 'Supervisor' ||
                        this.editSelectedLineObject.createdByProfil == 'Evaluator'
                        ? this.currentProfile.profil == 'Supervisor' ||
                        this.currentProfile.profil == 'Evaluator'
                        ? false : true
                        : true)
    return isQtyDisable;
  }

  isQtyToConsumeFieldExpenseItemDisabled(): boolean {
    let isQtyDisable =  (this.isUserBlocked) ||  
                        (this.editSelectedLineObject.createdByProfil == 'Supervisor' ||
                        this.editSelectedLineObject.createdByProfil == 'Evaluator'
                          ? this.currentProfile.profil == 'Supervisor' ||
                            this.currentProfile.profil == 'Evaluator' ||
                            this.currentProfile.profil == 'Mechanic'
                            ? false
                            : true
                          : this.editSelectedLineObject.createdByProfil ==
                            'Mechanic'
                          ? this.currentProfile.profil == 'Supervisor' ||
                            this.currentProfile.profil == 'Evaluator' ||
                            this.currentProfile.profil == 'Mechanic'
                            ? false
                            : true
                          : true)
    return isQtyDisable
  }

  saveLineItemOrExpense(dataType){
    if(!this.isUserBlocked){
      let data = null;
      let qtyType = ""
      let isQtyDisabled = this.isQtyFiledExpenseItemDisabled();
      if(dataType == 'expenses'){
        data = this.expenseWorkOrderLines.filter(
          (expenseWorkOrderLine) =>
            expenseWorkOrderLine.systemId == this.editSelectedLineObject.systemId
        )[0];
        qtyType = "une dépense"
      }
      else if(dataType == 'items'){
        data = this.itemWorkOrderLines.filter(
          (itemWorkOrderLine) =>
            itemWorkOrderLine.systemId == this.editSelectedLineObject.systemId
        )[0];
        qtyType = "un article"
      }
      if(isQtyDisabled){
        if(data.quantity != this.editSelectedLineObject.quantity){
            const statusApiPermissions:StatusProfileBlockAndReturnScan = JSON.parse(this.storageService.getItem("Bixi_BlockAndReturnScan")) || null;
            if(statusApiPermissions && statusApiPermissions.isBlockedAction){
              Notiflix.Notify.failure(
                "Le statut en cours "+ statusApiPermissions.currentStatus+" du bon de travail bloque le changement de quantité d'"+qtyType+" pour le profil " +
                  this.communService.roleEnglishToFrench[statusApiPermissions.currentProfile],{
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                }
              );
            }
            else {
              Notiflix.Notify.failure(
                "Vous ne pouvez pas modifier la quantité d'"+qtyType+" car vous êtes un " +
                  this.communService.roleEnglishToFrench[statusApiPermissions.currentProfile],{
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                }
              );
            }       
        }
        else{
          this.saveLine(dataType);
        }
      }
      else{
        this.saveLine(dataType);
      }
    }
    else{
        this.displayPermissionErrorMessage();
    }
  }

  saveLine(dataType) {
    let message = '';
    let data = null;
    let index = undefined;
    this.dataType = dataType;
    if (dataType == 'expenses') {
      data = this.expenseWorkOrderLines.filter(
        (expenseWorkOrderLine) =>
          expenseWorkOrderLine.systemId == this.editSelectedLineObject.systemId
      )[0];
      message = 'Dépense modifiée avec succès';
      index = this.expenseWorkOrderLines.indexOf(data);
    } 
    else if (dataType == 'items') {
      data = this.itemWorkOrderLines.filter(
        (itemWorkOrderLine) =>
          itemWorkOrderLine.systemId == this.editSelectedLineObject.systemId
      )[0];
      message = 'Article modifié avec succès';
      index = this.itemWorkOrderLines.indexOf(data);
      data.variantCode = this.editItemVariantElement2DefaultValue;
      delete data.description;
    }

    if (this.editSelectedLineObject.no.trim() !== '') {
      if (
        this.editSelectedLineObject.qtyToConsume <=
        this.editSelectedLineObject.quantity
      ) {
        // Loading indicator with a message, and the new options
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff',
        });

        data.quantity = this.editSelectedLineObject.quantity;
        data.qtyToConsume = this.editSelectedLineObject.qtyToConsume;

        const saveSystemId = data.systemId;
        const concatenatedUrl = '/workOrderLines(' + data.systemId + ')';

        delete data.systemId;
        this.detailWorkorderService
          .editElement(
            this.BIXI_userAuthObject.tenantId,
            this.currentUserCompany.id,
            this.BTandLines.BT.systemId,
            JSON.stringify(data),
            concatenatedUrl
          )
          .subscribe({
            next: (resData) => {
              Notiflix.Loading.remove();

              Notiflix.Notify.success(message, {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              });
              if(dataType == 'items'){
                if(this.isOpenedFromDetailWo)
                  this.getTaskCategoryBySystemId();
                else 
                  this.getMissingVariantInfoForTaskOutsideDetailBT();
              }
              this.onTaskDetailButtonClick(this.currentTaskLineObjectClicked, this.codeTaskCategory);
            },
            error: (error) => {
              data.systemId = saveSystemId;
              Notiflix.Loading.remove();
              Notiflix.Notify.failure(
                "Une erreur s'est produite pendant le traitement. Veuillez réessayer",
                {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                }
              );
            },
          });
      } else {
        Notiflix.Notify.failure(
          'La quantité à consommer ne doit pas être supérieure à la quantité',
          {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }
        );

        if (this.dataType == 'expenses') {
          var myModal = new bootstrap.Modal(
            document.getElementById('updateExpensesModal'),
            {}
          );
          myModal.toggle();
        } else if (this.dataType == 'items') {
          var myModal = new bootstrap.Modal(
            document.getElementById('updateItemsModal'),
            {}
          );
          myModal.toggle();
        }
      }
    } else {
      Notiflix.Notify.failure('Aucune ligne sélectionnée', {
        timeout: 3000,
        showOnlyTheLastOne: true, 
        width: "400px",
      });
    }
  }

  onLineClick(data, dataType) {

    this.toggleDataType = false;
  

    this.editSelectedLineObject = data;

    this.editItemVariants = this.allItemVariants.filter(
      (itemVariant) => itemVariant.itemNumber == data.no
    );
    if (this.editItemVariants.length > 0) {
      this.hideEditItemVariant = false;
      this.editItemVariantElement2DefaultValue = data.variantCode;
    } else {
      this.hideEditItemVariant = true;
      this.editItemVariantElement2DefaultValue = '';
    }
  }

  onChangeEvent(e) {
    if (e.itemData !== null) {
      this.expenseNum = e.itemData.no;
      this.uomElement1DefaultValue = e.itemData.unitOfMeasureCode;
    }
  }

  onChangeEvent_(e) {
    if (e.itemData !== null) {
      this.itemNum = e.itemData.no;
      this.uomElement2DefaultValue = e.itemData.baseUnitOfMeasure;
      this.isEraVariantExistCurrentItem = e.itemData.eraVariantExiste;
      if(this.isEraVariantExistCurrentItem)
       this.getItemVariants(this.itemNum);
    }
  }

  getItemVariants(itemNumber) {
    const concatenatedUrl = "/itemVariants?$filter=itemNumber eq '" + itemNumber + "'";
    this.detailWorkorderService
      .getNoStandartApiData_2(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        concatenatedUrl
      )
      .subscribe({
        next: (resData) => {
          if (resData.value.length > 0) {
            this.itemVariants = resData.value.sort((a, b) =>
              a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1
            );
            this.hideItemVariant = false;
            //this.itemVariantElement2DefaultValue = this.itemVariants[0].code;
          } else {
            this.hideItemVariant = true;
            this.itemVariantElement2DefaultValue = '';
          }
        },
        error: (error) => {
          this.hideItemVariant = true;
          this.itemVariantElement2DefaultValue = '';
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });
        },
      });
  }

  scanItem($event){
    const scannedItemNum = $event.target.value;
    if(scannedItemNum){
      const item = this.itemsList.filter(item => item.no == scannedItemNum)[0]
      if(item){
        this.itemDesc=  item?.no;
        this.itemsDataNgModel.quantity = "1";
        this.itemNum = item?.no;
        this.itemsDataNgModel.qtyToConsume = "1"
        this.uomElement2DefaultValue = item?.baseUnitOfMeasure;
        this.itemVariantElement2DefaultValue = '';
        this.isEraVariantExistCurrentItem = item?.eraVariantExiste;
        if(this.isEraVariantExistCurrentItem)
          this.getItemVariants(item?.no)       
        setTimeout(() => { 
          this.scanItemInput.nativeElement.value =""
          this.scanItemInput.nativeElement.focus()
        },500);         
      }
      else{
        Notiflix.Notify.warning("Numéro d'article non trouvé", {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
          });  
        this.itemDesc=  "";
        this.itemsDataNgModel.quantity = "";
        this.itemNum = "";
        this.itemsDataNgModel.qtyToConsume = ""
        this.uomElement2DefaultValue = ""
        this.getItemVariants("") 
        setTimeout(() => { 
          this.scanItemInput.nativeElement.value =""
          this.scanItemInput.nativeElement.focus()
        },500);  
      }
    }
  }

  onDuplicateNewLineClick(e){
    if(!this.isUserBlocked && this.editWorkOrderFeatures.addSupplement){
      var selectedItemRecord =this.itemsGrid.getSelectedRecords()[0];
      if(selectedItemRecord){
        const duplicatedLine = {
          documentType: "Order",
          no: selectedItemRecord?.no,
          description: selectedItemRecord?.description,
          quantity: 1,
          qtyToConsume: 1,
          variantCode: selectedItemRecord?.variantCode,
          unitOfMeasureCode: selectedItemRecord?.unitOfMeasureCode,
          createdByProfil: this.currentProfile?.profil,
          locationCode: selectedItemRecord?.locationCode,
          type: 'Item',
          codeTaskCategory: selectedItemRecord?.codeTaskCategory,
          eraWorkOrderCategoryCode: '',
          eraApproved: false,
          eraFinished: false,
        }

        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff'
        });

        const concatenatedUrl = "/workOrders("+ this.currentWorkorder.systemId +")/workOrderLines"
        this.detailWorkorderService.createElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, JSON.stringify(duplicatedLine), concatenatedUrl).subscribe({
          next: (resData) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Article dupliqué avec succès", {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
              });
            this.onTaskDetailButtonClick(this.currentTaskLineObjectClicked, this.codeTaskCategory)

          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            })
          }
        })
      }
      else{
        Notiflix.Notify.warning("Aucun article sélectionné", {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
        })      
      }
    }
    else this.displayPermissionErrorMessage();
  }

  onSelectedAllFinishedClick(openTaskDetailModal:boolean) {

    if(!this.isUserBlocked && this.editWorkOrderFeatures.terminate){
      const dataArray = this.BTandLines.expenses.concat(this.BTandLines.items);
      if (dataArray.length > 0) {
        // Loading indicator with a message, and the new options
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff',
        });

        dataArray.forEach((selectedRecord, index) => {
          let data = selectedRecord;
          data.eraFinished = true;
          const saveSystemId = data.systemId;
          const concatenatedUrl = '/workOrderLines(' + data.systemId + ')';
          delete data.systemId;
          this.detailWorkorderService
            .editElement(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              this.BTandLines.BT.systemId,
              JSON.stringify(data),
              concatenatedUrl
            )
            .subscribe({
              next: (resData) => {
                selectedRecord.systemId = saveSystemId;
                if (index == dataArray.length - 1) {
                  Notiflix.Loading.remove();

                  Notiflix.Notify.success(
                    'Dépense(s) et articles terminée(s) avec succès',
                    {
                      timeout: 3000,
                      showOnlyTheLastOne: true, 
                      width: "400px",
                    }
                  );

                  this.getWorkOrderLines(this.currentWorkorder, this.taskLine, '')
                }
              },
              error: (error) => {
                selectedRecord.systemId = saveSystemId;
                data.eraFinished = false;
                if (index == dataArray.length - 1) {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure(error,
                    {
                      timeout: 3000,
                      showOnlyTheLastOne: true, 
                      width: "400px",
                    }
                  );
                }
              },
            });
        });
      } else {
        Notiflix.Notify.failure('Aucune ligne trouvée. Dépense ou article.', {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
        });
      }
    }
    else this.displayPermissionErrorMessage();
  }

  onReopenGeneralClick(taskType:'validated' | 'finished', openTaskDetailModal:boolean){
    if(!this.isUserBlocked && (
      (taskType == 'finished' && this.editWorkOrderFeatures.reopenTerminatedTask) || 
      (taskType == 'validated' && this.editWorkOrderFeatures.reopenValidatedTask))){
      let dataArray = [];
      switch(taskType){
        case 'validated':
          dataArray = this.expenseWorkOrderLines.concat(this.itemWorkOrderLines).filter(x=> x.eraApproved == true);
          break;
        case 'finished':
          dataArray = this.expenseWorkOrderLines.concat(this.itemWorkOrderLines).filter(x=> x.eraFinished == true && x.eraApproved == false);
          break;
        default:
          break
      }
      if(dataArray.length > 0){

        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff'
        });
        if(dataArray?.length > 0){
          dataArray.forEach((selectedRecord, index) => {
            let data = selectedRecord
            let successMessage = "";
            if(taskType == "validated"){
              data.eraApproved = false;
              successMessage = "Les dépenses et les articles validés sont réouvris avec succès"
            } 
            if(taskType == "finished"){
              data.eraFinished = false;
              successMessage = "Les dépenses et les articles terminés sont réouvris avec succès"
            }
            const saveSystemId = data.systemId
            const concatenatedUrl = "/workOrderLines("+ selectedRecord.systemId +")"
            delete data.systemId
            this.detailWorkorderService.editElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.currentWorkorder.systemId, JSON.stringify(data), concatenatedUrl).subscribe({
              next: (resData) => {
            selectedRecord.systemId = saveSystemId
                if(index == (dataArray.length - 1)){
                  Notiflix.Loading.remove();
                  Notiflix.Notify.success(successMessage, {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                    })
                    if(openTaskDetailModal)
                      this.onTaskDetailButtonClick(this.currentTaskLineObjectClicked, this.codeTaskCategory)
                }
              },
              error: (error) => {
                selectedRecord.systemId = saveSystemId
                if(taskType == "validated"){
                  data.eraApproved = true;
                } 
                if(taskType == "finished"){
                  data.eraFinished = true;
                }
                if(index == (dataArray.length - 1)){
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure(error, {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                    })
                }
              }
            })
          });
        }else{
          let warningMessage = ""
          if(taskType == "validated"){
            warningMessage = "Aucune dépense ou article validée à rouvrir"
          } 
          if(taskType == "finished"){
            warningMessage = "Aucune dépense ou article terminée à rouvrir"
          }      
          Notiflix.Loading.remove();
          Notiflix.Notify.warning(warningMessage, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
            })
        }

      }else{

        let warningMessage = ""
        if(taskType == "validated"){
          warningMessage = "Aucune tâche validée trouvée"
        } 
        if(taskType == "finished"){
          warningMessage = "La validation sur la ligne de dépense ou article doit être ouverte pour rouvrir la tâche"
        }  

        Notiflix.Notify.failure(warningMessage, {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
        })


      }
    }
    else this.displayPermissionErrorMessage();
  }

  onSelectedAllValidateClick(openTaskDetailModal:boolean) {
    if(!this.isUserBlocked && this.editWorkOrderFeatures.validate){
      const dataArray = this.expenseWorkOrderLines.concat(
        this.itemWorkOrderLines
      );
      // Loading indicator with a message, and the new options
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff',
      });
      dataArray.forEach((selectedRecord, index) => {
        let data = selectedRecord;
        data.eraApproved = true;
        const saveSystemId = data.systemId;
        const concatenatedUrl ='/workOrderLines(' + selectedRecord.systemId + ')';
        delete data.systemId;
        this.detailWorkorderService
          .editElement(
            this.BIXI_userAuthObject.tenantId,
            this.currentUserCompany.id,
            this.BTandLines.BT.systemId,
            JSON.stringify(data),
            concatenatedUrl
          )
          .subscribe({
            next: (resData) => {
              selectedRecord.systemId = saveSystemId;
              if (index == dataArray.length - 1) {
                Notiflix.Loading.remove();
                Notiflix.Notify.success(
                  'Dépense(s) et articles validée(s) avec succès',
                  {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                  }
                );
              }
            },
            error: (error) => {
              selectedRecord.systemId = saveSystemId;
              data.eraApproved = false;
              let errorMessage = '';
              if (error.includes('Finished doit')) {
                errorMessage = 'Cette ligne doit être terminée pour être validée';
              } else {
                errorMessage =
                  "Une erreur s'est produite pendant le traitement. Veuillez réessayer";
              }
              if (index == dataArray.length - 1) {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(errorMessage, {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                });
              }
            },
          });
      });
    }
    else this.displayPermissionErrorMessage();
  }

  onEditItemVariantChange($event) {
    this.editItemVariantElement2DefaultValue = $event.target.value;
  }

  onAddItemVariantChange($event) {
    this.itemVariantElement2DefaultValue = $event.target.value;
  }


  
  inputEvent(event) {
    this.expensesDataNgModel.qtyToConsume = event.target.value;
  }

  inputEvent_item(event) {
    this.itemsDataNgModel.qtyToConsume = event.target.value;
  }

  inputEvent_edit_item(event) {
    this.editSelectedLineObject.qtyToConsume = parseInt(event.target.value);
  }

  inputEvent_edit_expenses(event) {
    this.editSelectedLineObject.qtyToConsume = parseInt(event.target.value);
  }

  onSelectedGeneralFinishedClick() {
    if(!this.isUserBlocked && this.editWorkOrderFeatures.terminate){

      var expensesSelectedRecords = this.expensesGrid.getSelectedRecords();
      var itemsSelectedRecords = this.itemsGrid.getSelectedRecords();

      const dataArray = expensesSelectedRecords.concat(itemsSelectedRecords);

      if (dataArray.length > 0) {
        // Loading indicator with a message, and the new options
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff',
        });

        dataArray.forEach((selectedRecord, index) => {
          let data = selectedRecord;
          data.eraFinished = true;

          const saveSystemId = data.systemId;
          const concatenatedUrl = '/workOrderLines(' + data.systemId + ')';

          delete data.systemId;

          this.detailWorkorderService
            .editElement(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              this.BTandLines.BT.systemId,
              JSON.stringify(data),
              concatenatedUrl
            )
            .subscribe({
              next: (resData) => {
                selectedRecord.systemId = saveSystemId;

                if (index == dataArray.length - 1) {
                  Notiflix.Loading.remove();

                  Notiflix.Notify.success('Dépense(s) terminée(s) avec succès', {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                  });

                  this.onTaskDetailButtonClick(this.currentTaskLineObjectClicked, this.codeTaskCategory);
                }
              },
              error: (error) => {
                selectedRecord.systemId = saveSystemId;
                data.eraFinished = false;
                if (index == dataArray.length - 1) {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure(
                    error,
                    // "Une erreur s'est produite pendant le traitement. Veuillez réessayer",
                    {
                      timeout: 3000,
                      showOnlyTheLastOne: true, 
                      width: "400px",
                    }
                  );
                }
              },
            });
        });
      } else {
        Notiflix.Notify.failure(
          'Veuillez sélectionner au moins une ligne pour procéder',
          {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }
        );
      }
    }
    else this.displayPermissionErrorMessage();
  }

  onSelectedGeneralValidateClick() {
    if(!this.isUserBlocked && this.editWorkOrderFeatures.validate){
      var expensesSelectedRecords = this.expensesGrid.getSelectedRecords();
      var itemsSelectedRecords = this.itemsGrid.getSelectedRecords();
      const dataArray = expensesSelectedRecords.concat(itemsSelectedRecords);
      if (dataArray.length > 0) {
        // Loading indicator with a message, and the new options
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff',
        });
        dataArray.forEach((selectedRecord, index) => {
          let data = selectedRecord;
          data.eraApproved = true;
          const saveSystemId = data.systemId;
          const concatenatedUrl = '/workOrderLines(' + data.systemId + ')';
          delete data.systemId;
          this.detailWorkorderService
            .editElement(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              this.BTandLines.BT.systemId,
              JSON.stringify(data),
              concatenatedUrl
            )
            .subscribe({
              next: (resData) => {
                selectedRecord.systemId = saveSystemId;
                if (index == dataArray.length - 1) {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.success('Dépense(s) validée(s) avec succès', {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                  });
                  this.onTaskDetailButtonClick(this.currentTaskLineObjectClicked, this.codeTaskCategory);
                }
              },
              error: (error) => {
                selectedRecord.systemId = saveSystemId;
                data.eraApproved = false;
                let errorMessage = '';
                if (error.includes('Finished doit')) {
                  errorMessage = 'Cette ligne doit être terminée pour être validée';
                } else {
                  errorMessage = "Une erreur s'est produite pendant le traitement. Veuillez réessayer";
                }
                if (index == dataArray.length - 1) {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure(errorMessage, {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                  });
                }
              },
            });
        });
      } else {
        Notiflix.Notify.failure(
          'Veuillez sélectionner au moins une ligne pour procéder',
          {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }
        );
      }
    }
    else this.displayPermissionErrorMessage();
  }

  onReopenSelectedClick(taskType:'validated' | 'finished'){

    if(!this.isUserBlocked && (
      (taskType == 'finished' && this.editWorkOrderFeatures.reopenTerminatedTask) || 
      (taskType == 'validated' && this.editWorkOrderFeatures.reopenValidatedTask))){
      var expensesSelectedRecords = this.expensesGrid.getSelectedRecords();
      var itemsSelectedRecords = this.itemsGrid.getSelectedRecords();
      if(expensesSelectedRecords.concat(itemsSelectedRecords).length  > 0){

        let dataArray = [];
        switch(taskType){
          case 'validated':
            dataArray = expensesSelectedRecords.concat(itemsSelectedRecords).filter(x=> x.eraApproved == true);
            break;
          case 'finished':
            dataArray = expensesSelectedRecords.concat(itemsSelectedRecords).filter(x=> x.eraFinished == true && x.eraApproved == false);
            break;
          default:
            break
        }
        if(dataArray.length > 0){
          Notiflix.Loading.standard('Chargement...', {
            svgColor: '#ffffff'
          });
          dataArray.forEach((selectedRecord, index) => {
            let data = selectedRecord
            let successMessage = "";
            if(taskType == "validated"){
              data.eraApproved = false;
              successMessage = "La sélection des dépenses et des articles validés est réouvrie avec succès"
            } 
            if(taskType == "finished"){
              data.eraFinished = false;
              successMessage = "La sélection des dépenses et des articles terminés est réouvrie avec succès"
            }
   
            const saveSystemId = data.systemId
            const concatenatedUrl = "/workOrderLines("+ data.systemId +")"
            delete data.systemId    

        
              this.detailWorkorderService.editElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.currentWorkorder.systemId, JSON.stringify(data), concatenatedUrl).subscribe({
                next: (resData) => {
                  selectedRecord.systemId = saveSystemId
                  if(index == (dataArray.length - 1)){
                    Notiflix.Loading.remove();
                    Notiflix.Notify.success(successMessage, {
                      timeout: 3000,
                      showOnlyTheLastOne: true, 
                      width: "400px",
                    })
                    this.onTaskDetailButtonClick(this.currentTaskLineObjectClicked, this.codeTaskCategory)
                  }
                },
                error: (error) => {
                  selectedRecord.systemId = saveSystemId
                  if(taskType == "validated"){
                    data.eraApproved = true;
                  } 
                  if(taskType == "finished"){
                    data.eraFinished = true;
                  }
                  if(index == (dataArray.length - 1)){
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(error, {
                      timeout: 3000,
                      showOnlyTheLastOne: true, width: "400px",
                    })
                  }
                }
              })
               
        
            })

 
        }else{

          let warningMessage = ""
          if(taskType == "validated"){
            warningMessage = "Aucune tâche validée sélectionnée"
          } 
          if(taskType == "finished"){
            warningMessage = "La validation sur la ligne de dépense ou article doit être ouverte pour rouvrir la tâche"
          }  

          Notiflix.Notify.failure(warningMessage, {
            timeout: 3000,
            showOnlyTheLastOne: true, width: "400px",
          })

        }

      }else{

        let warningMessage = ""
        if(taskType == "validated"){
          warningMessage = "Veuillez sélectionner au moins une dépense ou article validé pour procéder"
        } 
        if(taskType == "finished"){
          warningMessage = "Veuillez sélectionner au moins une dépense ou article terminé pour procéder"
        }      
        Notiflix.Loading.remove();
        Notiflix.Notify.warning(warningMessage, {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
        })

      }
    }
    else this.displayPermissionErrorMessage();
  }

  finishAllTasksOnProgressBarClick(){
    this.getWorkOrderLines(this.currentWorkorder, this.progressBarLineTask, 'ff');
  }

  reopenAllFinishedTasksOnProgressBarClick(){
    this.getWorkOrderLines(this.currentWorkorder, this.progressBarLineTask, 'fr'); 
  }

  validateAllTasksOnProgressBarClick(){
    this.getWorkOrderLines(this.currentWorkorder, this.progressBarLineTask, 'vv');   
  }

  reopenAllValidatedTasksOnProgressBarClick(){
    this.getWorkOrderLines(this.currentWorkorder, this.progressBarLineTask, 'vr');       
  } 


  private replaceSubstitutConfirmed(
    parentSystemId: string,
    no: string,
    substituteNo: string
  ) {
    if(!this.isUserBlocked){
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff',
      });
      this.detailWorkorderService
        .patchBikePartsSubstitut(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany.id,
          parentSystemId,
          {
            no: substituteNo,
          }
        )
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            var closeModalSubstitus = document.getElementById('closeSubstItemsModalBtn');
            closeModalSubstitus.click() 
            this.onTaskDetailButtonClick(this.currentTaskLineObjectClicked, this.codeTaskCategory);
            Notiflix.Notify.success(
              `Article ${no} a été remplacé par ${substituteNo} avec succès.`
            );
            Notiflix.Loading.remove();
          },
          error: (error) => {
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, width: "400px",
            });          
            Notiflix.Loading.remove();
          },
        });
    }
    else{
      this.displayPermissionErrorMessage();
    }
  }  

  replaceSubstitut(parentSystemId: string, no: string, substituteNo: string) {
    if(!this.isUserBlocked){
      Notiflix.Confirm.show(
        'Confirmation',
        `Êtes-vous sûr de vouloir remplacer ${no} par ${substituteNo} ?`,
        'Confirmer',
        'Annuler',
        () => {
          this.replaceSubstitutConfirmed(parentSystemId, no, substituteNo);
        },
        () => {},
        {
          // width: '450px',
          okButtonBackground: '#04B76B',
          cancelButtonBackground: '#FF5E6D',
          titleColor: '#392C70',
          borderRadius: '5px',
          backgroundColor: '#ffffff',
        }
      );
    }
    else{
      this.displayPermissionErrorMessage();
    }
  }

  onCancelSubstModal(){
    if(this.actionDetailModal && !this.actionDetailModal._isShown){
      this.actionDetailModal.show()
    }
  }

  closeOnRoueBTClickModal(){
    this.onRoueBTClickModal.hide()     
    this.actionDetailModal.show()
  }



}
