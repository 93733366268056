import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { AppConfigService } from '../../../config/app.config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorService } from './error.service';
import { BlockAndReturnScan } from '../models/blockAndReturnScan.model';

@Injectable({
  providedIn: 'root'
})
export class TaskControlService {
  defaultCompanyId = AppConfigService.settings.defaultCompanyId;
  bcCoreApiUrl = AppConfigService.settings.bcCoreApiUrl;
  customApiUrlSegment = AppConfigService.settings.customApiUrlSegment;
  bcEnvirName = AppConfigService.settings.bcEnvirName;
  defaultLg = AppConfigService.settings.defaultLanguage;
  schemaVersion = AppConfigService.settings.bcSchemaversion;

  constructor(private http: HttpClient,
    private errorService: ErrorService  ) { }

    // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'If-Match': '*',
      'Accept-Language': 'fr-CA'
    }),
  }; 
  
  blockActionsByStatusBtProfile(tenantId: any, companyId: string, status: string, profil: string, woId: string): Observable<BlockAndReturnScan>{  
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/workOrders("+woId+")/Microsoft.NAV.blockModification?$schemaversion="+this.schemaVersion;
    const body = {"profil": profil, "bixiStatus": status}     
    return this.http.post<any>(url, body, this.httpOptions).pipe(
      map(result  => JSON.parse(result?.value) as BlockAndReturnScan),
      catchError(this.errorService.handleError));
  }



}
