/// <reference types="@angular/localize" />

import {
  enableProdMode
} from "@angular/core";

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key 
registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9ec3ZVQmRfUEZwXkY=');

  // enableProdMode();
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  