import {
  Component,
  HostListener,
  Input,
  EventEmitter,
  Output,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Editor } from 'ngx-editor';
import { Company } from 'src/app/core/shared/models/company.model';
import { Profile } from 'src/app/core/shared/models/profile';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { Toolbar } from 'ngx-editor';
import Notiflix from 'notiflix';
import { GridComponent, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { NavBarService } from './services/nav-bar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationInsightsService } from 'src/app/telemetry.service';
import { CommunService } from 'src/app/services/commun.service';
import { Location } from '@angular/common';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { Features } from 'src/app/models/accessGrantObject.model';
import { AccessService } from 'src/app/guard/access.service';
import { HomeService } from 'src/app/pages/home/services/home.service';
import { OpenWorkorderService } from 'src/app/pages/open-workorder/services/open-workorder.service';
import { HeaderService } from '../header/services/header.service';
import { TaskControlService } from 'src/app/core/shared/services/task-control.service';
import { StatusProfileBlockAndReturnScan } from 'src/app/core/shared/models/statusProfileBlockAndReturnScan.model';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  @ViewChild('notesGrid') notesGrid: GridComponent;
  @ViewChild('defaultRTE') defaultRTE: RichTextEditorComponent;

  @Input() currentProfile: Profile = null;
  @Input() currentUserCompany: Company | undefined = null;
  @Input() isUserBlocked: boolean = true;
  @Output() changeProfileEvent = new EventEmitter<object>();
  @Output() logoutEvent = new EventEmitter<object>();
  @Input() BIXI_userAuthObject: any = null;
  @Input() equipementNo: string = '';
  @Input() pageName: string = '';
  @Input() noteData: any[] = [];
  userProfiles = [];
  editor: Editor;
  showNoteRicthText = false;
  currentSelectedNoteId = '0';

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  noteContent = '';
  disableNoteRicthText = true;
  viewModeNote = 'V';
  currentNote = '';
  saved = false
  public editSettings: Object;
  public toolbarPopupOptions: ToolbarItems[] | object;
  isDefaultTesksSelected = false;
  savingDataByRequest = false;
  @Output() endAllTasksEvent = new EventEmitter<object>();
  @Output() validateAllTasksEvent = new EventEmitter<object>();
  currentWorkOrderNo = '';
  @Output() printDetailBtEvent = new EventEmitter<object>();
  @Output() reopenAllFinishedTasksEvent = new EventEmitter<object>();
  @Output() reopenAllValidatedTasksEvent = new EventEmitter<object>();
  filtredEquipementNo = ''
  closeOpenWorkorderpanelEvent: Subscription;
  woParameter = '';

  @Input() events: Observable<void>;
  headerFeatures: Features = null;
  @Input() printLabelEquipement = null;
  @Output() maganePermssionEvent = new EventEmitter<object>();

  constructor(
    private storageService: StorageService,
    private navBarService: NavBarService,
    private router: Router,
    private appInsights: ApplicationInsightsService,
    private communService: CommunService,
    private activeRouter: ActivatedRoute,
    private _location: Location,
    private accessService: AccessService,
    private homeService: HomeService,
    private openWorkOrderService: OpenWorkorderService,
    private headerService: HeaderService,
    private taskControlService: TaskControlService
  ) {}

  ngOnInit() {

    if(this.events){
      this.closeOpenWorkorderpanelEvent = this.events.subscribe(() => {
        this.woParameter = '?wo-opened=false'
      });
    }
    this.activeRouter.params.subscribe((params) => {
      this.currentWorkOrderNo = params['workOrderNo'];
      if(this.currentWorkOrderNo){
        this.filtredEquipementNo = params['workOrderNo'];
        if (this.filtredEquipementNo.includes(',')) {
          const workOrdersNoArray = this.filtredEquipementNo.split(',');
          this.filtredEquipementNo = workOrdersNoArray[0];
        }else{
          this.filtredEquipementNo = this.filtredEquipementNo.replaceAll('%20', ' ');
        }
        if(window.location.href.includes('detailworkorder')){
          this.openWorkOrderService
          .getWorkorders(
            this.BIXI_userAuthObject.tenantId,
            this.currentUserCompany.id,
            "no eq '" + this.filtredEquipementNo + "'"
          )
          .subscribe({
            next: (workOrder) => {
              const TempworkOrder = workOrder.value[0]
              this.homeService
              .getEquipement(
                this.BIXI_userAuthObject.tenantId,
                this.currentUserCompany.id,
                workOrder.value[0].assetNo
              )
              .subscribe({
                next: (equipement) => {

                  const equip = equipement.value[0];
                  if(equip.multiCorrectifWorkOrder){

                    const workOrderLedSysId = this.storageService.setItem("Bixi_WorkOrderLedSysId", TempworkOrder.systemId);
                    const currentWoStatus = this.storageService.setItem("Bixi_WorkOrderBixiStatus", TempworkOrder.eraBixiStatus);

                    this.headerService.newMultipleCorrectifWOrkOrderEquipement.next( { equipement: equip, BT: TempworkOrder} ) 

                    this.maganePermssionEvent.next(null)
                  }
                },
                error: (error) => {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure(error, {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                  });
                },
              });
            },
            error: (error) => {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure(error, {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              });
            },
          });
        }
      }
    });

    Notiflix.Notify.init({
      position: 'right-bottom',
    });

    // check if user has data in browser storage, else redirect himn to login page
    if (!this.storageService.getItem('bixi-currentProfiles')) {
      this.router.navigateByUrl('/auth/login');
    } else {
      this.userProfiles = JSON.parse(
        this.storageService.getItem('bixi-currentProfiles')
      );
      this.BIXI_userAuthObject = JSON.parse(
        this.storageService.getItem('bixi-user-auth')
      );
    }

    this.editor = new Editor();
    this.getNotes();

    this.editSettings = { allowEditing: true, allowAdding: true };
    this.toolbarPopupOptions = [
      { text: 'Articles', tooltipText: 'Aricles', align: 'Left' },
      {
        text: 'Ajouter',
        tooltipText: 'Ajouter',
        prefixIcon: 'e-add',
        id: 'add',
        align: 'Right',
      },
      {
        text: 'Retirer',
        tooltipText: 'Retirer',
        prefixIcon: 'e-remove',
        id: 'remove',
        align: 'Right',
      },
    ];

    this.headerFeatures = this.accessService.getFeatureByProfile(this.currentProfile.profil);


  }

  flush() {
    this.appInsights.flush();
  }

  @HostListener('document:keypress', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    let defaultMaxContent = 250;
    var regex = /(<([^>]+)>)/gi;
    var result = this.noteContent.replace(regex, '');
    if (this.showNoteRicthText) {
      if (result.length >= defaultMaxContent) {
        Notiflix.Notify.warning('Vous avez atteint les 250 caractères', {
          timeout: 1500,
          width: "400px"
        });
        event.preventDefault();
      } else {
        return null;
      }
    }
  }

  getNotes() {
    if (this.BIXI_userAuthObject !== null) {
      this.navBarService
        .getNotes(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany.id,
          "tableName eq 'DAM Asset' and number eq '" + this.equipementNo + "'"
        )
        .subscribe({
          next: (notes) => {
            this.noteData = notes.value;
          },
          error: (error) => {},
        });
    } else {
      this.router.navigateByUrl('/auth/login');
    }
  }

  @HostListener('paste', ['$event']) onPaste(e: any) {
    let defaultMaxContent = 87;
    var regex = /(<([^>]+)>)/gi;
    var result = this.noteContent.replace(regex, '');
    if (this.showNoteRicthText) {
      if (result.length > defaultMaxContent) {
        this.noteContent = '';
        Notiflix.Notify.warning('Vous avez dépassez 250 caractères', {
          timeout: 1500,
          width: "400px"
        });
      } else {
        return null;
      }
    }
  }

  changeProfile(data) {
    if(!window.location.href.includes('home')){
      this.getBlockActionsByStatusProfile(data);
    }
    else{
      this.changeProfileEvent.emit(data);
    }   
  }

  getBlockActionsByStatusProfile(profile){
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });    
    const workOrderLedSysId = this.storageService.getItem("Bixi_WorkOrderLedSysId");
    const currentWoStatus = this.storageService.getItem("Bixi_WorkOrderBixiStatus");
    this.taskControlService.blockActionsByStatusBtProfile(
      this.BIXI_userAuthObject.tenantId,
      this.currentUserCompany?.id,
      currentWoStatus,
      profile,
      workOrderLedSysId   
    ).subscribe({
      next: (permissions) => {
        this.storageService.setBlockAndReturnScanObject(profile,currentWoStatus,  workOrderLedSysId, permissions.isBlockedAction, permissions.isRetrunScan)
        Notiflix.Loading.remove();
        this.changeProfileEvent.emit(profile);
      },
      error: (error) => {
        this.storageService.setBlockAndReturnScanObject(profile, currentWoStatus,  workOrderLedSysId, true, false)
        Notiflix.Loading.remove();
        this.changeProfileEvent.emit(profile);
      },
    });    
  }
      
  logout() {
    this.changeProfileEvent.emit();
  }

  createNewNote() {
    this.currentSelectedNoteId = '0';
    this.noteContent = '';
    this.showNoteRicthText = true;
    this.viewModeNote = 'A';
    this.disableNoteRicthText = false;
  }

  showNote(numNote: string) {
    let selectNote = this.noteData.filter(
      (entry) => entry['num'] === numNote
    )[0];
    this.noteContent = selectNote['contenu']?.toString() || '';
    this.showNoteRicthText = true;
    this.viewModeNote = 'V';
    this.disableNoteRicthText = true;
  }

  editNote(idNote: string) {
    this.saved = false
    this.currentSelectedNoteId = idNote;
    let selectNote = this.noteData.filter((entry) => entry['id'] === idNote)[0];
    this.noteContent = selectNote['comment']?.toString() || '';
    this.showNoteRicthText = true;
    this.viewModeNote = 'E';
    this.disableNoteRicthText = false;
  }

  onBTClick(url){
    if(window.location.href.includes('detailworkorder')){
          this.homeService
          .getEquipement(
            this.BIXI_userAuthObject.tenantId,
            this.currentUserCompany.id,
            this.equipementNo
          )
          .subscribe({
            next: (equipement) => {
              const equip = equipement.value[0]
              if(equip.multiCorrectifWorkOrder){
                Notiflix.Notify.failure('Liste des bons de travail filtrée sur le numéro BT '+ this.filtredEquipementNo, {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                });
              }else  {
                this.router.navigateByUrl(url)
              }
            },
            error: (error) => {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure(error, {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              });
            },
          });
    }else {
      this.router.navigateByUrl(url)
    }
  }

  deleteNote(idNote: string) {
    this.currentSelectedNoteId = idNote;
    Notiflix.Confirm.show(
      'Confirmation',
      'Voulez-vous vraiment supprimer cette note?',
      'Confirmer',
      'Annuler',
      () => {
        this.confirmDleteNote(idNote);
      },
      () => {},
      {
        width: '450px',
        okButtonBackground: '#04B76B',
        cancelButtonBackground: '#FF5E6D',
        titleColor: '#392C70',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
      }
    );
  }

  confirmDleteNote(idNote: string) {
    let selectNote = this.noteData.filter((entry) => entry['id'] === idNote)[0];
    const index = this.noteData.indexOf(selectNote);
    if (index > -1) {
      // Loading indicator with a message, and the new options
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff',
      });

      const concatenatedUrl = '';

      this.navBarService
        .deleteElement(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany.id,
          this.currentSelectedNoteId,
          concatenatedUrl
        )
        .subscribe({
          next: (note) => {
            this.noteData.splice(index, 1);
            this.notesGrid.refresh();

            Notiflix.Loading.remove();
            Notiflix.Notify.success('Note supprimée avec succès', {
              timeout: 3000,
              showOnlyTheLastOne: true, width: "400px",
            });
          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });
          },
        });
    }
    this.notesGrid.refresh();
  }

  deleteSelectedRows(grid, data: Object[], checkType: boolean): void {
    let selectedRows = grid.getSelectedRecords();
    for (let k = 0; k < selectedRows.length; k++) {
      const index = data.indexOf(selectedRows[k]);
      if (index > -1) {
        if (!checkType || selectedRows[k]['Type'] !== 'Correctif')
          data.splice(index, 1);
      }
    }
  }

  cancelNote() {
    if(!this.saved && this.noteContent.length > 0){
      Notiflix.Confirm.show(
        'Confirmation',
        "Vous n'avez enregistré aucune modification. Êtes-vous sûr de vouloir fermer la note ?",
        'Oui',
        'Non',
        () => {
          this.noteContent = '';
          this.showNoteRicthText = false;
          this.viewModeNote = 'V';
          this.disableNoteRicthText = true;   
        },
        () => {
        },
        {
          width: "450px",
          okButtonBackground: "#04B76B",
          cancelButtonBackground: "#FF5E6D",
          titleColor: "#392C70",
          borderRadius: "5px",
          backgroundColor: "#ffffff",
      });  

    }else {
      this.noteContent = '';
      this.showNoteRicthText = false;
      this.viewModeNote = 'V';
      this.disableNoteRicthText = true;
    }
  }

  saveNote() {
    var regex = /(<([^>]+)>)/gi;
    var result = this.noteContent.replace(regex, '');

    if (result.length <= 250) {
      if (this.viewModeNote == 'E') {
        this.showNoteRicthText = false;
        this.viewModeNote = 'V';
        this.disableNoteRicthText = true;
        let selectNote = this.noteData.filter(
          (entry) => entry['id'] === this.currentSelectedNoteId
        )[0];
        let indexFound = this.noteData.findIndex(
          (entry) => entry['id'] === this.currentSelectedNoteId
        );
        selectNote.comment = result;
        // Loading indicator with a message, and the new options
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff',
        });
        const concatenatedUrl = '';
        const save_id = selectNote.id;
        const save_lineNo = selectNote.lineNo;
        delete selectNote.id;
        delete selectNote.lineNo;

        this.savingDataByRequest = true;
        this.navBarService
          .editElement(
            this.BIXI_userAuthObject.tenantId,
            this.currentUserCompany.id,
            this.currentSelectedNoteId,
            selectNote,
            concatenatedUrl
          )
          .subscribe({
            next: (note) => {
              this.noteData[indexFound] = note;
              this.notesGrid.refresh();

              this.saved = true

              this.savingDataByRequest = false;
              Notiflix.Loading.remove();
              Notiflix.Notify.success('Note modifiée avec succès', {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              });
            },
            error: (error) => {
              selectNote.id = save_id;
              selectNote.lineNo = save_lineNo;

              this.savingDataByRequest = false;
              Notiflix.Loading.remove();
              Notiflix.Notify.failure(error, {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              });
            },
          });
      } else {
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff',
        });
        const concatenatedUrl = '';
        const data = {
          comment: result,
          tableName: 'DAM Asset',
          number: this.equipementNo,
        };
        this.savingDataByRequest = true;
        this.navBarService
          .createElement(
            this.BIXI_userAuthObject.tenantId,
            this.currentUserCompany.id,
            this.currentSelectedNoteId,
            data,
            concatenatedUrl
          )
          .subscribe({
            next: (note) => {
              this.noteData.push(note);
              this.notesGrid.refresh();
              this.showNoteRicthText = false;
              this.viewModeNote = 'V';
              this.disableNoteRicthText = true;
              this.savingDataByRequest = false;
              Notiflix.Loading.remove();
              Notiflix.Notify.success('Note créée avec succès', {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              });
            },
            error: (error) => {
              this.savingDataByRequest = false;
              Notiflix.Loading.remove();
              Notiflix.Notify.failure(error, {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              });
            },
          });
      }
    } else {
      Notiflix.Notify.failure(
        "Vous ne pouvez entrer qu'un maximum de 250 caractères",
        {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
        }
      );
    }
  }

  validateAllTasks() {
    if(!this.isUserBlocked && this.headerFeatures.validate)
       this.validateAllTasksEvent.emit();
    else this.displayPermissionErrorMessage();
  }

  endAllTasks() {
    if(!this.isUserBlocked && this.headerFeatures.terminate)
       this.endAllTasksEvent.emit();
    else this.displayPermissionErrorMessage();    
  }

  goToDetailworkorder() {
    this._location.back();
  }

  printDetailBt() {
    this.printDetailBtEvent.emit()
  }

  printWorkOrderLabel() {   
    if(this.printLabelEquipement && this.printLabelEquipement?.workOrderNo){
  
      if(this.printLabelEquipement.multiCorrectifWorkOrder){
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff',
        });
        this.openWorkOrderService
          .printWorkOrderLabel(
            this.BIXI_userAuthObject.tenantId,
            this.currentUserCompany.id,
            this.printLabelEquipement?.workOrderNo
          )
          .subscribe({
            next: (report) => {
              Notiflix.Loading.remove();
              if (report?.value)
                this.communService.openPdfFileInNewTab(report?.value);
              else
                Notiflix.Notify.failure(
                  `Aucune étiquette trouvée pour le bon de travail ${this.printLabelEquipement?.workOrderNo} `
                );
            },
            error: (error) => {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure(error, {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              });
            },
          });
      }
      else{
        Notiflix.Notify.warning("Impossible d'imprimer le bon de travail d'un équipement non virtuel", {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
        }); 
      }
    }
    else{
      Notiflix.Notify.failure("Impossible d'imprimer le bon de travail. Equipement non trouvé ou Bon de travail invalide", {
        timeout: 3000,
        showOnlyTheLastOne: true, 
        width: "400px",
      });       
    }
  }

 reopenAllFinishedTasks(){
    if(!this.isUserBlocked && this.headerFeatures.reopenTerminatedTask)
       this.reopenAllFinishedTasksEvent.emit();
    else this.displayPermissionErrorMessage();
 }

 reopenAllValidatedTasks(){
    if(!this.isUserBlocked && this.headerFeatures.reopenValidatedTask)
       this.reopenAllValidatedTasksEvent.emit();
    else this.displayPermissionErrorMessage();

 }

 formatDateString(inputDateString) {
  const inputDate = new Date(inputDateString); 
  // Extract date components
  const year = inputDate.getFullYear() % 100; // Get last two digits of the year
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getDate()).padStart(2, '0');
  // Extract time components
  const hours = String(inputDate.getHours()).padStart(2, '0');
  const minutes = String(inputDate.getMinutes()).padStart(2, '0');  
  // Format the output string
  const outputString = `${year}-${month}-${day} ${hours}:${minutes}`;
  return outputString;
 }

  private displayPermissionErrorMessage() {
    if(this.storageService.getItem('Bixi_BlockAndReturnScan')){
      const statusApiPermissions:StatusProfileBlockAndReturnScan = JSON.parse(this.storageService.getItem("Bixi_BlockAndReturnScan")) || null;
      if(statusApiPermissions && statusApiPermissions.isBlockedAction){
        Notiflix.Notify.warning(
            "Le statut en cours "+ statusApiPermissions.currentStatus+" du bon de travail bloque cette opération pour le profil " +
              this.communService.roleEnglishToFrench[statusApiPermissions.currentProfile],{
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            }
          );
      }
      else {
        Notiflix.Notify.warning(
            "Vous ne pouvez pas effectuer cette action car vous êtes un " +
              this.communService.roleEnglishToFrench[statusApiPermissions.currentProfile],{
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            }
          );
      }
    } 
  }

}
