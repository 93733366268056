import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Notiflix from 'notiflix';
import { Profile } from 'src/app/core/shared/models/profile';
import { SectionLine } from 'src/app/core/shared/models/template/SectionLine.model';
import { Task } from 'src/app/core/shared/models/template/task.model';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { HomeService } from '../home/services/home.service';
import { Equipement } from 'src/app/core/shared/models/equipement';
import { Company } from 'src/app/core/shared/models/company.model';
import { CommunService } from 'src/app/services/commun.service';
import { TemplateMakerService } from './services/template-maker.service';
import { AssetCategory } from 'src/app/core/shared/models/AssetCategory';
import { SubsectionLine } from 'src/app/core/shared/models/template/SubsectionLine.model';
import { AuthService } from 'src/app/core/auth/services/auth.service';
declare var bootstrap: any;

@Component({
  selector: 'app-template-maker',
  templateUrl: './template-maker.component.html',
  styleUrls: ['./template-maker.component.scss']
})
export class TemplateMakerComponent implements OnInit {

  @ViewChild('templateSectionLine', { static: false }) templateSectionLine?:any; 
  @ViewChild('templateSectionLinePosition', { static: false }) templateSectionLinePosition?:any; 


  @ViewChild('templateSubSectionLineAlignement', { static: false }) templateSubSectionLineAlignement?:any; 
  @ViewChild('templateSectionLineDescription', { static: false }) templateSectionLineDescription?:any; 
  @ViewChild('templateSubsctionLine', { static: false }) templateSubsctionLine?:any; 
  @ViewChild('templateSubsctionLineDescription', { static: false }) templateSubsctionLineDescription?:any; 
  @ViewChild('leftLabelElement', { static: false }) leftLabelElement?:any; 
  @ViewChild('rightLabelElement', { static: false }) rightLabelElement?:any; 
  @ViewChild('taskDescription', { static: false }) taskDescription?:any; 
  @ViewChild('templateSubSectionLinePosition', { static: false }) templateSubSectionLinePosition?:any; 
  @ViewChild('templateTaskLinePosition', { static: false }) templateTaskLinePosition?:any; 
  @ViewChild('defaultTaskElement', { static: false }) defaultTaskElement?:any; 
  @ViewChild('taskCategoryElement', { static: false }) taskCategoryElement?:any; 
  @ViewChild('templateHeaderAssetCategoryDescriptionElement', { static: false }) templateHeaderAssetCategoryDescriptionElement?:any; 
  @ViewChild('templateHeaderAssetCategoryCodeElement', { static: false }) templateHeaderAssetCategoryCodeElement?:any; 
  @ViewChild('templateHeaderDescriptionElement', { static: false }) templateHeaderDescriptionElement?:any; 
  @ViewChild('templateColumnsNbElement', { static: false }) templateColumnsNbElement?:any; 
  @ViewChild('sectionDisplayNameElement', { static: false }) sectionDisplayNameElement?:any; 
  @ViewChild('sectionLineElement_', { static: false }) sectionLineElement_?:any; 
  @ViewChild('subsctionTaskLineElement', { static: false }) subsctionTaskLineElement?:any; 
  @ViewChild('fieldTypeElement', { static: false }) fieldTypeElement?:any; 
  @ViewChild('fieldMinElement', { static: false }) fieldMinElement?:any; 
  @ViewChild('fieldMaxElement', { static: false }) fieldMaxElement?:any; 
  
  pageName = 'templateMaker'
  currentUserName = "";
  currentUserCompany: Company | undefined;
  userProfil = "";
  isModeEditor = true;
  makerModeBtnLabel = "Mode visuel";
  makerModeBtnIcon = "fa fa-eye";
  isTemplateHeaderCreated = false;
  isTemplateSectionCreated = false;
  isTemplateSubSectionCreated = false;
  headerTemplateMode = false;
  sectionTemplateMode = false;
  subsectionTemplateMode = false;
  taskTemplateMode = false;
  formTemplateModalTitle = "";
  labelSection = "";
  labelGSection = "";
  labelDSection = "";
  clickedPosition = 0;
  clickedSection = "";
  labelSubsection = "";

  template: any = {
    code: '',
    description: '',
    codeAssetCategory: '',
    status: '',
    systemId: '',
    templateColumns: 0,
    columnLines: []
  }

  tempTemplate: any = {
    code: '',
    description: '',
    codeAssetCategory: '',
    status: '',
    systemId: '',
    templateColumns: 0,
    columnLines: []
  }

  templateSection = [] as SectionLine[];
  section = {} as SectionLine;
  templateSubsection = [] as SubsectionLine[];
  subsection = {} as SubsectionLine;
  templateTask = [] as Task[];
  task = {} as Task;
  sectionCounter = 0;
  subsectionCounter = 0;
  sectionPosition = 0;
  subsectionPosition = 0;
  currentselectSection = "";
  currentsubselectSection: any;
  currentselectLabelG = "";
  currentselectLabelD = "";

  taskColumnPosition = 0;
  taskSection = "";
  equipementNo: string = ''
  equipement: Equipement = null
  sectionLineDataSelectedIndex = 0

  saveMode ="save";
  saveBtnModal = "Créer"
  taskFakeData = [
    {value: "ajuster", option: "ajuster"},
    {value: "changer", option: "changer"},
    {value: "graisser", option: "graisser"},
    {value: "installer", option: "installer"},
  ].sort((a,b) => a.value.localeCompare(b.value));

  userProfilTitle = "";
  BIXI_userAuthObject: any = null
  currentProfile: Profile = null
  currentProfiles: Profile[] = []
  workOrderNo = ''
  assetCategories: AssetCategory[] = []

  hideLeftAndRightPosition = true
  currentColumnSelectedIndex: number = 0
  currentSectionSelectedIndex: number = 0
  currentSubSectionSelectedIndex: number = 0
  currentTaskSelectedIndex: number = 0
  selectTaskPosition = ''
  taskLinePosition = 0

  sectionDescriptionEdit = ''
  sectionLeftLabelEdit = ''
  sectionRightLabelEdit = ''
  subSectionDescriptionEdit = ''
  taskDescriptionEdit = ''
  taskDefaultEdit = ''
  taskPositionSelected = ''

  taskLineDescriptionEdit = ''
  taskLineCodeTaskCategoryEdit = ''
  taskLineFieldType = 'Selection'
  taskLineFieldMINValue = '0'
  taskLineFieldMAXValue = '0'
  taskLineDefaultTask = 'false'


  clickEditMode = false
  currentEditableDataIndexPosition = 0

  action = ''
  editExistantTemplate = false

  currentTemplateSystemId = ''
  duplicateMode = false
  defDisplayNames: any[] = []
  taskCategories: any[] = []

  sameTaskCategoryError = false
  sectionPositionValidation = 0
  subSectionPositionValidation = 0


  constructor(private router: Router,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private homeService: HomeService,
    private communService: CommunService,
    private templateMakerService: TemplateMakerService,
    private AuthService: AuthService) {  }

  ngOnInit() {

    Notiflix.Notify.init({
      position: 'right-bottom'
    });

        // check if user has data in browser storage, else redirect himn to login page
        if(!this.storageService.getItem("bixi-user-auth") || !this.storageService.getItem("bixi-currentCompany") || !this.storageService.getItem("bixi-currentProfile")){
          this.router.navigateByUrl('/auth/login')
        }else{
          this.BIXI_userAuthObject = JSON.parse(this.storageService.getItem("bixi-user-auth"));
          this.currentUserCompany = JSON.parse(this.storageService.getItem("bixi-currentCompany"));
          this.currentProfile = JSON.parse(this.storageService.getItem("bixi-currentProfile"));
          this.currentProfiles = JSON.parse(this.storageService.getItem("bixi-currentProfiles"));
          this.equipementNo =  this.storageService.getItem('currentSerialNumber')
        }

    if(window.location.href.includes('?mode=duplicate')){
      // the template is in a duplicate mode
      this.duplicateMode = true
      this.displayTemplateModel("save","templateHeader", 0, 0, 0,'', 0, '', 0, '', 0, '');  
    }

    this.getEquipement()
    this.templateMakerService.getAssetCategories(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id).subscribe({
      next: (assetCategoryObject) => {
        this.assetCategories = assetCategoryObject.value
      },
      error: (error) => {
      }
    })

    this.templateMakerService.getPortailDefDisplayNames(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id).subscribe({
      next: (defDisplayNames) => {
        this.defDisplayNames = defDisplayNames.value
      },
      error: (error) => {
      }
    })

    this.templateMakerService.getWorkOrderCategories(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id).subscribe({
      next: (taskCategories) => {
        this.taskCategories = taskCategories.value
      },
      error: (error) => {
      }
    })

    this.currentUserName = localStorage.getItem("bixiApp_currentUserName");
    this.userProfil = localStorage.getItem("bixiApp_userProfil") || ""

  }

  ngOnDestroy(): void {
    setTimeout(() =>{
      const elem: any = document.getElementsByClassName('modal-backdrop')[document.getElementsByClassName('modal-backdrop').length - 1]
      if(typeof elem !== 'undefined'){
        elem.classList.remove('show')
        elem.style.display = 'none'
      }
    }, 100)
  }

  getEquipement(){
    this.activatedRoute.params.subscribe(params => {
      if(params['equipementNo']){
        this.equipementNo = params['equipementNo'];
        // checjk if equipementNo is in url
        if(this.equipementNo){
          this.equipementNo = this.equipementNo.replace('%20', ' ')       
          Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff'
        });
        this.homeService.getEquipement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.equipementNo).subscribe({
          next: (equipement) => {
            this.equipement = equipement.value[0]
            Notiflix.Loading.remove();
          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
             })
          }
        }) 
        }else{
        }
      }

      if(params['systemId']){
        this.currentTemplateSystemId = params['systemId']
        this.editExistantTemplate = true
        this.templateMakerService.getTemplateByTemplateSystemId(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.currentTemplateSystemId, "columnLines($expand=sectionLines($expand=subSectionLines($expand=taskLines)))").subscribe({
          next: (template) => {       
            this.isTemplateHeaderCreated = true
            if(window.location.href.includes('?mode=duplicate')){
              this.continueProcess(template)             
            }else{
              if(template.status == 'Open'){
                this.continueProcess(template)            
              }else{
                Notiflix.Notify.failure("Ce template est déjà publié(Release). Impossible de le modifier.", {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                })
                history.back()
              }
            } 
          },
          error: (error) => {  
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
             })
          }
        })
      }else{
        this.displayTemplateModel("save","templateHeader", 0, 0, 0,'', 0, '', 0, '', 0, '');  
      }
    })
  }

  continueProcess(template){
    template.columnLines.sort(function(a, b) {
      return parseFloat(a.showingOrder) - parseFloat(b.showingOrder);
      });
    template.columnLines.forEach(columnLine => {
      columnLine.sectionLines.sort(function(a, b) {
        return parseFloat(a.showingOrder) - parseFloat(b.showingOrder);
        });
    });
    template.columnLines.forEach(columnLine => {
      columnLine.sectionLines.forEach(sectionLine => {  
        sectionLine.subSectionLines.sort(function(a, b) {
          return parseFloat(a.showingOrder) - parseFloat(b.showingOrder);
          });
      });
      
    });

    template.columnLines.forEach(columnLine => {
      columnLine.sectionLines.forEach(sectionLine => {       
        sectionLine.subSectionLines.forEach(subSectionLine => {
          subSectionLine.taskLines.sort(function(a, b) {
            return parseFloat(a.showingOrder) - parseFloat(b.showingOrder);
            });        
        });
      });   
    });
    template.columnLines.forEach(columnLine => {
      columnLine.sectionLines.forEach(sectionLine => {     
        sectionLine.subSectionLines.forEach(subSectionLine => {
          subSectionLine.taskLines.forEach(taskLine => {
            taskLine.code = this.communService.generateRandomCode()
          });         
        });
      });  
    });
    this.template = template
    this.tempTemplate = template
    if(window.location.href.includes('?mode=duplicate')){             
      this.template.code = ''
      this.tempTemplate.code = ''
      this.template.codeAssetCategory = ''
      this.tempTemplate.codeAssetCategory = ''
      this.template.codeAssetCategory = ''
      this.tempTemplate.codeAssetCategory = ''
    }
    this.sectionPositionValidation = this.clickEditMode ? this.currentEditableDataIndexPosition : this.template.columnLines[this.currentColumnSelectedIndex] !== undefined ? this.template.columnLines[this.currentColumnSelectedIndex].sectionLines.length + 1 : 1
    this.subSectionPositionValidation = this.clickEditMode ? this.currentEditableDataIndexPosition : this.template.columnLines[this.currentColumnSelectedIndex] !== undefined ? this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex] !== undefined ? this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines.length + 1 : 1 : 1
    Notiflix.Loading.remove();
  }

  removeTask(colmunIndex, sectionIndex, subsectionIndex, taskIndex, taskPosition){
    this.currentColumnSelectedIndex = colmunIndex
    this.currentSectionSelectedIndex = sectionIndex
    this.currentSubSectionSelectedIndex = subsectionIndex
    this.currentTaskSelectedIndex = taskIndex
    let concatenatedUrl = ''
    Notiflix.Confirm.show(
      'Confirmation',
      "Êtes-vous sûr de vouloir supprimer cette tâche?",
      'Confirmer',
      'Annuler',
      () => {
        if (sectionIndex >= -1 && subsectionIndex >= -1 && taskIndex>= -1) {
          if(this.editExistantTemplate && !this.duplicateMode){
            // Loading indicator with a message, and the new options
            Notiflix.Loading.standard('Chargement...', {
              svgColor: '#ffffff'
            });     
            concatenatedUrl = "/columnLines("+ this.template.columnLines[this.currentColumnSelectedIndex].systemId +")/sectionLines(" + this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].systemId +")/subSectionLines(" + this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].systemId +")/taskLines(" + this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[taskIndex].systemId +")"
            this.templateMakerService.deleteElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.currentTemplateSystemId, concatenatedUrl).subscribe({
              next: (resData) => {
                this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.splice(this.currentTaskSelectedIndex, 1)
                Notiflix.Loading.remove();
                // this.reloadCurrentRoute()
              },
              error: (error) => {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(error, {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                 })
              }
            })
          }
        }     
      },
      () => {
      },
      {
        width: "450px",
        okButtonBackground: "#04B76B",
        cancelButtonBackground: "#FF5E6D",
        titleColor: "#392C70",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
    });    
 
  }

  removeSubsection(colmunIndex, sectionIndex, subsectionIndex){
    this.currentColumnSelectedIndex = colmunIndex
    this.currentSectionSelectedIndex = sectionIndex
    this.currentSubSectionSelectedIndex = subsectionIndex
    let concatenatedUrl = ''
    Notiflix.Confirm.show(
      'Confirmation',
      "Êtes-vous sûr de vouloir supprimer cette sous-section?",
      'Confirmer',
      'Annuler',
      () => {
        if (sectionIndex >= -1 && subsectionIndex >= -1) {          
          concatenatedUrl = "/columnLines("+ this.template.columnLines[this.currentColumnSelectedIndex].systemId +")/sectionLines(" + this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].systemId +")/subSectionLines(" + this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[subsectionIndex].systemId +")"       
            if(this.editExistantTemplate && !this.duplicateMode){
              // Loading indicator with a message, and the new options
              Notiflix.Loading.standard('Chargement...', {
                svgColor: '#ffffff'
              });
              this.templateMakerService.deleteElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.currentTemplateSystemId, concatenatedUrl).subscribe({
                next: (resData) => {
                  Notiflix.Loading.remove();
                  this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines.splice(this.currentSubSectionSelectedIndex, 1)    
                  // this.reloadCurrentRoute()       
                },
                error: (error) => {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure(error, {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                   })
                }
              })      
            }
        }      
      },
      () => {
      },
      {
        width: "450px",
        okButtonBackground: "#04B76B",
        cancelButtonBackground: "#FF5E6D",
        titleColor: "#392C70",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
    });
  }

  removeSection(columnIndex, sectionIndex){
    this.currentColumnSelectedIndex = columnIndex
    this.currentSectionSelectedIndex = sectionIndex
    let concatenatedUrl = ''
    Notiflix.Confirm.show(
      'Confirmation',
      "Êtes-vous sûr de vouloir supprimer cette section?",
      'Confirmer',
      'Annuler',
      () => {
        if (sectionIndex >= -1) {
          concatenatedUrl = "/columnLines("+ this.template.columnLines[this.currentColumnSelectedIndex].systemId +")/sectionLines(" + this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[sectionIndex].systemId +")"
          if(this.editExistantTemplate && !this.duplicateMode){
            // Loading indicator with a message, and the new options
            Notiflix.Loading.standard('Chargement...', {
              svgColor: '#ffffff'
            });
            this.templateMakerService.deleteElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.currentTemplateSystemId, concatenatedUrl).subscribe({
              next: (resData) => {
                Notiflix.Loading.remove();
                this.template.columnLines[this.currentColumnSelectedIndex].sectionLines.splice(sectionIndex, 1)      
              },
              error: (error) => {      
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(error, {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                 })    
              }
            })  
          }
        }      
      },
      () => {
      },
      {
        width: "450px",
        okButtonBackground: "#04B76B",
        cancelButtonBackground: "#FF5E6D",
        titleColor: "#392C70",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
    });    
  }

  checkUserProfil(){
    this.userProfil = localStorage.getItem("bixiApp_userProfil") || "";
  }

  changeProfile(profil){
    if(profil){
      const profileFound = this.currentProfiles.find(currentProfile => currentProfile.profil == profil)
      localStorage.setItem("bixi-currentProfile", JSON.stringify(profileFound));
      this.reloadCurrentRoute()     
    }else{
      this.AuthService.logout()
    } 
  }

  logout(){
    Notiflix.Confirm.show(
      'Confirmation',
      "Êtes-vous sûr de vouloir vous déconnecter?",
      'Confirmer',
      'Annuler',
      () => {
        this.router.navigateByUrl('/')
      },
      () => {
      },
      {
        width: "450px",
        okButtonBackground: "#04B76B",
        cancelButtonBackground: "#FF5E6D",
        titleColor: "#392C70",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
      });
  } 

  disableModeEditor(){
    if(this.isModeEditor){
      this.isModeEditor = false;
      this.makerModeBtnLabel = "Mode editeur";
      this.makerModeBtnIcon = "fa fa-edit";
    }
    else{
      this.isModeEditor = true;
      this.makerModeBtnLabel = "Mode visuel";
      this.makerModeBtnIcon = "fa fa-eye"; 
    }
  }


  selectTask($event){

  }

  getTaskLineIcon(displayName, columnLineIndex, sectionLineIndex, subSectionLineIndex, taskLineIndex){
   const dataFound = this.defDisplayNames.filter(defDisplayName => defDisplayName.code == displayName)
   if(dataFound.length > 0){
      const content64 = dataFound[0].content64
      const element:any = document.getElementById('taskLine_icon_'+ columnLineIndex +'_'+ sectionLineIndex +'_'+ subSectionLineIndex +'_'+taskLineIndex)
      if(element !== null){
        if(content64.trim() !== ''){
          element.src = "data:image/png;base64,"+content64
        }else{
          element.style.display = 'none'
        }    
      }   
   }
  }

  displayTemplateModel(
      saveMode: string,
      mode:string,
      colmunIndex: number, 
      sectionIndex: number,
      subsectionIndex: number,
      section: string,
      sectionPosition: number,
      subsection:string,
      subsectionPosition: number,
      taskPosition: string,
      taskIndex: number,
      taskCode: string
      ){
        this.currentColumnSelectedIndex = colmunIndex
        this.currentSectionSelectedIndex = sectionIndex
        this.currentSubSectionSelectedIndex = subsectionIndex
        this.currentTaskSelectedIndex = taskIndex
        // vider tous les champ a chaque nouvelle création
        if(this.isModeEditor){
          this.disableTemplateModes();
          this.saveMode = saveMode;
          this.sectionPosition = sectionPosition;               
          this.subsectionPosition = subsectionPosition;
          switch(mode){
            case "templateHeader":
              this.action = 'new-template'         
              if(!this.duplicateMode && !this.editExistantTemplate){
                this.template.codeAssetCategory = this.template.codeAssetCategory.trim() == '' ? "VELO_E": this.tempTemplate.codeAssetCategory
                this.template.templateColumns = 1;
                this.template.status = "Open";
              }
              this.formTemplateModalTitle = "Ajouter un entête";
              this.headerTemplateMode = true;
                var myModal = new bootstrap.Modal(document.getElementById('templateModal'), {})
                myModal.toggle()                
                setTimeout(() =>{
                  this.templateHeaderDescriptionElement.nativeElement.focus();
                }, 700)
              break;
            case "templateSection":
              // this.section.position = index;
              if(this.saveMode == "save"){
                this.clickEditMode = false
                // this.section.code = this.sectionLinesData[0].code;
                // this.currentselectSection = this.sectionLinesData[0].displayName;
                // this.currentselectLabelG = this.sectionLinesData[0].leftLabel;
                // this.currentselectLabelD = this.sectionLinesData[0].rightLabel
                this.formTemplateModalTitle = "Ajouter une section";
                this.saveBtnModal = "Créer";              
              }
              else{
                this.clickEditMode = true
                this.currentEditableDataIndexPosition = this.currentSectionSelectedIndex + 1
                this.sectionDescriptionEdit = this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].displayName
                this.sectionLeftLabelEdit = this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].leftLabel
                this.sectionRightLabelEdit = this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].rightLabel
                this.formTemplateModalTitle = "Modifier la section";
                this.saveBtnModal = (this.editExistantTemplate && !this.duplicateMode) ? "Sauvegarder" : (this.editExistantTemplate && this.duplicateMode) ? "Terminer" : "Terminer"
              }
              this.sectionPositionValidation = this.clickEditMode ? this.currentEditableDataIndexPosition : this.template.columnLines[this.currentColumnSelectedIndex] !== undefined ? this.template.columnLines[this.currentColumnSelectedIndex].sectionLines.length + 1 : 1
              var myModal = new bootstrap.Modal(document.getElementById('templateModal'), {})
              myModal.toggle()               
              this.sectionTemplateMode = true;
              break;
            case "templateSubsection":
              // this.subsection.position = index;
              if(this.saveMode == "save"){
                // currentEditableDataIndexPosition : this.template.columnLines[currentColumnSelectedIndex].sectionLines[currentSectionSelectedIndex].subSectionLines.length
                this.clickEditMode = false
                this.formTemplateModalTitle = "Ajouter une sous-section";
                this.saveBtnModal = "Créer";        
              }
              else{             
                this.clickEditMode = true
                this.currentEditableDataIndexPosition = this.currentSubSectionSelectedIndex + 1
                this.subSectionDescriptionEdit =  this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].displayName
                this.formTemplateModalTitle = "Modifier la sous-section";                                     
                this.saveBtnModal = (this.editExistantTemplate && !this.duplicateMode) ? "Sauvegarder" : (this.editExistantTemplate && this.duplicateMode) ? "Terminer" : "Terminer"     
              }

              this.subSectionPositionValidation = this.clickEditMode ? this.currentEditableDataIndexPosition : this.template.columnLines[this.currentColumnSelectedIndex] !== undefined ? this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex] !== undefined ? this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines.length + 1 : 1 : 1
              var myModal = new bootstrap.Modal(document.getElementById('templateModal'), {});
              myModal.toggle();               
              this.subsectionTemplateMode = true;
              break;
            case "templateTask":
              if(this.saveMode == "save"){

                this.taskPositionSelected = taskPosition
                this.clickEditMode = false

                this.selectTaskPosition = taskPosition

                this.formTemplateModalTitle = "Ajouter une tâche";
                this.saveBtnModal = "Créer";        

                this.taskLinePosition = this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.filter(taskLine => taskLine.position == taskPosition).length
                
                this.taskLineFieldMINValue = '0'
                this.taskLineFieldMAXValue = '0'
                this.taskLineCodeTaskCategoryEdit = ''
                this.taskLineFieldType = 'Selection'
                this.taskLineDefaultTask = 'false'
              }
              else {
                this.taskPositionSelected = taskPosition
                
                this.clickEditMode = true

                const arrayDataFound = this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.filter(taskLine => taskLine.position == taskPosition)

                const index = arrayDataFound.findIndex(data => data.code == taskCode)
 
                this.currentEditableDataIndexPosition = index + 1

                this.taskLineDescriptionEdit = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].displayName
                this.taskLineCodeTaskCategoryEdit = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].codeTaskCategory            
                this.taskLineFieldType = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].fieldType
                this.taskLineFieldMINValue = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].fieldMINValue
                this.taskLineFieldMAXValue = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].fieldMAXValue
                this.taskLineDefaultTask = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].defaultTask


                this.formTemplateModalTitle = "Modifier la tâche";                                     
                this.saveBtnModal = (this.editExistantTemplate && !this.duplicateMode) ? "Sauvegarder" : (this.editExistantTemplate && this.duplicateMode) ? "Terminer" : "Terminer"
              } 
              var myModal = new bootstrap.Modal(document.getElementById('templateModal'), {});
              myModal.toggle();              
              this.taskTemplateMode = true;                          
              break;
            default:
              break;      
          }
        }
        

        if(this.templateSectionLineDescription !== undefined && !this.clickEditMode){
          this.templateSectionLineDescription.nativeElement.value = ""
        }

        if(this.leftLabelElement !== undefined && !this.clickEditMode){
          this.leftLabelElement.nativeElement.value = ""
        }

        if(this.rightLabelElement !== undefined && !this.clickEditMode){
          this.rightLabelElement.nativeElement.value = ""
        }


        if(this.templateSubsctionLineDescription !== undefined && !this.clickEditMode){
          this.templateSubsctionLineDescription.nativeElement.value = ""
        }

        if(this.templateSubSectionLineAlignement !== undefined && !this.clickEditMode){
          this.templateSubSectionLineAlignement.nativeElement.value = "Center"
        }

        if(this.defaultTaskElement !== undefined && !this.clickEditMode){
          this.defaultTaskElement.nativeElement.value = "false"
        }


  }

  onAssetCategoryChange(event){

    this.template.codeAssetCategory = event.target.value

  }

  onFieldTypeChange(event){
    this.taskLineFieldType = event.target.value

 
    if(event.target.value == 'Selection'){
      this.taskLineFieldMINValue = '0'
      this.taskLineFieldMAXValue = '0'

      this.fieldMinElement.nativeElement.value = '0'
      this.fieldMaxElement.nativeElement.value = '0'
    }
  }

  sendTheNewValue(event){

    const foundData = this.assetCategories.filter(assetCategorie => assetCategorie.code == event.target.value)

    if(foundData.length > 0){
      this.templateHeaderAssetCategoryCodeElement.nativeElement.value = foundData[0].code
      this.template.codeAssetCategory = foundData[0].code
    }else{
      this.templateHeaderAssetCategoryCodeElement.nativeElement.value = ''
    }

  }

  disableTemplateModes(){
    this.headerTemplateMode = false;
    this.sectionTemplateMode = false;
    this.subsectionTemplateMode = false;
    this.taskTemplateMode = false;
    this.section = {} as SectionLine;  
    this.subsection = {} as SubsectionLine;  
    this.task = {} as Task;  
  }

  addNewColumn(){
    if(this.template.templateColumns > this.template.columnLines.length){
      if(this.editExistantTemplate && !this.duplicateMode){
        // Loading indicator with a message, and the new options
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff'
        });
    }
      this.template.columnLines.push({
        lineType: 'column',
        displayName: 'C'+ (this.template.columnLines.length + 1),
        sectionLines: []
      })
      setTimeout(() => { 
        if(this.editExistantTemplate && !this.duplicateMode){
          const concatenatedUrl = "/columnLines"
          const data = this.template.columnLines[this.template.columnLines.length - 1]   
            this.templateMakerService.createElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.currentTemplateSystemId, data, concatenatedUrl).subscribe({
              next: (resData) => {
                this.template.columnLines[this.template.columnLines.length - 1] = resData 
                if(typeof this.template.columnLines[this.template.columnLines.length - 1].columnLines == 'undefined'){
                  this.template.columnLines[this.template.columnLines.length - 1].columnLines = []
                }
                Notiflix.Loading.remove();
                this.reloadCurrentRoute()
              },
              error: (error) => {      
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(error, {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                 })  
              }
            })
          }
      }, 2000)
    }else{
      Notiflix.Notify.failure("Veuillez augmenter le nombre de colonnes dans l'entête du template", {
        timeout: 3000,
        showOnlyTheLastOne: true, 
        width: "400px",
       })
    }
  }

  removeColumn(columnIndex){
    Notiflix.Confirm.show(
      'Confirmation',
      "Êtes-vous sûr de vouloir supprimer cette colonne ?",
      'Oui',
      'Non',
      () => {
        const oldData = this.template.columnLines[columnIndex]
        this.template.columnLines.splice(columnIndex, 1)
        if(this.editExistantTemplate && !this.duplicateMode){
          // Loading indicator with a message, and the new options
          Notiflix.Loading.standard('Chargement...', {
            svgColor: '#ffffff'
          });
          const concatenatedUrl = "/columnLines("+ oldData.systemId +")"
          this.templateMakerService.deleteElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.currentTemplateSystemId, concatenatedUrl).subscribe({
            next: (resData) => {
              Notiflix.Loading.remove();
                  //rename columns dynamically
                  for(let i = 0; i < this.template.columnLines.length; i++ ){
                    this.template.columnLines[i].displayName = 'C'+ (i + 1)
                    this.tempTemplate = this.template
                    const concatenatedUrl = "/columnLines(" + this.template.columnLines[i].systemId +")"
                    const data = this.template.columnLines[i] 
                    const save_systemId = data.systemId
                    const save_showingOrder = data.showingOrder
                    delete data.systemId
                    delete data.showingOrder
                    const _data = { ...data }
                    _data.sectionLines = []
                      this.templateMakerService.editElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.currentTemplateSystemId, _data, concatenatedUrl).subscribe({
                        next: (resData) => {
                          if(i == this.tempTemplate.columnLines.length - 1){
                            this.reloadCurrentRoute()
                          }                
                        },
                        error: (error) => {
                          data.systemId = save_systemId
                          data.showingOrder = save_showingOrder               
                          Notiflix.Loading.remove();
                          Notiflix.Notify.failure(error, {
                            timeout: 3000,
                            showOnlyTheLastOne: true, 
                            width: "400px",
                           })                
                        }
                      })                   
                  }                   
            },
            error: (error) => {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure(error, {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
               })
            }
          })   
        }else{
          for(let i = 0; i < this.template.columnLines.length; i++ ){
            this.template.columnLines[i].displayName = 'C'+ (i + 1)
            this.tempTemplate = this.template
          }
        }
      },
      () => {
      },
      {
        width: "450px",
        okButtonBackground: "#04B76B",
        cancelButtonBackground: "#FF5E6D",
        titleColor: "#392C70",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
    });


  }

  getTaskIcon(taskLineDisplayName){
    this.templateMakerService.getPortailDefDisplayNamesWithFilter(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "code eq '" + taskLineDisplayName + "'").subscribe((response) => {
      return "data:image/png;base64,"+response.value[0].content64;
    },
    error => {}
  )
  }

  createTemplateHeader(){
    this.isTemplateHeaderCreated = true;

    // check first if a such template exists already

    this.templateMakerService.getTemplateByFilters(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "codeAssetCategory eq '"+ this.template.codeAssetCategory +"' ").subscribe({
      next: (template) => {
        if(template.value.length == 0){
          this.templateMakerService.getTemplateByFilters(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "code eq '"+ this.template.code +"' ").subscribe({
            next: (template) => {
              if(template.value.length == 0){
                if(this.template.code.length <= 100){
                  if(!this.duplicateMode){
                    for(let i = 0; i <this.template.templateColumns; i++){
                      this.template.columnLines.push({
                        lineType: 'column',
                        displayName: 'C'+ (i + 1),
                        sectionLines: []
                      })
                    }
                  }
                }else{
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure("Le code du template ne doit pas dépasser 100 caractères", {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                  })
                  this.displayTemplateModel("save","templateHeader", 0, 0, 0,'', 0, '', 0, '', 0, '');  
                }         
              }else{
                this._continue(template)
              }
            },
            error: (error) => {
            }
          })      
        }else{
          this._continue_2(template)
        }
      },
      error: (error) => {
      }
    })
  }

  _continue(template){
    if(!this.duplicateMode){
      Notiflix.Confirm.show(
        'Message',
        "Un template possède déjà le même code dans le système. Souhaiteriez-vous le modifier?",
        'Oui',
        'Non',
        () => {
          if(this.equipementNo.trim() !== ''){
            this.router.navigateByUrl('/templatemaker/'+this.currentUserCompany.id+'/'+this.currentProfile.id +'/serial-number/' +this.equipementNo+ '/template/'+template.value[0].systemId)       
          }else{
            this.router.navigateByUrl('/templatemaker/'+this.currentUserCompany.id+'/'+this.currentProfile.id +'/template/'+template.value[0].systemId)
          }                
        },
        () => {
          this.cancelTemplateCreate()
        },
        {
          width: "450px",
          okButtonBackground: "#04B76B",
          cancelButtonBackground: "#FF5E6D",
          titleColor: "#392C70",
          borderRadius: "5px",
          backgroundColor: "#ffffff",
      }); 
      }else{
        Notiflix.Notify.failure("Un template possède déjà le même code dans le système. Veuillez, s'il vous plait changer le code du template pour continuer", {
          timeout: 6000,
          showOnlyTheLastOne: true, 
          width: "400px",
         })
         this.displayTemplateModel("save","templateHeader", 0, 0, 0,'', 0, '', 0, '', 0, '');  
      } 
  }

  _continue_2(template){
    if(!this.assetCategories){
      Notiflix.Confirm.show(
        'Message',
        "Un template "+ this.assetCategories.filter(assetCategory => assetCategory.code == this.template.codeAssetCategory)[0].description +" existe déjà dans le système. Souhaiteriez-vous le modifier?",
        'Oui',
        'Non',
        () => {
          if(this.equipementNo.trim() !== ''){
            this.router.navigateByUrl('/templatemaker/'+this.currentUserCompany.id+'/'+this.currentProfile.id +'/serial-number/' +this.equipementNo+ '/template/'+template.value[0].systemId)
          }else{
            this.router.navigateByUrl('/templatemaker/'+this.currentUserCompany.id+'/'+this.currentProfile.id +'/template/'+template.value[0].systemId)
          }       
        },
        () => {
          this.cancelTemplateCreate()
        },
        {
          width: "450px",
          okButtonBackground: "#04B76B",
          cancelButtonBackground: "#FF5E6D",
          titleColor: "#392C70",
          borderRadius: "5px",
          backgroundColor: "#ffffff",
      }); 
      }else{
        Notiflix.Notify.failure("Un template "+ this.assetCategories.filter(assetCategory => assetCategory.code == this.template.codeAssetCategory)[0].description +" existe déjà dans le système. Veuillez, s'il vous plait changer Asset category code et Asset category code pour continuer", {
          timeout: 6000,
          showOnlyTheLastOne: true, 
          width: "400px",
         })
         this.displayTemplateModel("save","templateHeader", 0, 0, 0,'', 0, '', 0, '', 0, '');  
      } 
  }

  createTemplateSection(){
    const positionByIndex = parseInt(this.templateSectionLinePosition.nativeElement.value) - 1
    let newDataToPost = null
    if(this.saveMode == 'save'){
      const data = {
        displayName: this.templateSectionLineDescription.nativeElement.value.trim(),
        lineType: "Section",
        subSectionLines: [],
        leftLabel: this.leftLabelElement.nativeElement.value.trim() ,
        rightLabel: this.rightLabelElement.nativeElement.value.trim() ,
      }
      this.tempTemplate = this.template
      if(typeof this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[positionByIndex] != 'undefined'){
        this.template.columnLines[this.currentColumnSelectedIndex].sectionLines = [
          ...this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines.slice(0, positionByIndex),
          data,
          ...this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines.slice(positionByIndex)
      ];
      // insert using afterId and beforeId
      newDataToPost = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[positionByIndex]
      const systemId = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[positionByIndex + 1].systemId
      newDataToPost.beforeId = systemId
      }else {
        this.template.columnLines[this.currentColumnSelectedIndex].sectionLines.push(data)
        // insert using afterId and beforeId
        newDataToPost = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.template.columnLines[this.currentColumnSelectedIndex].sectionLines.length - 1]
      }
      if(this.editExistantTemplate && !this.duplicateMode){
        // Loading indicator with a message, and the new options
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff'
        });
        const concatenatedUrl = "/columnLines("+ this.template.columnLines[this.currentColumnSelectedIndex].systemId +")/sectionLines"
        this.templateMakerService.createElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.currentTemplateSystemId, JSON.stringify(newDataToPost), concatenatedUrl).subscribe({
          next: (resData) => {
            this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.template.columnLines[this.currentColumnSelectedIndex].sectionLines.length - 1] = resData
            if(typeof this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.template.columnLines[this.currentColumnSelectedIndex].sectionLines.length - 1].subSectionLines == 'undefined'){
              this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.template.columnLines[this.currentColumnSelectedIndex].sectionLines.length - 1].subSectionLines = []
            }
            Notiflix.Loading.remove();
          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
             }) 
          }
        }) 
      }
    }
    else{
      this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].displayName = this.templateSectionLineDescription.nativeElement.value.trim()
      this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].leftLabel = this.leftLabelElement.nativeElement.value.trim()
      this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].rightLabel = this.rightLabelElement.nativeElement.value.trim()
      this.tempTemplate = this.template
      const oldData = this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex]
      this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines.splice(this.currentSectionSelectedIndex, 1)
      this.template.columnLines[this.currentColumnSelectedIndex].sectionLines = [
        ...this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines.slice(0, positionByIndex),
        oldData,
        ...this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines.slice(positionByIndex)
    ];
    if(this.editExistantTemplate && !this.duplicateMode){
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff'
      });
      const data = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex]
      const concatenatedUrl = "/columnLines("+ this.template.columnLines[this.currentColumnSelectedIndex].systemId +")/sectionLines("+ data.systemId +")"   
      const save_systemId = data.systemId
      delete data.systemId     
      this.templateMakerService.editElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.currentTemplateSystemId, JSON.stringify(data), concatenatedUrl).subscribe({
        next: (resData) => {
          data.systemId = save_systemId
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Section modifiée avec succès", {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
           })
        },
        error: (error) => {
          data.systemId = save_systemId
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
           })
        }
      })
    }      
    }
    setTimeout(() => {
      this.templateSectionLineDescription.nativeElement.value = ''
      this.leftLabelElement.nativeElement.value = ''
      this.rightLabelElement.nativeElement.value = ''     
    }, 800)
  }  

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    currentUrl = currentUrl.replace('%20', ' ');
    location.reload()
  }
  

  createTemplateSubsection(){

    const positionByIndex = parseInt(this.templateSubSectionLinePosition.nativeElement.value) - 1

    let newEntryWasUndefined = false

    let newDataToPost = null

    if(this.saveMode == 'save'){

      const data = {
        displayName: this.templateSubsctionLineDescription.nativeElement.value.trim(),
        taskLines: [],
        position: this.templateSubSectionLineAlignement.nativeElement.value
  
       }

       this.tempTemplate = this.template

       if(typeof this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[positionByIndex] != 'undefined'){

        newEntryWasUndefined = false

        this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines = [
          ...this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines.slice(0, positionByIndex),
          data,
          ...this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines.slice(positionByIndex)
      ];

        // insert using afterId and beforeId
        newDataToPost = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[positionByIndex]

        const systemId = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[positionByIndex + 1].systemId
  
        newDataToPost.beforeId = systemId


      }else {

        newEntryWasUndefined = true
        this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines.push(data)

        // insert using afterId and beforeId
        newDataToPost = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines.length - 1]

      }

    if(this.editExistantTemplate && !this.duplicateMode){


      // Loading indicator with a message, and the new options
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff'
      });

      const concatenatedUrl = "/columnLines("+ this.template.columnLines[this.currentColumnSelectedIndex].systemId +")/sectionLines(" + this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].systemId + ")/subSectionLines"
      this.templateMakerService.createElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.currentTemplateSystemId, JSON.stringify(newDataToPost), concatenatedUrl).subscribe({
        next: (resData) => {

            if(newEntryWasUndefined){
              this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines.length - 1] = resData
            }else{
              this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[positionByIndex] = resData
            }
            
  
          
          if(typeof this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines.length - 1].taskLines == 'undefined'){
            this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines.length - 1].taskLines = []
          }
          Notiflix.Loading.remove();

          // this.reloadCurrentRoute()

        },
        error: (error) => {

          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
           })

        }
      })


    }

    }
    else{

      this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].displayName = this.templateSubsctionLineDescription.nativeElement.value.trim()

      this.tempTemplate = this.template

      const oldData = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex]


      this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines.splice(this.currentSubSectionSelectedIndex, 1)

      
      this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines = [
        ...this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines.slice(0, positionByIndex),
        oldData,
        ...this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines.slice(positionByIndex)
    ];

    if(this.editExistantTemplate && !this.duplicateMode){

      // Loading indicator with a message, and the new options
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff'
      });

      const data = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex]

      const concatenatedUrl = "/columnLines("+ this.template.columnLines[this.currentColumnSelectedIndex].systemId +")/sectionLines(" + this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].systemId +")/subSectionLines(" + this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].systemId +")"
      this.templateMakerService.editElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.currentTemplateSystemId, JSON.stringify(data), concatenatedUrl).subscribe({
        next: (resData) => {
          Notiflix.Loading.remove();

          // this.reloadCurrentRoute()

        },
        error: (error) => {

          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
           })

        }
      })

    }


    }

    setTimeout(() => {
      this.templateSubsctionLineDescription.nativeElement.value = ''
    }, 800)


  }  

  taskCategoryError(){
    Notiflix.Notify.failure("Une tâche contient déjà le même Task category. Veuillez sélectionner un autre Task category", {
      timeout: 3000,
      showOnlyTheLastOne: true, 
      width: "400px",
     })
  }

  continueCreateTemplateTask(){
    const taskCategoryCode = this.taskCategoryElement.nativeElement.value.trim() 
    if(this.saveMode == 'save'){
      let directPush = false
      let specificPositionByIndex = 0
      let newDataToPost = null
      const taskCategoryArray = this.taskCategories.filter(taskCategory => taskCategory.code == taskCategoryCode);
      const taskCategory = taskCategoryArray[0].description
      const positionByIndex = parseInt(this.templateTaskLinePosition.nativeElement.value) - 1
      const data = {
        codeTaskCategory: taskCategoryCode,
        descriptionTaskCategory: taskCategory,
        displayName: this.taskDescription.nativeElement.value.trim(),
        lineType: "Task",
        fieldType: this.fieldTypeElement.nativeElement.value.trim(),
        fieldMINValue: this.fieldMinElement.nativeElement.value.trim(),
        fieldMAXValue: this.fieldMaxElement.nativeElement.value.trim(),
        defaultTask: this.defaultTaskElement.nativeElement.value,
        position: this.selectTaskPosition,
        code: this.communService.generateRandomCode()
    }
      const foundArrayData = this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.filter(taskLine => taskLine.position == this.taskPositionSelected)
      if(foundArrayData.length > 0 ){
        if(typeof foundArrayData[positionByIndex]  != 'undefined'){
          const taskcode = foundArrayData[positionByIndex].code
          specificPositionByIndex = this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.findIndex(taskLine => taskLine.code == taskcode)
          this.tempTemplate = this.template
          if(typeof this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[positionByIndex] != 'undefined'){
            this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines = [
              ...this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.slice(0, specificPositionByIndex),
              data,
              ...this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.slice(specificPositionByIndex)
          ];
          // insert using afterId and beforeId
          newDataToPost = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[specificPositionByIndex]
          const systemId = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[specificPositionByIndex + 1].systemId
          newDataToPost.beforeId = systemId  
          }else {
            this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.push(data)   
            // insert using afterId and beforeId
            newDataToPost = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.length - 1] 
            directPush = true
          }
        }else{
          this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.push(data)
        // insert using afterId and beforeId
        newDataToPost = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.length - 1]   
        directPush = true
      } 
      }else{
        this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.push(data)
      // insert using afterId and beforeId
      newDataToPost = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.length - 1]
      directPush = true
      }
    if(this.editExistantTemplate && !this.duplicateMode){
      // Loading indicator with a message, and the new options
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff'
      });
      const save_code = newDataToPost.code
      const save_descriptionTaskCategory = newDataToPost.descriptionTaskCategory
      const save_systemId = newDataToPost.systemId
      const save_parentId = newDataToPost.parentId
      const save_showingOrder = newDataToPost.showingOrder
      delete newDataToPost.code
      delete newDataToPost.descriptionTaskCategory
      delete newDataToPost.systemId
      delete newDataToPost.parentId
      delete newDataToPost.showingOrder
      const concatenatedUrl = "/columnLines("+ this.template.columnLines[this.currentColumnSelectedIndex].systemId +")/sectionLines(" + this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].systemId + ")/subSectionLines(" + this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].systemId+ ")/taskLines"
      this.templateMakerService.createElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.currentTemplateSystemId, JSON.stringify(newDataToPost), concatenatedUrl).subscribe({
        next: (resData) => {
          this.taskLineFieldMINValue = '0'
          this.taskLineFieldMAXValue = '0'
          this.taskLineCodeTaskCategoryEdit = ''
          this.taskLineFieldType = 'Selection'
          this.taskLineDefaultTask = 'false'
          this.taskLineDescriptionEdit = ''
          this.taskDescription.nativeElement.value = ''
          this.taskCategoryElement.nativeElement.value = ''
          if(directPush){
            this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.length - 1] = resData
            this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.length - 1].code = this.communService.generateRandomCode()
          }else{
            this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[specificPositionByIndex] = resData
            this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[specificPositionByIndex].code = this.communService.generateRandomCode()
          }
          Notiflix.Loading.remove();
        },
        error: (error) => {
          newDataToPost.code = save_code
          newDataToPost.descriptionTaskCategory = save_descriptionTaskCategory
          newDataToPost.systemId = save_systemId
          newDataToPost.parentId = save_parentId
          newDataToPost.showingOrder = save_showingOrder

          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
           })
        }
      })
    }
  }else{
      this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].displayName = this.taskDescription.nativeElement.value.trim()
      this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].fieldType = this.fieldTypeElement.nativeElement.value.trim()
      this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].fieldMINValue = this.fieldMinElement.nativeElement.value.trim()
      this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].fieldMAXValue = this.fieldMaxElement.nativeElement.value.trim()
      const taskCategoryArray = this.taskCategories.filter(taskCategory => taskCategory.code == taskCategoryCode);
      const taskCategory = taskCategoryArray[0].description
      if(this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].codeTaskCategory == taskCategoryCode){
        delete this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].codeTaskCategory
        delete this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].descriptionTaskCategory
      }else{
        this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].codeTaskCategory = taskCategoryCode
        this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].descriptionTaskCategory = taskCategory
      }
      const foundArrayData = this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.filter(taskLine => taskLine.position == this.taskPositionSelected)
      const positionByIndex = parseInt(this.templateTaskLinePosition.nativeElement.value) - 1
      if(typeof foundArrayData[positionByIndex]  != 'undefined'){
        const taskcode = foundArrayData[positionByIndex].code
        const specificPositionByIndex = this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.findIndex(taskLine => taskLine.code == taskcode)
        const oldData = this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex]      
        this.tempTemplate.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.splice(this.currentTaskSelectedIndex, 1)
        this.tempTemplate = this.template
        this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines = [
          ...this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.slice(0, specificPositionByIndex),
          oldData,
          ...this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines.slice(specificPositionByIndex)
      ];
      } 
      if(this.editExistantTemplate && !this.duplicateMode){
        // Loading indicator with a message, and the new options
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff'
        });
        const data = this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex]
        const concatenatedUrl = "/columnLines("+ this.template.columnLines[this.currentColumnSelectedIndex].systemId +")/sectionLines(" + this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].systemId +")/subSectionLines(" + this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].systemId +")/taskLines(" + this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].systemId +")"
        const save_code = data.code
        const save_showingOrder = data.showingOrder
        const save_systemId = data.systemId
        const save_parentId = data.parentId
        const save_descriptionTaskCategory = data.descriptionTaskCategory
        data.defaultTask = this.defaultTaskElement.nativeElement.value
        delete data.code
        delete data.showingOrder
        delete data.systemId
        delete data.parentId
        delete data.descriptionTaskCategory
        this.templateMakerService.editElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.currentTemplateSystemId, JSON.stringify(data), concatenatedUrl).subscribe({
          next: (resData) => {
            this.taskLineFieldMINValue = '0'
            this.taskLineFieldMAXValue = '0'
            this.taskLineCodeTaskCategoryEdit = ''
            this.taskLineFieldType = 'Selection'
            this.taskLineDefaultTask = 'false'
            this.taskLineDescriptionEdit = ''
            this.taskDescription.nativeElement.value = ''
            this.taskCategoryElement.nativeElement.value = ''
            this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex] = resData
            Notiflix.Loading.remove();  
          },
          error: (error) => {
            this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].codeTaskCategory = taskCategoryCode
            this.template.columnLines[this.currentColumnSelectedIndex].sectionLines[this.currentSectionSelectedIndex].subSectionLines[this.currentSubSectionSelectedIndex].taskLines[this.currentTaskSelectedIndex].descriptionTaskCategory = taskCategory      
            data.code = save_code
            data.showingOrder = save_showingOrder
            data.systemId = save_systemId
            data.parentId = save_parentId
            data.descriptionTaskCategory = save_descriptionTaskCategory
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Une erreur s'est produite pendant le traitement. Veuillez réessayer", {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
             })
          }
        })
      }
    }
  }

  createTemplateTask(){

    if(this.saveMode == 'save'){

      const taskCategoryCode = this.taskCategoryElement.nativeElement.value.trim()

      for(let i = 0; i < this.template.columnLines.length; i++){
  
        for(let j = 0; j < this.template.columnLines[i].sectionLines.length; j++){
  
          for(let k = 0; k < this.template.columnLines[i].sectionLines[j].subSectionLines.length; k++){
  
            for(let l = 0; l < this.template.columnLines[i].sectionLines[j].subSectionLines[k].taskLines.length; l++){
  
              if(this.template.columnLines[i].sectionLines[j].subSectionLines[k].taskLines[l].codeTaskCategory == taskCategoryCode){
                  
                this.taskCategoryError()
                this.sameTaskCategoryError = true
                break;
              }
  
  
  
            }
        
          }
        
        }
  
      }
  
      setTimeout(() => {
  
        if(!this.sameTaskCategoryError){

          if(this.taskLineFieldType !== 'Selection' && parseInt(this.fieldMaxElement.nativeElement.value)  <= parseInt(this.fieldMinElement.nativeElement.value)){
            Notiflix.Notify.failure("Champ max doit être supérieur au champ min", {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
             })

             var myModal = new bootstrap.Modal(document.getElementById('templateModal'), {});
             myModal.toggle();  
          }else{
            this.continueCreateTemplateTask()
          }
    
        }else{
          this.sameTaskCategoryError = false
        }
      }, 200)

    }else{
      if(this.taskLineFieldType !== 'Selection' && parseInt(this.fieldMaxElement.nativeElement.value)  <= parseInt(this.fieldMinElement.nativeElement.value)){
        Notiflix.Notify.failure("Champ max doit être supérieur au champ min", {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
         })

         var myModal = new bootstrap.Modal(document.getElementById('templateModal'), {});
         myModal.toggle();  
      }else{
        this.continueCreateTemplateTask()
      }
    }
 
  } 


  cancelTemplateCreate(){

    history.back()

    if(window.location.href.includes('?mode=duplicate')){
      window.close();
    }
    
  }

  onSaveTemplateClick(action){
    if(action = 'new-template'){
      // Loading indicator with a message, and the new options
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff'
      });  
      const tt_template = {...this.template}
      delete this.template.systemId
      this.template.columnLines.forEach(columnLine => {
        delete columnLine.showingOrder
        delete columnLine.systemId
        columnLine.sectionLines.forEach(sectionLine => {
         delete sectionLine.showingOrder
          delete sectionLine.systemId
          sectionLine.subSectionLines.forEach(subSectionLine => {
            delete subSectionLine.showingOrder
            delete subSectionLine.systemId
            delete subSectionLine.parentId
            subSectionLine.taskLines.forEach(taskLine => {
              delete taskLine.code
              delete taskLine.showingOrder
              delete taskLine.systemId
              delete taskLine.parentId
              delete taskLine.descriptionTaskCategory            
            });
          });         
        });     
      });
      
      setTimeout(()=> {
        this.templateMakerService.newTemplate(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, JSON.stringify(this.template)).subscribe({
          next: (data) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Template créé avec succès", {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
             })
             if(this.duplicateMode){
              window.close()
              this.storageService.setItem('duplcatedTemplate', 'true')
             }else{
              history.back()
             }        
          },
          error: (error) => {
            this.tempTemplate = tt_template
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
             })
          }
        })
      }, 4000)
    }
  }
}
