<div class="container-scroller">
  <app-nav-bar [BIXI_userAuthObject]="bixiUserAuthObject" [currentProfile]="currentProfile"
    [currentUserCompany]="currentUserCompany" (changeProfileEvent)="changeProfile($event)" [equipementNo]="equipementNo"
    [pageName]="pageName" [events]="closeOpenWorkorderpanelEvent.asObservable()" (logoutEvent)="logout()"
    [isUserBlocked]="isUserBlocked"></app-nav-bar>
  <div class="container-fluid page-body-wrapper add-padding">
    <app-side-bar [currentProfile]="currentProfile" [currentUserCompany]="currentUserCompany" [pageName]="pageName"
      (onpenEquipementAssocieEvent)="onpenEquipementAssocieModal()" [equipementNo]="equipementNo"></app-side-bar>
    <div class="main-panel" style="margin-left: 70px">
      <div class="content-wrapper p-3 content-container">
        <!-- Header -->
        <app-header *ngIf="equipement" [BIXI_userAuthObject]="bixiUserAuthObject"
          [currentUserCompany]="currentUserCompany" [equipement]="equipement" [pageName]="pageName"
          (refreshEquipementEvent)="refreshEquipementEvent()"></app-header>
        <div class="accordion mt-2 accordion-solid-header" id="accordionExample" role="tablist">
          <div class="card border">
            <div class="accordion-item card-header">
              <h2 class="accordion-header m-0" id="headingOne">
                <a class="accordion-button py-2" type="button" data-bs-target="#collapseOne" aria-expanded="true"
                  aria-controls="collapseOne">
                  <i class="fas fa-list-alt mr-2"></i>Liste des pièces
                </a>
              </h2>
            </div>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
              data-bs-parent="#accordionExample">
              <div class="accordion-body py-1 px-2">
                <div class="card cardWorkOrdersGrid">
                  <div>
                    <button class="btn btn-light m-2 ml-1 float-left p-1 border"
                      [disabled]="disableButton || isUserBlocked" (click)="transfertOrder()"
                      *ngIf="bikePartFeatures.createTransferOrder">
                      <small class="fw-bold mr-2 ml-1"><i class="fas fa-file-export mr-1"></i> Créer OT
                      </small>
                    </button>
                    <button class="btn btn-light m-2 ml-1 float-left p-1 border"
                      [disabled]="disableButton || isUserBlocked" (click)="returnParts()"
                      *ngIf="bikePartFeatures.createReturnPart">
                      <small class="fw-bold mr-2 ml-1"><i class="fas fa-file-import mr-1"></i> Créer RP
                      </small>
                    </button>
                    <button class="btn btn-light m-2 ml-1 float-left p-1 border"
                      [disabled]="disableButton || isUserBlocked" data-bs-toggle="modal"
                      data-bs-target="#validateOrderTransferModal" (click)="validateTransfertOrder(true)"
                      *ngIf="bikePartFeatures.validateTransferOrder">
                      <small class="fw-bold mr-2 ml-1"><i class="fas fa-check-circle mr-1"></i> Valider OT
                      </small>
                    </button>
                    <button class="btn btn-light m-2 ml-1 float-left p-1 border"
                      [disabled]="disableButton || isUserBlocked" data-bs-toggle="modal"
                      data-bs-target="#validateOrderTransferModal" (click)="validateTransfertOrder(false)"
                      *ngIf="bikePartFeatures.validateReturnPart">
                      <small class="fw-bold mr-2 ml-1"><i class="fas fa-check-square mr-1"></i> Valider RP
                      </small>
                    </button>
                    <button class="btn btn-light m-2 ml-1 float-left p-1 border" (click)="expandCollapse()">
                      <small class="fw-bold mr-2 ml-1"><i class="fas {{ expandCollapseClass }} mr-1"></i>
                        Substituts
                      </small>
                    </button>


                    <div [ngStyle]="{ display: isBarCodeVisible }" class="float-right border mb-2" style="opacity: 0">
                      <div class="row">
                        <ejs-barcodegenerator style="display: block" #barcode id="barcode" width="200px" height="80px"
                          mode="SVG" type="Code128" value=""></ejs-barcodegenerator>
                      </div>
                    </div>
                  </div>

                  <ejs-grid #bikePartsGrid [dataSource]="bikePartsData" [childGrid]="childGrid" gridLines="Horizontal"
                    showColumnChooser="false" [allowSelection]="bikePartFeatures.allowPartsSelection"
                    [allowTextWrap]="true" [allowMultiSorting]="true" (rowSelected)="rowSelected()"
                    (rowDeselected)="rowSelected()" (rowDataBound)="rowDataBoundBikePartsGrid($event)"
                    gridLines='Both_'>
                    <e-columns>
                      <e-column type="checkbox" width="40" *ngIf="bikePartFeatures.allowPartsSelection"></e-column>
                      <e-column field="lineNo" headerText="N° Ligne" width="80" textAlign="Left"
                        [visible]="true"></e-column>
                      <e-column field="descriptionCategory" headerText="Opération" width="120" textAlign="Left">
                        <ng-template let-data #template>
                          <a class="link link-dark fw-bold" href="javascript: void(0)" (click)="openDetailModal(data)">
                            {{ data?.descriptionCategory }}
                          </a>
                        </ng-template>
                      </e-column>
                      <e-column headerText="Quantité" width="80" textAlign="Right" headerTextAlign="Right">
                        <ng-template let-data #template>
                          <span class="rounded-pill px-2 py-1" [ngClass]="{
                              'badge bg-warning':
                                !data.transferOrderNo &&
                                ((data.quantity > data.inventoryCyclo &&
                                  data.quantity <= data.inventoryBixi) ||
                                  (data.quantity > data.inventoryBixi &&
                                    data.quantity <= data.inventoryCyclo)),
                              'badge bg-danger':
                                !data.transferOrderNo &&
                                (data.inventoryCyclo < data.quantity) &&
                                (data.inventoryBixi < data.quantity) || data.quantity == 0,
                              'badge bg-success':
                                !data.transferOrderNo && data.quantity > 0 &&
                                data.quantity <= data.inventoryCyclo &&
                                data.quantity <= data.inventoryBixi
                            }">
                            {{ data.quantity }}
                          </span>
                        </ng-template>
                      </e-column>
                      <e-column field="inventoryCyclo" headerText="Quantité Magasin" width="80" textAlign="Right"
                        headerTextAlign="Right"></e-column>
                      <e-column field="inventoryBixi" headerText="Inventaire Bixi" width="80" textAlign="Right"
                        headerTextAlign="Right"></e-column>
                      <e-column field="no" headerText="Code de pièce d'inventaire" width="150"
                        textAlign="Left"></e-column>
                      <e-column field="variantCode" headerText="Variant" width="160" textAlign="Left">
                        <ng-template let-data #template>
                          <span *ngIf="data?.variantCode && !data?.eraVariantExiste">{{data?.variantCode}}</span>
                          <span *ngIf="data?.eraVariantExiste">
                            <div class="row-fluid py-3" style="margin-top:-3px">
                              <div>
                                <span *ngIf="data?.variantCode" class="mt-3">{{data?.variantCode}}</span>
                                <span class="requiredStar fas fa-star text-danger text-small"
                                  *ngIf="!data?.variantCode"></span>
                                <select class="form-select form-select-sm input-block-level variantSelector"
                                  *ngIf="!data?.transferOrderNo" (change)="updateItemVariant($event, data)"
                                  style="margin-top:-5px">
                                  <option [value]=""></option>
                                  <option *ngFor="let itemVariant of data?.eraVariantsExisteArray"
                                    [value]="itemVariant.code">
                                    {{ itemVariant.description }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </span>
                        </ng-template>
                      </e-column>
                      <e-column field="description" headerText="Pièce d'inventaire" width="140"
                        textAlign="Left"></e-column>
                      <e-column headerText="N° Transfert" width="100" textAlign="Left">
                        <ng-template let-data #template>
                          <a class="link link-dark fw-bold" href="javascript: void(0)"
                            (click)="generateCodeBar(data?.transferOrderNo)">
                            {{ data?.transferOrderNo }}
                          </a>
                        </ng-template>
                      </e-column>
                      <e-column field="transferOrderLineNo" headerText="N° Ligne OT" width="100" textAlign="Left">
                      </e-column>
                      <e-column field="eraPostedTransfer" headerText="N° Validation OT" width="110"
                        textAlign="Left"></e-column>
                      <e-column field="eraReturnOrderNo" headerText="N° RP" width="100" textAlign="Left"></e-column>
                      <e-column field="eraReturnOrderLineNo" headerText="N° Ligne RP" width="100"
                        textAlign="Left"></e-column>
                      <e-column field="eraPostedReturn" headerText="N° Validation RP" width="110"
                        textAlign="Left"></e-column>
                    </e-columns>
                  </ejs-grid>
                  <ng-template #childtemplate let-data>
                    <a rel="nofollow" style="cursor: pointer" class="text-info" (click)="
                        replaceSubstitut(
                          data.parentSystemId,
                          data.no,
                          data.substituteNo
                        )
                      ">{{ data.substituteNo }}</a>
                  </ng-template>
                  <ng-template #quantitytemplate let-data>
                    <span class="rounded-pill px-2 py-1" [ngClass]="{
                        'badge bg-warning':
                          (data.inventory > data.inventoryCyclo &&
                            data.inventory <= data.inventoryBixi) ||
                          (data.inventory > data.inventoryBixi &&
                            data.inventory <= data.inventoryCyclo),
                        'badge bg-danger':
                          (data.inventoryCyclo < data.inventory) &&
                          (data.inventoryBixi < data.inventory) || data.inventory == 0,
                        'badge bg-success':
                          data.inventory <= data.inventoryCyclo && data.inventory > 0 &&
                          data.inventory <= data.inventoryBixi
                      }">
                      {{ data.inventory }}
                    </span>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <app-linked-asset *ngIf="equipement" [formTitle]="'Bike Parts'" [equipement]="equipement"
  [isUserBlocked]="isUserBlocked"></app-linked-asset> -->
<app-linked-asset *ngIf="equipement" [formTitle]="'Bike Parts'"
  (confirmeCreateWoEquipEvent)="createNewWorkOrder($event)" [equipement]="equipement"
  [events]="refreshAssetLinkEvent.asObservable()" [isUserBlocked]="isUserBlocked"></app-linked-asset>

<app-consumption-track [consumptionTrackData]="consumptionTrackData"></app-consumption-track>

<app-detail-tache-modal (refreshGetEquipementEvent)="ngOnInit()" [BIXI_userAuthObject]="BIXI_userAuthObject"
  [currentProfile]="currentProfile" [currentProfiles]="currentProfiles" [currentUserCompany]="currentUserCompany"
  [BTandLines]="BTandLines" [openDetailTacheModalEvent]="openDetailTacheModalEvent" [sameTasks]="sameTasks"
  [currentWorkorder]="currentWorkorder" [itemsList]="itemsList" [expensesList]="expensesList"
  [allItemVariants]="allItemVariants" [editWorkOrderFeatures]="editWorkOrderFeatures"
  [onSelectedAllFinishedClickEvent]="onSelectedAllFinishedClickEvent"
  [onReopenGeneralClickEvent]="onReopenGeneralClickEvent"
  [onSelectedAllValidateClickEvent]="onSelectedAllValidateClickEvent" [unitsOfMeasureList]="unitsOfMeasureList"
  [locationsList]="locationsList" [bikePartsData]="bikePartsData"
  [isUserBlocked]="isUserBlocked"></app-detail-tache-modal>

<div class="modal fade" id="validateOrderTransferModal" tabindex="-1" aria-labelledby="validateOrderTransferModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-customized-title" id="validateOrderTransferModalLabel">
          <i class="fa fa-check-square mr-2"></i>
          <span class="fw-bold">{{ modalValidationTitle }}</span>
        </h6>
        <button type="button" id="closeWorkorderStatusModal" class="btn btn-danger btn-sm my-1" data-bs-dismiss="modal"
          aria-label="Close"><i class="fa fa-times"></i></button>
      </div>
      <div class="modal-body">
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th scope="col" class="fw-bold">Code pièce</th>
              <th scope="col" class="fw-bold">N° OT</th>
              <th scope="col" class="fw-bold">N° Ligne OT</th>
              <th scope="col" class="fw-bold">N° RP</th>
              <th scope="col" class="fw-bold">N° Ligne RP</th>
              <th scope="col" class="fw-bold">Qté à consommer</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let line of validateSelectedRecords">
              <td>{{ line.lineNo }}</td>
              <td>{{ line.no }}</td>
              <td>{{ line.transferOrderNo }}</td>
              <td>{{ line.transferOrderLineNo }}</td>
              <td>{{ line.eraReturnOrderNo }}</td>
              <td>{{ line.eraReturnOrderLineNo }}</td>
              <td>{{ line.qtyToConsume }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-success btn-sm" (click)="validate()">
          <i class="fa fa-check mr-2"></i>Valider
        </button>
        <button type="button" class="btn btn-light btn-sm" data-bs-dismiss="modal" id="btnModalCloseBtn"
          aria-label="Close">
          <i class="fa fa-times mr-2"></i>Annuler
        </button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="onCodeBarOTClickModal" tabindex="-1" aria-labelledby="onCodeBarOTClickModal"
  aria-hidden="true" data-bs-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light px-3">
        <h6 class="modal-title text-primary modal-h6-title-container" id="onCodeBarOTClickModalTitle">
          <span class="modal-title-style fw-bold"><i class="fas fa-barcode mr-2"></i>Code barre</span>
        </h6>
        <button type="button" class="btn btn-danger btn-sm my-1" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body pt-3 pb-3">
        <img [src]="codeBarImgBase64" width="300px" class="img-responsive" />
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="associatedEquipTemplateModal" tabindex="-1" aria-labelledby="associatedEquipTemplateLabel"
  aria-hidden="true">
  <div *ngIf="roueWorkorder" class="modal-dialog modal-lg"
    [ngStyle]="{ width: roueWorkorder.isBtRoue ? '40%' : '70%' }">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 *ngIf="roueWorkorder !== null" class="modal-title text-primary modal-customized-title"
          id="associatedEquipTemplateLabel">
          <i class="fa fa-cog mr-2"></i>
          <span class="fw-bold">Bon de travail N° <span class="text-danger">{{ roueWorkorder.no }}</span></span>
        </h6>
        <button type="button" class="btn btn-danger btn-sm my-1" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body pt-2 pb-2">
        <div *ngIf="templateRoue && !roueWorkorder.isBtRoue" class="row">
          <div *ngFor="let columnLine of templateRoue.columnLines; let i = index" class="col-md-4">
            <div *ngFor="let sectionLine of columnLine.sectionLines; let j = index"
              class="card mt-2 rounded-0 borderTemplateTable">
              <div class="card-header pb-0 pt-2 bg-light rounded-0">
                <h5 class="sectionLineDisplayName">
                  <!-- <i class="fas fa-table"></i> -->
                  {{ sectionLine.displayName }}
                </h5>
              </div>
              <div class="card-body pt-0 pb-0 pl-2 pr-2" style="padding: 0px !important">
                <div class="table-responsive">
                  <table class="table templateTable">
                    <thead>
                      <tr class="text-center">
                        <th style="width: 40%"></th>
                        <th *ngIf="
                            !sectionLine.leftLabel && !sectionLine.rightLabel
                          "></th>
                        <th *ngIf="sectionLine.leftLabel">
                          {{ sectionLine.leftLabel }}
                        </th>
                        <th *ngIf="sectionLine.rightLabel">
                          {{ sectionLine.rightLabel }}
                        </th>
                      </tr>

                      <!-- <tr class="text-center">
                <th style="width:30%"></th>
                <th>{{sectionLine.leftLabel}}</th>
                <th>{{sectionLine.rightLabel}}</th>
              </tr> -->
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let subSectionLine of sectionLine.subSectionLines;
                          let k = index
                        ">
                        <td class="font-weight-bold" style="padding-right: 0px; width: 50px">
                          <div class="ctc-1">
                            {{ subSectionLine.displayName }}
                          </div>
                        </td>
                        <td colspan="2" *ngIf="subSectionLine.position == 'Center'">
                          <div style="width: 100%" *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l1 = index
                            " class="btn-group d-flex">
                            <div style="width: 100%" class="btn-group d-flex mb-1"
                              *ngIf="taskLine.position == 'Center'">
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-large-width"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                i,
                                j,
                                k,
                                l1
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ i }}_{{ j }}_{{ k }}_{{
                                    l1
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l2 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Left'">
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-width-to-button"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                i,
                                j,
                                k,
                                l2
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ i }}_{{ j }}_{{ k }}_{{
                                    l2
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.rightLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.rightLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l3 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Right'">
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-width-to-button"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                i,
                                j,
                                k,
                                l3
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ i }}_{{ j }}_{{ k }}_{{
                                    l3
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="templateRoue && roueWorkorder.isBtRoue" class="row">
          <div class="col-md-12">
            <div *ngFor="
                let sectionLine of templateRoue.columnLines[templateSideIndex]
                  .sectionLines;
                let j = index
              " class="card mt-2 rounded-0 borderTemplateTable">
              <div class="card-header pb-0 pt-2 bg-light rounded-0">
                <h5 class="">
                  {{ sectionLine.displayName }}
                </h5>
              </div>
              <div class="card-body py-0 pl-2 pr-2">
                <div class="table-responsive">
                  <table class="table templateTable">
                    <thead>
                      <tr class="text-center">
                        <th style="width: 40%"></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let subSectionLine of sectionLine.subSectionLines;
                          let k = index
                        ">
                        <td class="font-weight-bold" style="padding-right: 0px; width: 50px">
                          <div class="ctc-1">
                            {{ subSectionLine.displayName }}
                          </div>
                        </td>
                        <td colspan="2" *ngIf="subSectionLine.position == 'Center'">
                          <div style="width: 100%" *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l1 = index
                            " class="btn-group d-flex">
                            <div style="width: 100%" class="btn-group d-flex mb-1"
                              *ngIf="taskLine.position == 'Center'">
                              <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                0,
                                j,
                                k,
                                l1
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ 0 }}_{{ j }}_{{ k }}_{{
                                    l1
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l2 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Left'">
                              <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                0,
                                j,
                                k,
                                l2
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ 0 }}_{{ j }}_{{ k }}_{{
                                    l2
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l3 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Right'">
                              <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                0,
                                j,
                                k,
                                l3
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ 0 }}_{{ j }}_{{ k }}_{{
                                    l3
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer px-0">
          <button type="button" (click)="onBtClose()" class="btn btn-primary btn-sm" data-bs-dismiss="modal">
            <i class="fa fa-check mr-2"></i>Continuer
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="mulitipeValueTaskLineModal" tabindex="-1" aria-labelledby="mulitipeValueTaskLineModal"
  aria-hidden="true" data-bs-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-customized-title" id="updateItemsModal">
          <b>Paramètre de tâche</b>
        </h6>
      </div>
      <div class="modal-body pt-2 pb-2">
        <div class="row mt-2">
          <div class="grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="forms-sample">
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Min</label>
                    <div class="col-sm-7">
                      <input *ngIf="EObjectToUpdate !== null" type="number" class="form-control form-control-sm py-1"
                        [value]="EObjectToUpdate.object.fieldMINValue" [disabled]="true" />
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Max</label>
                    <div class="col-sm-7">
                      <input *ngIf="EObjectToUpdate !== null" type="number" class="form-control form-control-sm py-1"
                        [value]="EObjectToUpdate.object.fieldMAXValue" [disabled]="true" />
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Multiplicateur</label>
                    <div class="col-sm-7">
                      <input type="number" name="multiplicateur" #multiplicateur="ngModel"
                        [(ngModel)]="taskMultipleValue" required
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        class="form-control form-control-sm py-1" autofocus="autofocus" #multiplicateurElement />
                      <div class="alert alert-danger input-error-style" *ngIf="multiplicateur.errors?.['required']">
                        Le multiplicateur est requis
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="">
          <button type="button" class="btn btn-primary mr-2" data-bs-dismiss="modal" (click)="addTaskByMultipleValue()"
            [disabled]="multiplicateur.errors?.['required']">
            Ajouter la tâche
          </button>
          <button class="btn btn-light" (click)="onMultipleTaskCancel()" data-bs-dismiss="modal">
            Annuler
          </button>
        </div>
      </div>
    </div>
  </div>
</div>