import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../core/shared/services/storage.service';
import { Profile } from '../core/shared/models/profile';
import { CommunService } from '../services/commun.service';
import { AccessService } from './access.service';
import { AccessGrantObject } from '../models/accessGrantObject.model';

@Injectable({
  providedIn: 'root',
})
export class AccessGuard implements CanActivate {
  currentProfile: Profile = null;

  routeConvertToPageName = {
    'auth/login': 'login',
    'auth/profile': 'profile',
    'home/:currentCompanyId/:profileId': 'home',
    'archiveworkorder/:currentCompanyId/:profileId/serial-number':
      'archiveworkorder',
    'archiveworkorder/:currentCompanyId/:profileId/serial-number/:equipementNo':
      'archiveworkorder',
    'validationworkorder/:currentCompanyId/:profileId/serial-number':
      'validationworkorder',
    'validationworkorder/:currentCompanyId/:profileId/serial-number/:equipementNo':
      'validationworkorder',
    'templates/:currentCompanyId/:profileId/serial-number': 'templates',
    'templates/:currentCompanyId/:profileId/serial-number/:equipementNo':
      'templates',
    'templatemaker/:currentCompanyId/:profileId/serial-number': 'templates',
    'templatemaker/:currentCompanyId/:profileId/serial-number/:equipementNo/template/:systemId':
      'templatemaker',
    'templatemaker/:currentCompanyId/:profileId/template/:systemId':
      'templatemaker',
    'openworkorder/:currentCompanyId/:profileId/serial-number': 'openworkorder',
    'detailworkorder/:currentCompanyId/:profileId/workOrder': 'detailworkorder',
    'openworkorder/:currentCompanyId/:profileId/serial-number/:equipementNo':
      'openworkorder',
    'bikepartslist/:currentCompanyId/:profileId/serial-number/:equipementNo':
      'bikepartslist',
    'detailworkorder/:currentCompanyId/:profileId/workOrder/:workOrderNo':
      'detailworkorder',
    'detailworkorder/:currentCompanyId/:profileId/workOrders/:workOrderNo':
      'detailworkorder',
  };

  accessGrantObject: AccessGrantObject = null;

  constructor(private storageService: StorageService,
    private communService: CommunService,
    private accessService: AccessService) {
     }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean{
    return new Observable<boolean>(obs => {
      if(this.storageService.getItem("bixi-currentProfile") !== ''){
        this.currentProfile = JSON.parse(this.storageService.getItem("bixi-currentProfile"));
        const userProfile = this.currentProfile.profil
        const pageUrl = route.routeConfig.path
        const pageName = this.routeConvertToPageName[pageUrl]
        this.accessService.getAccessPermissions().subscribe({
          next: (result) => {
            if(result?.value.length>=0){
              let accessGrantValue = result?.value[0]?.webPermissionsObject;
              this.accessGrantObject = accessGrantValue ? JSON.parse(accessGrantValue) : null;
            }

            if(this.accessGrantObject){
              const accessData = this.accessGrantObject[userProfile]
              this.communService.userAccess = accessData[pageName]
              //it's the read access that alows the user to go to the page
              if(accessData[pageName]?.read)
              {
                this.accessService.accessGrantObject = this.accessGrantObject 
                obs.next(true);
              }
              else{
                window.alert("Vous n'avez pas accès à cette page car vous êtes un "+ userProfile);
                obs.next(false);
              }
            }
            else{
                window.alert("Une erreur est survee lors de chargement des permissions web");
                obs.next(false);              
            }
          },
          error: (error) => {     
              window.alert("Vous n'avez pas accès à cette page car vous êtes un "+ userProfile);
              obs.next(false);          
          }
        });
      }
      else{
        obs.next(true);
      }
    })
  }
}
