import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication
} from "@azure/msal-browser";
import {MsalInterceptorConfiguration} from "@azure/msal-angular";
import {OAuthSettings} from "./creds";
import { AppConfigService } from "../app.config.service";


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
// const appClientId = AppConfigService.settings.appClientId;
// const authority = AppConfigService.settings.authority;
// const redirectUri = AppConfigService.settings.redirectUri;
// const scopesGrapheMs = AppConfigService.settings.scopesGrapheMs;
// const scopesBc = AppConfigService.settings.scopesBc;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: OAuthSettings.appClientId,
      authority: OAuthSettings.authority,
      redirectUri: OAuthSettings.redirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', OAuthSettings.scopesGrapheMs);
  protectedResourceMap.set('https://api.businesscentral.dynamics.com', OAuthSettings.scopesBc);
  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}
