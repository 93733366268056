import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/config/app.config.service';
import { Observable, catchError, map } from 'rxjs';
import { ErrorService } from 'src/app/core/shared/services/error.service';
import { Equipement } from 'src/app/core/shared/models/equipement';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

defaultCompanyId = AppConfigService.settings.defaultCompanyId;
bcCoreApiUrl = AppConfigService.settings.bcCoreApiUrl;
customApiUrlSegment = AppConfigService.settings.customApiUrlSegment;
bcEnvirName = AppConfigService.settings.bcEnvirName;
defaultLg = AppConfigService.settings.defaultLanguage;
schemaVersion = AppConfigService.settings.bcSchemaversion;

  constructor(private http: HttpClient,
    private errorService: ErrorService  ) { }

   // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language' : 'fr-CA'
    }),
  }; 

  scanner(tenantId: any, companyId: string, data: any): Observable<any>{  
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/assets?$schemaversion="+this.schemaVersion     
    return this.http.post(url, data, this.httpOptions).pipe(
      map(result  => result as Equipement),
      catchError(this.errorService.handleError));
  }

  getEquipement(tenantId: any, companyId: string, noEquipement: string): Observable<any>{
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/assets?$schemaversion="+this.schemaVersion+"&$filter=no eq '" + noEquipement +"'";     
    return this.http.get(url, this.httpOptions).pipe(
      map(result  => result as Equipement),
      catchError(this.errorService.handleError));
  }

  getStatus(tenantId: any, companyId: string, woNo: string): Observable<any>{
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/saveStatus?$schemaversion="+this.schemaVersion+"&$filter=no eq '" + woNo +"'";     
    return this.http.get(url, this.httpOptions).pipe(
      map(result  => result as Equipement),
      catchError(this.errorService.handleError));
  }

}
