import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/config/app.config.service';
import { Observable, catchError, map } from 'rxjs';
import { ErrorService } from 'src/app/core/shared/services/error.service';
import { Template } from 'src/app/core/shared/models/template/template.model';

@Injectable({
  providedIn: 'root'
})
export class TemplateMakerService {

  defaultCompanyId = AppConfigService.settings.defaultCompanyId;
  bcCoreApiUrl = AppConfigService.settings.bcCoreApiUrl;
  customApiUrlSegment = AppConfigService.settings.customApiUrlSegment;
  bcEnvirName = AppConfigService.settings.bcEnvirName;
  defaultLg = AppConfigService.settings.defaultLanguage;
  schemaVersion = AppConfigService.settings.bcSchemaversion;

    constructor(private http: HttpClient,
      private errorService: ErrorService  ) { }
  
     // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'If-Match': '*'
      }),
    }; 

  getTemplates(tenantId: any, companyId: string, $expand): Observable<any>{
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies("+companyId+")/templateHeaders?$schemaversion="+this.schemaVersion+"&$expand="+ $expand;     
    return this.http.get(url, this.httpOptions).pipe(
      map(result  => result as Template),
      catchError(this.errorService.handleError));
  }

  getTemplateByFilters(tenantId: any, companyId: string, $filter): Observable<any>{
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies("+companyId+")/templateHeaders?$schemaversion="+this.schemaVersion+"&$filter="+$filter;    
    return this.http.get(url, this.httpOptions).pipe(
      map(result  => result as Template),
      catchError(this.errorService.handleError));
  }

  getTemplateByTemplateSystemId(tenantId: any, companyId: string, $templateSystemId, $expand): Observable<any>{
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies("+companyId+")/templateHeaders("+ $templateSystemId +")?$schemaversion="+this.schemaVersion+"&$expand="+ $expand;    
    return this.http.get(url, this.httpOptions).pipe(
      map(result  => result as Template),
      catchError(this.errorService.handleError));
  }

  getAssetCategories(tenantId: any, companyId: string): Observable<any>{
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies("+companyId+")/assetCategories?$schemaversion="+this.schemaVersion;     
    return this.http.get(url, this.httpOptions).pipe(
      map(result  => result as Template),
      catchError(this.errorService.handleError));
  }

  getSectionLines (tenantId: any, companyId: string): Observable<any>{
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies("+companyId+")/sectionLines?$schemaversion="+this.schemaVersion;      
    return this.http.get(url, this.httpOptions).pipe(
      map(result  => result as Template),
      catchError(this.errorService.handleError));
  }

  getSubSectionLines (tenantId: any, companyId: string): Observable<any>{
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies("+companyId+")/subSectionLines?$schemaversion="+this.schemaVersion;      
    return this.http.get(url, this.httpOptions).pipe(
      map(result  => result as Template),
      catchError(this.errorService.handleError));
  }

  getPortailDefDisplayNames(tenantId: any, companyId: string){
    const url =  this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies("+companyId+")/portailDefDisplayNames?$schemaversion="+this.schemaVersion;  
    return this.http.get(url, this.httpOptions).pipe(
      map(result  => result as any),
      catchError(this.errorService.handleError));
  }

  getPortailDefDisplayNamesWithFilter(tenantId: any, companyId: string, filter: string){
    const url =  this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies("+companyId+")/portailDefDisplayNames?$schemaversion="+this.schemaVersion+"&$filter="+filter
    return this.http.get(url, this.httpOptions).pipe(
      map(result  => result as any),
      catchError(this.errorService.handleError));
  }

  newTemplate(tenantId: any, companyId: string, data): Observable<any>{
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies("+companyId+")/templateHeaders?$schemaversion="+this.schemaVersion;     
    return this.http.post(url, data, this.httpOptions).pipe(
      map(result  => result as Template),
      catchError(this.errorService.handleError));
  }
  
  editElement(tenantId: any, companyId: string, systemId: string, data, concatenatedUrl: string): Observable<any>{
    const schemaversion = "?$schemaversion="+this.schemaVersion
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies("+companyId+")/templateHeaders("+systemId+")"+concatenatedUrl+schemaversion;     
    return this.http.patch(url, data, this.httpOptions).pipe(
      map(result  => result as Template),
      catchError(this.errorService.handleError));
  }

  deteleTemplate(tenantId: any, companyId: string, systemId: string): Observable<any>{
    const schemaversion = "?$schemaversion="+this.schemaVersion
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies("+companyId+")/templateHeaders("+systemId+")"+schemaversion;     
    return this.http.delete(url, this.httpOptions).pipe(
      map(result  => result as Template),
      catchError(this.errorService.handleError));
  }

  createElement(tenantId: any, companyId: string, systemId: string, data, concatenatedUrl: string): Observable<any>{
    const schemaversion = "?$schemaversion="+this.schemaVersion
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies("+companyId+")/templateHeaders("+systemId+")"+concatenatedUrl+schemaversion;     
    return this.http.post(url, data, this.httpOptions).pipe(
      map(result  => result as Template),
      catchError(this.errorService.handleError));
  }

  deleteElement(tenantId: any, companyId: string, systemId: string, concatenatedUrl: string): Observable<any>{
    const schemaversion = "?$schemaversion="+this.schemaVersion
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies("+companyId+")/templateHeaders("+systemId+")"+concatenatedUrl+schemaversion;     
    return this.http.delete(url, this.httpOptions).pipe(
      map(result  => result as Template),
      catchError(this.errorService.handleError));
  }

  getWorkOrderCategories(tenantId: any, companyId: string): Observable<any>{
    const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies("+companyId+")/workOrderCategories?$schemaversion="+this.schemaVersion;     
    return this.http.get(url,  this.httpOptions).pipe(
      map(result  => result as Template),
      catchError(this.errorService.handleError));
  }
  

}
