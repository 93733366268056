import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { HomeService } from '../home/services/home.service';
import { OpenWorkorderService } from '../open-workorder/services/open-workorder.service';
import { WorkOrder } from 'src/app/core/shared/models/WorkOrder';
import { Profile } from 'src/app/core/shared/models/profile';
import { Company } from 'src/app/core/shared/models/company.model';
import { Equipement } from 'src/app/core/shared/models/equipement';
import Notiflix from 'notiflix';
import { TemplateMakerService } from '../template-maker/services/template-maker.service';
import { Template } from 'src/app/core/shared/models/template/template.model';
import { AssetCategory } from 'src/app/core/shared/models/AssetCategory';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { setCulture } from '@syncfusion/ej2-base';
import { Features } from 'src/app/models/accessGrantObject.model';
import { AccessService } from 'src/app/guard/access.service';

setCulture('fr-CA');

declare var bootstrap: any;

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
})
export class TemplatesComponent {
  @ViewChild('templateHeaderDescriptionElement2', { static: false })
  templateHeaderDescriptionElement2?: any;
  @ViewChild('templateHeaderAssetCategoryCodeElement', { static: false })
  templateHeaderAssetCategoryCodeElement?: any;
  pageName = 'templates';
  inProgressWorkOrdersData: WorkOrder[] = [];
  openWorkOrdersData: WorkOrder[] = [];
  userProfilTitle = '';
  BIXI_userAuthObject: any = null;
  currentProfile: Profile = null;
  currentProfiles: Profile[] = [];
  workOrderNo = '';
  currentUserCompany: Company | undefined;
  equipement: Equipement = null;
  taskColumnPosition = 0;
  taskSection = '';
  sectionLineDataSelectedIndex = 0;
  equipementNo: string = '';
  templatesLoaded = false;
  templates: Template[] = [];
  filtredTemplates: Template[] = [];
  public editSettings?: Object;
  public contextMenuItems: Object[] = [];
  headerTemplateMode = false;
  @ViewChild('templateHeaderAssetCategoryDescriptionElement', { static: false })
  templateHeaderAssetCategoryDescriptionElement?: any;
  assetCategories: AssetCategory[] = [];

  template: any = {
    code: '',
    description: '',
    codeAssetCategory: '',
    status: '',
    systemId: '',
    templateColumns: 0,
    columnLines: [],
  };
  templateFeatures: Features = null;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private homeService: HomeService,
    private openWorkOrderService: OpenWorkorderService,
    private templateMakerService: TemplateMakerService,
    private AuthService: AuthService,
    private accessService: AccessService
  ) {}

  ngOnInit() {
    Notiflix.Notify.init({
      position: 'right-bottom',
    });
    setInterval(() => {
      if (this.storageService.getItem('duplcatedTemplate') == 'true') {
        this.getTemplates();

        this.storageService.setItem('duplcatedTemplate', 'false');
      }
    }, 100);

    // check if user has data in browser storage, else redirect himn to login page
    if (
      !this.storageService.getItem('bixi-user-auth') ||
      !this.storageService.getItem('bixi-currentCompany') ||
      !this.storageService.getItem('bixi-currentProfile')
    ) {
      this.router.navigateByUrl('/auth/login');
    } else {
      this.BIXI_userAuthObject = JSON.parse(
        this.storageService.getItem('bixi-user-auth')
      );
      this.currentUserCompany = JSON.parse(
        this.storageService.getItem('bixi-currentCompany')
      );
      this.currentProfile = JSON.parse(
        this.storageService.getItem('bixi-currentProfile')
      );
      this.currentProfiles = JSON.parse(
        this.storageService.getItem('bixi-currentProfiles')
      );
      this.equipementNo = this.storageService.getItem('currentSerialNumber');
    }

    this.getEquipement();
    this.getTemplates();
    localStorage.setItem('bixiApp_isTemplateReadOnly', '0');
    this.templateMakerService
      .getAssetCategories(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id
      )
      .subscribe({
        next: (assetCategoryObject) => {
          this.assetCategories = assetCategoryObject.value;
        },
        error: (error) => {},
      });

    this.templateFeatures = this.accessService.getFeatureByProfile(
      this.currentProfile.profil
    );

    this.contextMenuItems = [];
    if (this.templateFeatures.editTemplate) {
      this.contextMenuItems.push({
        text: 'Edit template',
        id: 'edit',
        target: '.e-content',
        iconCss: 'e-icons e-edit',
        items: [
          {
            text: 'Entête',
            id: 'edit-header',
          },
          {
            text: 'Composants',
            id: 'edit-components',
          },
        ],
      });
    }

    if (this.templateFeatures.deleteTemplate) {
      this.contextMenuItems.push({
        text: 'Supprimer',
        target: '.e-content',
        id: 'delete',
        iconCss: 'e-icons e-trash',
      });
    }

    if (this.templateFeatures.duplicateTemplate) {
      this.contextMenuItems.push({
        text: 'Dupliquer',
        target: '.e-content',
        id: 'duplicate',
        iconCss: 'e-icons e-copy',
      });
    }
  }

  getTemplates() {
    // Loading indicator with a message, and the new options
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    this.templateMakerService
      .getTemplates(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        'columnLines'
      )
      .subscribe({
        next: (templates) => {
          this.templates = templates.value.sort((a, b) =>
            a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1
          );
          this.filtredTemplates = this.templates;
          this.templatesLoaded = true;

          Notiflix.Loading.remove();
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });
        },
      });
  }

  contextMenuclick(args) {
    this.template = args.rowInfo.rowData;
    const systemId = args.rowInfo.rowData.systemId;
    const contextMenuId = args.item.id;
    if (contextMenuId == 'edit-header') {
      this.headerTemplateMode = true;
      var myModal = new bootstrap.Modal(
        document.getElementById('_templateModal_2'),
        {}
      );
      myModal.toggle();

      setTimeout(() => {
        this.templateHeaderDescriptionElement2.nativeElement.focus();
      }, 700);
    } else if (contextMenuId == 'edit-components') {
      this.router.navigateByUrl(
        '/templatemaker/' +
          this.currentUserCompany.id +
          '/' +
          this.currentProfile.id +
          '/template/' +
          systemId
      );
    } else if (contextMenuId == 'delete') {
      this.onDeleteTemplateClick(systemId);
    } else if (contextMenuId == 'duplicate') {
      this.onDuplicateTemplateClick(systemId);
    }
  }

  onAssetCategoryChange(event) {
    this.template.codeAssetCategory = event.target.value;
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      const elem: any =
        document.getElementsByClassName('modal-backdrop')[
          document.getElementsByClassName('modal-backdrop').length - 1
        ];
      if (typeof elem !== 'undefined') {
        elem.classList.remove('show');
        elem.style.display = 'none';
      }
    }, 100);
  }

  saveTemplateHeader() {
    const found = this.templates.filter(
      (template) =>
        template.code == this.template.code &&
        template.systemId != this.template.systemId
    );
    if (found.length > 0) {
      Notiflix.Notify.failure(
        'Un template possède déjà le même code dans le système',
        {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
        }
      );
      var myModal = new bootstrap.Modal(
        document.getElementById('_templateModal_2'),
        {}
      );
      myModal.toggle();
    } else {
      const found_2 = this.templates.filter(
        (template) =>
          template.codeAssetCategory == this.template.codeAssetCategory &&
          template.systemId != this.template.systemId
      );
      if (found_2.length > 0) {
        Notiflix.Notify.failure(
          'Un template ' +
            this.assetCategories.filter(
              (assetCategory) =>
                assetCategory.code == this.template.codeAssetCategory
            )[0].description +
            ' existe déjà dans le système. Souhaiteriez-vous le modifier?',
          {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }
        );
      } else {
        if (this.template.columnLines.length <= this.template.templateColumns) {
          if (this.template.code.length <= 100) {
            // Loading indicator with a message, and the new options
            Notiflix.Loading.standard('Chargement...', {
              svgColor: '#ffffff',
            });
            const _data = { ...this.template };
            const currentTemplateSystemId = this.template.systemId;
            const concatenatedUrl = '';
            const save_systemId = _data.systemId;
            delete _data.systemId;
            this.templateMakerService
              .editElement(
                this.BIXI_userAuthObject.tenantId,
                this.currentUserCompany.id,
                currentTemplateSystemId,
                _data,
                concatenatedUrl
              )
              .subscribe({
                next: (template) => {
                  _data.systemId = save_systemId;
                  Notiflix.Notify.success(
                    'Entête de template modifié avec succès',
                    {
                      timeout: 3000,
                      showOnlyTheLastOne: true, 
                      width: "400px",
                    }
                  );
                  this.reloadCurrentRoute();
                },
                error: (error) => {
                  _data.systemId = save_systemId;
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure(error, {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                  });
                },
              });

            setTimeout(() => {
              this.templateHeaderDescriptionElement2.nativeElement.value = '';
            }, 800);
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(
              'Le code du template ne doit pas dépasser 100 caractères',
              {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              }
            );

            var myModal = new bootstrap.Modal(
              document.getElementById('_templateModal_2'),
              {}
            );
            myModal.toggle();
          }
        } else {
          Notiflix.Notify.failure(
            'Le nombre de colonnes doit être supérieur ou égal au nombre total de colonnes dans le template',
            {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            }
          );
          var myModal = new bootstrap.Modal(
            document.getElementById('_templateModal_2'),
            {}
          );
          myModal.toggle();
        }
      }
    }
  }

  cancelTemplateHeaderSave() {}

  onDuplicateTemplateClick(systemId) {
    let myWind = window.open(
      '/templatemaker/' +
        this.currentUserCompany.id +
        '/' +
        this.currentProfile.id +
        '/template/' +
        systemId +
        '?mode=duplicate',
      'Duplication du template',
      'height=1300,width=1400'
    );
  }

  onDeleteTemplateClick(systemId) {
    Notiflix.Confirm.show(
      'Confirmation',
      'Êtes-vous sûr de vouloir supprimer ce template ?',
      'Oui',
      'Non',
      () => {
        // Loading indicator with a message, and the new options
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff',
        });

        this.templateMakerService
          .deteleTemplate(
            this.BIXI_userAuthObject.tenantId,
            this.currentUserCompany.id,
            systemId
          )
          .subscribe({
            next: (templates) => {
              this.reloadCurrentRoute();
            },
            error: (error) => {
              Notiflix.Loading.remove();

              Notiflix.Notify.failure(error, {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              });
            },
          });
      },
      () => {},
      {
        width: '450px',
        okButtonBackground: '#04B76B',
        cancelButtonBackground: '#FF5E6D',
        titleColor: '#392C70',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
      }
    );
  }

  getEquipement() {
    this.activatedRoute.params.subscribe((params) => {
      this.equipementNo = params['equipementNo'];
      // checjk if equipementNo is in url
      if (this.equipementNo) {
        this.equipementNo = this.equipementNo.replace('%20', ' ');
        // Loading indicator with a message, and the new options

        this.homeService
          .getEquipement(
            this.BIXI_userAuthObject.tenantId,
            this.currentUserCompany.id,
            this.equipementNo
          )
          .subscribe({
            next: (equipement) => {
              this.equipement = equipement.value[0];

              this.openWorkOrderService
                .getWorkorders(
                  this.BIXI_userAuthObject.tenantId,
                  this.currentUserCompany.id,
                  "status eq 'In Progress'"
                )
                .subscribe({
                  next: (inProgressWorkOrdersData) => {
                    this.inProgressWorkOrdersData =
                      inProgressWorkOrdersData.value;
                  },

                  error: (error) => {},
                });

              this.openWorkOrderService
                .getWorkorders(
                  this.BIXI_userAuthObject.tenantId,
                  this.currentUserCompany.id,
                  "status eq 'Open'"
                )
                .subscribe({
                  next: (openWorkOrdersData) => {
                    this.openWorkOrdersData = openWorkOrdersData.value;
                  },

                  error: (error) => {},
                });
            },
            error: (error) => {
              // redirect user to home page
              // this.router.navigateByUrl(`home/${this.currentUserCompany.id}/${this.currentProfile.id}`);
            },
          });
      } else {
        // // redirect user to home page
        // this.router.navigateByUrl(`home/${this.currentUserCompany.id}/${this.currentProfile.id}`);
      }
    });
  }

  refreshEquipementEvent() {
    this.getEquipement();
  }

  changeProfile(profil) {
    if (profil) {
      const profileFound = this.currentProfiles.find(
        (currentProfile) => currentProfile.profil == profil
      );
      localStorage.setItem('bixi-currentProfile', JSON.stringify(profileFound));
      this.reloadCurrentRoute();

    } else {
      this.AuthService.logout();
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    currentUrl = currentUrl.replace('%20', ' ');
    location.reload()
  }

  logout() {
    Notiflix.Confirm.show(
      'Confirmation',
      'Êtes-vous sûr de vouloir vous déconnecter?',
      'Confirmer',
      'Annuler',
      () => {
        this.router.navigateByUrl('/');
      },
      () => {},
      {
        width: '450px',
        okButtonBackground: '#04B76B',
        cancelButtonBackground: '#FF5E6D',
        titleColor: '#392C70',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
      }
    );
  }

  sendTheNewValue(event) {
    this.filtredTemplates = this.templates.filter(function (template) {
      return (
        template.description
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        template.codeAssetCategory
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        template.status
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        template.templateColumns == event.target.value
      );
    });
  }
}
