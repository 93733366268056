import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/config/app.config.service';
import { Observable, catchError, map } from 'rxjs';
import { ErrorService } from 'src/app/core/shared/services/error.service';

@Injectable({
  providedIn: 'root'
})
export class NavBarService {

  defaultCompanyId = AppConfigService.settings.defaultCompanyId;
  bcCoreApiUrl = AppConfigService.settings.bcCoreApiUrl;
  customApiUrlSegment = AppConfigService.settings.customApiUrlSegment;
  bcEnvirName = AppConfigService.settings.bcEnvirName;
  defaultLg = AppConfigService.settings.defaultLanguage;
  schemaVersion = AppConfigService.settings.bcSchemaversion;

    constructor(private http: HttpClient,
      private errorService: ErrorService  ) { }
  
     // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'If-Match': '*',
      }),
    }; 
  
    getNotes(tenantId: any, companyId: string, $filter): Observable<any>{ 
      const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/eraNotes?$schemaversion="+this.schemaVersion+"&$filter="+$filter;     
      return this.http.get(url, this.httpOptions).pipe(
        map(result  => result as any),
        catchError(this.errorService.handleError));
    }

    editElement(tenantId: any, companyId: string, systemId: string, data, concatenatedUrl: string): Observable<any>{
      const schemaversion = !concatenatedUrl || concatenatedUrl== '' ? "?$schemaversion="+this.schemaVersion : "&$schemaversion="+this.schemaVersion 
      const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/eraNotes("+systemId+")"+concatenatedUrl+schemaversion;     
      return this.http.patch(url, data, this.httpOptions).pipe(
        map(result  => result as any),
        catchError(this.errorService.handleError));
    }
  
    createElement(tenantId: any, companyId: string, systemId: string, data, concatenatedUrl: string): Observable<any>{
      const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/eraNotes?$schemaversion="+this.schemaVersion;     
      return this.http.post(url, data, this.httpOptions).pipe(
        map(result  => result as any),
        catchError(this.errorService.handleError));
    }

    deleteElement(tenantId: any, companyId: string, systemId: string, concatenatedUrl: string): Observable<any>{
      const schemaversion = !concatenatedUrl || concatenatedUrl== '' ? "?$schemaversion="+this.schemaVersion : "&$schemaversion="+this.schemaVersion 
      const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/eraNotes("+systemId+")"+concatenatedUrl+schemaversion;     
      return this.http.delete(url, this.httpOptions).pipe(
        map(result  => result as any),
        catchError(this.errorService.handleError));
    }

  //  printWorkOrderTemplate(tenantId: any, companyId: string, workOrder:string): Observable<any>{
  //   const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/workOrderPrintLabels(00000000-0000-0000-0000-000000000000)/Microsoft.NAV.runReportWOPrintLabelPdf?$schemaversion="+this.schemaVersion;
  //   const body = {workOrderNo : workOrder}     
  //   return this.http.post(url, JSON.stringify(body),this.httpOptions).pipe(
  //     map(result  => result as Report),
  //     catchError(this.errorService.handleError));    
  // }   


}