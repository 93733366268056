<div class="modal fade" id="consumptionTrackModal" tabindex="-1" aria-labelledby="consumptionTrackModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" style="width: 70%">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-customized-title" id="consumptionTrackModalLabel">
          <i class="fa fa-eye me-2"></i><span class="fw-bold">Suivi de consommation</span>
        </h6>
        <button type="button" class="btn btn-danger btn-sm my-1" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <ejs-grid #consumptionTrackGrid [dataSource]="consumptionTrackData" gridLines="Horizontal"
          showColumnChooser="false" [allowSelection]="true" [allowMultiSorting]="true" rowHeight="30">
          <e-columns>
            <e-column field="itemN" headerText="N° d'article" width="130" textAlign="Left"></e-column>
            <e-column field="lastconsumedvariantcode" headerText="Dernière variante consommée" width="150"
              textAlign="Left"></e-column>
            <e-column field="lastconsumptiondate" headerText="Date de consommation" width="120" textAlign="Left">
              <ng-template #template let-data>
                {{
                data.lastconsumptiondate !== "0001-01-01"
                ? data.lastconsumptiondate
                : ""
                }}
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</div>