import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Loading } from 'notiflix';
import { ProfilService } from '../../core/auth/services/profil.service';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { Router } from '@angular/router';
import { Company } from 'src/app/core/shared/models/company.model';
import { Profile } from 'src/app/core/shared/models/profile';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {

  public currentUser:string = "";
  public errorProfilMessage = "";
  public currentCompanyId:string = "";
  public currentProfileId: string = ''
  public companies: Company[] = [];
  public profiles: Profile[] = [];
  public profilFailed: boolean = false; 
  disableProfile: boolean = true
  disableConfirmationButton: boolean = true
  BIXI_userAuthObject: any = null
  userHasUniqCompany = false
  
  constructor(
    private profilService: ProfilService,
    private router: Router,
    private storageService: StorageService,    
  ) { }

  ngOnInit() {
    // check if user has data in browser storage, else redirect himn to login page
    if(!this.storageService.getItem("bixi-user-auth")){
      this.router.navigateByUrl('/auth/login')
    }
    this.getCompanies();
  }

  private setFailedAlert(display:boolean, message:string){
    this.errorProfilMessage = "Vous n'êtes pas un utilisateur de maintenance valide dans cette compagnie. Veuillez changer de compagnie ou communiquer avec votre administrateur";
    this.profilFailed = display;
  }

  public confirmProfilSelection(){
    Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });    
    this.router.navigateByUrl(`home/${this.currentCompanyId}/${this.currentProfileId}`);
  }

  public selectCompany(event: any){
    this.errorProfilMessage = ""
    this.currentCompanyId = event.target.value
    this.currentProfileId = ''
    this.profilFailed = false
    
    // filter compagnies with compagny Id to get tge compagnie object
    const foundCompagny = this.companies.filter(compagny => compagny.id == this.currentCompanyId)
    if(foundCompagny.length > 0){
      this.storageService.setItem("bixi-currentCompany", JSON.stringify(foundCompagny[0]));
    }
    this.getProfiles()
  }

  private getCompanies(){
    // get BIXI User Auth as Object
    this.BIXI_userAuthObject = JSON.parse(this.storageService.getItem('bixi-user-auth')!)
    this.profilService.getCompanies(this.BIXI_userAuthObject).subscribe({
      next: (companies) => {
        this.companies = companies;
        this.confirmButtonStateCheck()
        Loading.remove();       
        // check if user has only one compamy
        if(this.companies.length == 1){
          // save user has uniq company state
          this.userHasUniqCompany = true
          // get the companyID by index 0
          this.currentCompanyId = this.companies[0].id
          this.storageService.setItem("bixi-currentCompany", JSON.stringify(this.companies[0])); 
          // invoke user profile using the currentCompanyId
          this.getProfiles()
        }
      },
      error: (error) => {
        this.setFailedAlert(true, error?.message);
        Loading.remove();  
      },
    })     
  }

  public getProfiles(){
    // this.currentCompanyId = 
    this.profilService.getProfiles(this.BIXI_userAuthObject, this.currentCompanyId).subscribe({
      next: (profiles) => {
        this.profiles = profiles;

        this.storageService.setItem("bixi-currentProfiles", JSON.stringify(this.profiles));

        // loop on profiles array and check if user has a default profile in profiles in array
        this.profiles.forEach(profile => {

           // check if user has a default profile in profiles in array
          if(profile.default){
            this.currentProfileId = profile.id
            this.storageService.setItem("bixi-currentProfile", JSON.stringify(profile));

            // check if user has uniq company
            if(this.userHasUniqCompany){
              this.router.navigateByUrl(`home/${this.currentCompanyId}/${this.currentProfileId}`);
            }
          }
        })

        setTimeout(() => {
          this.confirmButtonStateCheck()
          Loading.remove();
        })

        // enable and disable profile select input if the profile array is empty or not
        if(this.profiles.length > 0){
          this.disableProfile = false
        }else{
          // initialize profile select input
          this.disableProfile = true
        }             
      },
      error: (error) => {
        this.setFailedAlert(true, error);
        Loading.remove();  
      },
    })     
  }

  onProfileChange(event: any){

    if(event.target.value.trim() !== ''){
      this.currentProfileId = event.target.value

    // filter profiles with profile Id to get this cprofile object
      const foundProfile = this.profiles.filter(profile => profile.id == this.currentProfileId)

      if(foundProfile.length > 0){
        this.storageService.setItem("bixi-currentProfile", JSON.stringify(foundProfile[0]));
      }

    }else{
      this.currentProfileId = ''
    }
    
    this.confirmButtonStateCheck()
  }

  confirmButtonStateCheck(){

    // if user has compagnies and profiles and has selected a profile, then enable confirmation button
    if(this.companies.length > 0 && this.profiles.length > 0 && this.currentProfileId.trim() != ''){
      this.disableConfirmationButton = false
    }if(this.companies.length > 0 && this.profiles.length > 0 && this.currentProfileId.trim() == ''){

      // if user has compagnies and profiles and has profile but has not selected any profile
      this.disableConfirmationButton = true
    }else if(this.companies.length > 0 && this.profiles.length == 0){

      // if user has compagnies and does not have profiles, disable confirmation button
      this.disableConfirmationButton = true
    }else if(this.companies.length == 0 && this.profiles.length == 0){

      // if user does not have compagnies and profiles either, then disable confirmation button
      this.disableConfirmationButton = true
    }
  }
}
