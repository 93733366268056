<div class="modal fade" id="actionDetailModal" tabindex="-1" aria-labelledby="actionDetailModalLabel" aria-hidden="true"
  data-bs-backdrop="static">
  <div class="modal-dialog modal-lg my-5" style="width: 90%; overflow-y: initial !important">
    <div class="modal-content">
      <div class="modal-header py-2 bg-light">
        <h5 class="modal-title text-primary modal-customized-title" id="actionDetailModalLabel">
          {{ actionDetailModalTitle }}
        </h5>
        <button type="button" class="btn btn-danger btn-sm my-1" (click)="refreshEquipments()">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body py-2" style="height: 82vh;overflow-y: auto;">
        <div class="card">
          <div class="card border">
            <div *ngIf="currentWorkorder" class="card-header">
              <h5 class="mb-0">
                {{
                currentBTDetailNo !== ""
                ? currentBTDetailNo
                : currentWorkorder.no
                }}
                /
                {{
                currentWorkorder.correctiveMaintenance
                ? "Correctif"
                : "Preventif"
                }}
                / Réparation de vélo
              </h5>
            </div>
          </div>
        </div>

        <div *ngIf="
            taskDetailSelected !== null &&
            taskDetailSelected.workOrdersNo.length > 1
          " class="alert alert-warning" style="width: 50%; position: relative; top: 10px">
          Cette tâche existe sur
          {{ taskDetailSelected.workOrdersNo.length }} bon(s) de travail :
          <strong *ngFor="
              let workOrderNo of taskDetailSelected.workOrdersNo;
              let l3 = index
            ">
            {{ workOrderNo }}
          </strong>
        </div>

        <div class="row pl-0 pr-0">
          <div class="template-demo pl-0 pr-0">
            <a class="nav-link float-right pl-0" (click)="reopenTasksBtnsControl()"><span
                class="btn btn-outline-primary btn-sm"><i class="{{ chevronIconClass }}"></i> </span></a>
            <a class="nav-link float-right pl-0" *ngIf="editWorkOrderFeatures?.validate"
              (click)="onSelectedAllValidateClick(true)"><span class="btn btn-primary btn-sm"
                [ngClass]="isUserBlocked? 'disabled border-primary': ''"><i class="fa fa-tasks mr-2"></i><small> Valider
                  tout</small></span></a>
            <a class="nav-link float-right pl-0 pr-1" *ngIf="editWorkOrderFeatures?.validate"
              (click)="onSelectedGeneralValidateClick()"><span class="btn btn-outline-primary btn-sm"
                [ngClass]="isUserBlocked? 'disabled border-primary': ''"><i class="fa fa-tasks mr-2"></i><small> Valider
                  la sélection</small></span></a>
            <a class="nav-link float-right pl-0 pr-3" *ngIf="editWorkOrderFeatures?.terminate"
              (click)="onSelectedAllFinishedClick(true)"><span class="btn btn-primary btn-sm"
                [ngClass]="isUserBlocked? 'disabled border-primary': ''"><i class="fas fa-save mr-2"></i><small>
                  Terminer tout</small></span></a>
            <a class="nav-link float-right pl-0 pr-1" *ngIf="editWorkOrderFeatures?.terminate"
              (click)="onSelectedGeneralFinishedClick()"><span class="btn btn-outline-primary btn-sm"
                [ngClass]="isUserBlocked? 'disabled border-primary': ''"><i class="fas fa-save mr-2"></i><small>
                  Terminer la sélection</small></span></a>
            <a class="nav-link float-right pr-0 pl-0 d-none"><span class="btn btn-outline-primary btn-sm mr-2"><i
                  class="fas fa-check mr-2"></i><small> Sélectionner tout</small></span></a>
          </div>
          <div class="template-demo pl-0 reopenTasksSection mr-5" *ngIf="displayReopenTasksBtns">
            <a class="nav-link float-right pl-0" (click)="onReopenGeneralClick('validated', true)"
              *ngIf="editWorkOrderFeatures?.reopenValidatedTask"><span class="btn btn-primary btn-sm"
                [ngClass]="isUserBlocked? 'disabled border-primary': ''"><i class="fa fa-tasks mr-2"></i><small> Rouvrir
                  toutes les tâches validées</small></span></a>
            <a class="nav-link float-right pl-0 pr-1" (click)="onReopenSelectedClick('validated')"
              *ngIf="editWorkOrderFeatures?.reopenValidatedTask"><span class="btn btn-outline-primary btn-sm"
                [ngClass]="isUserBlocked? 'disabled border-primary': ''"><i class="fa fa-tasks mr-2"></i><small> Rouvrir
                  les tâches validées sélectionnées</small></span></a>
            <a class="nav-link float-right pl-0 pr-3" (click)="onReopenGeneralClick('finished', true)"
              *ngIf="editWorkOrderFeatures?.reopenTerminatedTask"><span class="btn btn-primary btn-sm"
                [ngClass]="isUserBlocked? 'disabled border-primary': ''"><i class="fas fa-save mr-2"></i><small> Rouvrir
                  toutes les tâches terminées</small></span></a>
            <a class="nav-link float-right pl-0 pr-1" (click)="onReopenSelectedClick('finished')"
              *ngIf="editWorkOrderFeatures?.reopenTerminatedTask"><span class="btn btn-outline-primary btn-sm"
                [ngClass]="isUserBlocked? 'disabled border-primary': ''"><i class="fas fa-save mr-2"></i><small>
                  Rouvrir les tâches terminées sélectionnées</small></span></a>
            <a class="nav-link float-right pr-0 pl-0 d-none"><span class="btn btn-outline-primary btn-sm mr-2"><i
                  class="fas fa-check mr-2"></i><small> Sélectionner tout</small></span></a>
          </div>
        </div>
        <!-- Instruction -->
        <div class="accordion mt-2 accordion-solid-header" id="modalAccordion1" role="tablist">
          <div class="card border">
            <div class="accordion-item card-header">
              <h2 class="accordion-header my-0" id="headingTwo">
                <a class="accordion-button py-2" type="button" data-bs-toggle="collapse"
                  data-bs-target="#modalCollapseOne" aria-expanded="true" aria-controls="modalCollapseOne">
                  <i class="fas fa-question-circle mr-2"></i>Instructions
                </a>
              </h2>
            </div>
            <div id="modalCollapseOne" class="accordion-collapse collapse show" aria-labelledby="headingTwo"
              data-bs-parent="#modalAccordion1">
              <div class="accordion-body py-2">
                <ul class="list-group list-group-flush">
                  <li *ngIf="
                      currentTaskLineObjectClicked !== null &&
                      currentTaskLineObjectClicked.taskCategoryByDoc !==
                        undefined
                    " class="list-group-item">
                    {{
                    currentTaskLineObjectClicked.taskCategoryByDoc
                    .instructions
                    }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Dépenses -->
        <div class="accordion mt-2 accordion-solid-header" id="modalAccordion2" role="tablist">
          <div class="card border">
            <div class="accordion-item card-header">
              <h2 class="accordion-header my-0" id="headingTwo">
                <a class="accordion-button py-2" type="button" data-bs-toggle="collapse"
                  data-bs-target="#modalCollapseTwo" aria-expanded="true" aria-controls="modalCollapseTwo">
                  <i class="fa fa-credit-card mr-2"></i>Dépenses
                </a>
              </h2>
            </div>
            <div id="modalCollapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo"
              data-bs-parent="#modalAccordion2">
              <div class="accordion-body py-2">
                <div class="table-responsive">
                  <ejs-grid #expensesGrid [dataSource]="expenseWorkOrderLines" gridLines="Horizontal"
                    showColumnChooser="false" [allowSelection]="true" [allowSorting]="true" rowHeight="30"
                    [editSettings]="editSettings">
                    <ng-template #toolbarTemplate let-data>
                      <ejs-toolbar>
                        <e-items>
                          <e-item (click)="onFinishedClick($event, 'expenses')" #depFinished text="Terminer"
                            tooltipText="Terminer" prefixIcon="e-save" align="Right"
                            *ngIf="editWorkOrderFeatures?.terminate" [disabled]="isUserBlocked"></e-item>
                          <e-item (click)="onValidateClick($event, 'expenses')" #depFinished text="Valider"
                            tooltipText="Valider" prefixIcon="e-check" align="Right"
                            *ngIf="editWorkOrderFeatures?.validate" [disabled]="isUserBlocked"></e-item>
                          <e-item (click)="onAddNewLineClick('expenses')" #depAdd text="Ajouter" tooltipText="Ajouter"
                            prefixIcon="e-add" align="Right" *ngIf="editWorkOrderFeatures?.addExpense"
                            [disabled]="isUserBlocked"></e-item>
                          <e-item (click)="onDeleteClick($event, 'expenses')" #depRemove text="Retirer"
                            tooltipText="Retirer" prefixIcon="e-delete" align="Right"
                            *ngIf="editWorkOrderFeatures?.removeExpense" [disabled]="isUserBlocked"></e-item>
                        </e-items>
                      </ejs-toolbar>
                    </ng-template>

                    <e-columns>
                      <e-column type="checkbox" width="50"></e-column>
                      <e-column field="no" headerText="N°" width="100" textAlign="Left" [allowEditing]="false">
                        <ng-template #template let-data>
                          <a class="nav-link pe-auto" (click)="onLineClick(data, 'expenses')" style="
                              padding-left: 0px !important;
                              color: #007bff !important;
                            " href="#" data-bs-toggle="modal" data-bs-target="#updateExpensesModal">
                            {{ data.no }}
                          </a>
                        </ng-template>
                      </e-column>
                      <e-column field="description" headerText="Description" width="150" textAlign="Left"
                        [allowEditing]="false"></e-column>
                      <e-column field="quantity" headerText="Quantité" width="100" textAlign="Right">
                      </e-column>
                      <e-column field="qtyToConsume" headerText="À consommer" width="120" textAlign="Right"></e-column>
                      <e-column field="unitOfMeasureCode" headerText="UOM" width="100" textAlign="Left"
                        [allowEditing]="false"></e-column>
                      <e-column field="locationCode" headerText="Emplacement" width="120" textAlign="Left"
                        [allowEditing]="false"></e-column>
                      <e-column field="consummedWheelWONo" headerText="N° BT Roue à consommer" width="100"
                        textAlign="Left" [allowEditing]="false">
                        <ng-template #template let-data>
                          <a *ngIf="data?.usedforWheelconsumption && data?.consummedWheelWONo == ''"
                            data-toggle="tooltip" (click)="onOpenBTRoueScanModalClickEmptyData(data)"
                            data-placement="right" title="Scanner" class="nav-link" href="javascript:">
                            <i class="fa fa-qrcode menu-icon"></i>
                          </a>

                          <a *ngIf="data.usedforWheelconsumption && data?.consummedWheelWONo != ''"
                            data-toggle="tooltip" (click)="onRoueBTClick(data)" data-placement="right" title="Scanner"
                            class="nav-link" href="javascript:">
                            {{ data?.consummedWheelWONo }}
                          </a>
                        </ng-template>
                      </e-column>
                      <e-column field="eraFinished" headerText="Terminé" width="100" textAlign="Center"
                        [allowEditing]="false">
                        <ng-template #template let-data><i *ngIf="data.eraFinished === true"
                            class="fa fa-check"></i></ng-template>
                      </e-column>
                      <e-column field="eraApproved" headerText="Validé" width="100" textAlign="Center"
                        [allowEditing]="false">
                        <ng-template #template let-data><i *ngIf="data.eraApproved === true"
                            class="fa fa-thumbs-up"></i></ng-template>
                      </e-column>
                      <e-column field="systemCreatedBy" headerText="Inséré par" width="100" textAlign="Center"
                        [allowEditing]="false">
                        <ng-template #template let-data>
                          <span *ngIf="data.systemCreatedBy && currentTaskLineObjectClicked" class="p-1 rounded-pill"
                            [ngStyle]="{
                              backgroundColor:
                                data.createdByProfil === 'Evaluator'
                                  ? currentTaskLineObjectClicked.definition
                                      .evaluatorColor
                                  : data.createdByProfil === 'Mechanic'
                                  ? currentTaskLineObjectClicked.definition
                                      .mechanicColor
                                  : data.createdByProfil === 'Supervisor'
                                  ? currentTaskLineObjectClicked.definition
                                      .supervisorColor
                                  : data.createdByProfil === 'Administrator'
                                  ? currentTaskLineObjectClicked.definition
                                      .administratorColor
                                  : 'white'
                            }">
                            {{ data.insertedby }}
                          </span>
                        </ng-template>
                      </e-column>
                    </e-columns>
                  </ejs-grid>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Articles -->
        <div class="accordion mt-2 accordion-solid-header" id="modalAccordion3" role="tablist">
          <div class="card border">
            <div class="accordion-item card-header">
              <h2 class="accordion-header my-0" id="headingThree">
                <a class="accordion-button py-2" type="button" data-bs-toggle="collapse"
                  data-bs-target="#modalCollapseThree" aria-expanded="true" aria-controls="modalCollapseThree">
                  <i class="fa fa-list-alt mr-2"></i>Articles
                </a>
              </h2>
            </div>
            <div id="modalCollapseThree" class="accordion-collapse collapse show" aria-labelledby="headingThree"
              data-bs-parent="#modalAccordion3">
              <div class="accordion-body py-2">
                <span class="text-danger font-bold mb-1" *ngIf="isEraMissingVariantTaskItems">
                  <small><i class="fas fa-exclamation-triangle mr-2"></i>Veuillez remplir le code(s) variante
                    manquant(s)</small>
                </span>
                <div class="table-responsive">
                  <ejs-grid #itemsGrid [dataSource]="itemWorkOrderLines" gridLines="Horizontal"
                    showColumnChooser="false" [allowSelection]="true" [allowSorting]="true" rowHeight="30"
                    [editSettings]="editSettings">
                    <ng-template #toolbarTemplate let-data>
                      <ejs-toolbar>
                        <e-items>
                          <e-item (click)="onDuplicateNewLineClick($event)" #artAdd text="Supplément"
                            tooltipText="Supplément " prefixIcon="e-zoom-in-2" align="Right"
                            *ngIf="editWorkOrderFeatures?.addSupplement" [disabled]="isUserBlocked"></e-item>
                          <e-item (click)="onFinishedClick($event, 'items')" #artFinished text="Terminer"
                            tooltipText="Terminer" prefixIcon="e-save" align="Right"
                            *ngIf="editWorkOrderFeatures?.terminate" [disabled]="isUserBlocked"></e-item>
                          <e-item (click)="onValidateClick($event, 'items')" #artFinished text="Valider"
                            tooltipText="Valider" prefixIcon="e-check" align="Right"
                            *ngIf="editWorkOrderFeatures?.validate" [disabled]="isUserBlocked"></e-item>
                          <e-item (click)="onAddNewLineClick('items')" #artAdd text="Ajouter" tooltipText="Ajouter"
                            prefixIcon="e-add" align="Right" *ngIf="editWorkOrderFeatures?.addItem"
                            [disabled]="isUserBlocked"></e-item>
                          <!-- <e-item (click)="onAddNewLineClick('items')" #artAdd text="Ajouter" tooltipText="Ajouter"
                            prefixIcon="e-add" align="Right" [disabled]="isUserBlocked"></e-item> -->
                          <e-item (click)="onDeleteClick($event, 'items')" #artRemove text="Retirer"
                            tooltipText="Retirer" prefixIcon="e-delete" align="Right"
                            *ngIf="editWorkOrderFeatures?.removeItem" [disabled]="isUserBlocked"></e-item>
                        </e-items>
                      </ejs-toolbar>
                    </ng-template>
                    <e-columns>
                      <e-column type="checkbox" width="50"></e-column>
                      <e-column headerText="" width="40" allowSelection="false">
                        <ng-template #template let-data>
                          <a data-bs-toggle="modal" data-bs-target="#substItemsModal"><i
                              class="fa fa-th-list text-primary" (click)="getLineSubstituts(data)"></i></a>
                        </ng-template>
                      </e-column>
                      <e-column field="no" headerText="N°" width="100" textAlign="" [allowEditing]="false">
                        <ng-template #template let-data>
                          <a class="nav-link pe-auto" (click)="onLineClick(data, 'items')" style="
                              padding-left: 0px !important;
                              color: #007bff !important;
                            " href="#" data-bs-toggle="modal" data-bs-target="#updateItemsModal">
                            {{ data.no }}
                          </a>
                        </ng-template>
                      </e-column>
                      <e-column field="variantCode" headerText="Variante" width="100" textAlign="Center"
                        [allowEditing]="false">
                        <ng-template #template let-data>
                          <!-- <span *ngIf="data.variantCode">{{
                            getItemVariantCodeDescription(data)
                            }}</span> -->
                          <span *ngIf="data.variantCode">{{data.variantCode}}</span>
                          <span *ngIf="data.eraVariantExiste && !data.variantCode"
                            class="text-center fas fa-star text-danger"></span>
                        </ng-template>
                      </e-column>
                      <e-column field="description" headerText="Description" width="150" textAlign="Left"
                        [allowEditing]="false"></e-column>
                      <e-column field="documentNo" headerText="Bt Associé" width="100" textAlign="Left"
                        [allowEditing]="false"></e-column>
                      <e-column field="quantity" headerText="Quantité" width="100" textAlign="Right"></e-column>
                      <e-column field="qtyToConsume" headerText="À consommer" width="120" textAlign="Right"></e-column>
                      <e-column field="unitOfMeasureCode" headerText="UOM" width="100" textAlign="Left"
                        [allowEditing]="false"></e-column>
                      <e-column field="locationCode" headerText="Emplacement" width="120" textAlign="Left"
                        [allowEditing]="false"></e-column>
                      <e-column field="eraFinished" headerText="Terminé" width="100" textAlign="Center"
                        [allowEditing]="false">
                        <ng-template #template let-data><i *ngIf="data.eraFinished === true"
                            class="fa fa-check"></i></ng-template>
                      </e-column>
                      <e-column field="eraApproved" headerText="Validé" width="100" textAlign="Center"
                        [allowEditing]="false">
                        <ng-template #template let-data><i *ngIf="data.eraApproved === true"
                            class="fa fa-thumbs-up"></i></ng-template>
                      </e-column>
                      <e-column field="systemCreatedBy" headerText="Inséré par" width="100" textAlign="Center"
                        [allowEditing]="false">
                        <ng-template #template let-data>
                          <span *ngIf="data.systemCreatedBy && currentTaskLineObjectClicked" class="p-1 rounded-pill"
                            [ngStyle]="{
                              backgroundColor:
                                data.createdByProfil === 'Evaluator'
                                  ? currentTaskLineObjectClicked.definition
                                      .evaluatorColor
                                  : data.createdByProfil === 'Mechanic'
                                  ? currentTaskLineObjectClicked.definition
                                      .mechanicColor
                                  : data.createdByProfil === 'Supervisor'
                                  ? currentTaskLineObjectClicked.definition
                                      .supervisorColor
                                  : data.createdByProfil === 'Administrator'
                                  ? currentTaskLineObjectClicked.definition
                                      .administratorColor
                                  : 'white'
                            }">
                            {{ data.insertedby }}
                          </span>
                        </ng-template>
                      </e-column>
                    </e-columns>
                  </ejs-grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="btRoueScanModal" tabindex="-1" aria-labelledby="btRoueScanModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="width: 30%">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-customized-title fw-bold" id="chooserQuantityModalLabel">
          <i class="fas fa-qrcode mr-2"></i>
          Veuillez scanner un BT roue
        </h6>
        <button type="button" class="btn-close" (click)="onScanBTRoueModalClose()" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body py-2">
        <div class="form-group">
          <label for="recipient-name" class="col-form-label">Numéro du BT roue</label>

          <input #btScanInput type="text" name="btRoueNoToScan" #btRoueNoToScan="ngModel"
            [(ngModel)]="btRoueNoToScanValue" required class="form-control rounded-0 w-100 border-secondary mb-3"
            (change)="saveChangedQty($event)" />
          <div class="alert alert-danger p-1 my-1" *ngIf="btRoueNoToScan.errors?.['required']">
            Le numéro du BT roue est requis
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success btn-sm" data-bs-dismiss="modal" (click)="savedEmptyRoueBT()"
          [disabled]="!btRoueNoToScan.errors?.['required'] || isUserBlocked"
          *ngIf="editWorkOrderFeatures?.removeExpenseBtRoue">
          <i class="fa fa-save mr-2"></i>Sauvegarder
        </button>

        <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal" (click)="scanRoueBT()"
          [disabled]="btRoueNoToScan.errors?.['required']">
          <i class="fas fa-qrcode mr-2"></i>Scanner
        </button>
        <button type="button" (click)="cancelRoueBTScann()" class="btn btn-light btn-sm" data-bs-dismiss="modal">
          <i class="fa fa-times mr-2"></i>Annuler
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="chooserQuantityModal" tabindex="-1" aria-labelledby="chooserQuantityModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" style="width: 30%">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-customized-title" id="chooserQuantityModalLabel">
          <i class="fa fa-wrench modal-icon-title mr-2"></i>
          <span class="modal-title-style fw-bold">{{ actionDetailModalTitle }}</span>
        </h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body py-2">
        <div class="form-group">
          <label for="recipient-name" class="col-form-label">Choisir un multiplicateur</label>
          <input type="number" class="form-control rounded-0 w-100 border-secondary mb-3"
            (change)="saveChangedQty($event)" />
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal" (click)="addQuantityToInput()">
          <i class="fa fa-check mr-2"></i>Confirmer
        </button>
        <button type="button" class="btn btn-light btn-sm" data-bs-dismiss="modal">
          <i class="fa fa-times mr-2"></i>Annuler
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade dropdownModal" data-bs-focus="false" id="newExpensesAndItemsLine" tabindex="-1"
  aria-labelledby="templateLabel" aria-hidden="true" data-bs-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-h6-title-container" id="templateLabel">
          <i class="fa fa-plus modal-icon-title mr-2"></i>
          <span class="modal-title-style fw-bold">{{ formTemplateModalTitle }}</span>
        </h6>
      </div>
      <div class="modal-body pt-2 pb-2">
        <div class="row mt-2">
          <div class="grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div [hidden]="toggleDataType" class="forms-sample">
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">
                      {{ NbfiledTitle }}
                    </label>
                    <div class="col-sm-7">

                      <ejs-dropdownlist #noElement1 (open)="onDropdownOpened($event, 'expenses')"
                        [dataSource]="expensesList" aria-expanded="true" [enabled]="true" width=""
                        placeholder="Sélectionner une dépense" [fields]="expensesFields" [allowFiltering]="true"
                        (change)="onChangeEvent($event)" (filtering)="onFiltering($event)"></ejs-dropdownlist>
                    </div>
                  </div>

                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Numéro de dépense</label>
                    <div class="col-sm-7">
                      <span *ngIf="expenseNum !== ''"> {{ expenseNum }} </span>
                      <span style="color: red; font-size: 11px" *ngIf="expenseNum == ''">Aucune dépense n'a été
                        sélectionnée</span>
                    </div>
                  </div>

                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Quantité</label>
                    <div class="col-sm-7">
                      <input type="number" name="quantity" #quantity="ngModel" (input)="inputEvent($event)"
                        [(ngModel)]="expensesDataNgModel.quantity" required
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        class="form-control form-control-sm py-1" autofocus="autofocus" #qtyElement1 />
                      <div class="alert alert-danger p-1 my-1" *ngIf="quantity.errors?.['required']">
                        La quantité est requise
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Quantité à consommer</label>
                    <div class="col-sm-7">
                      <input type="number" name="qtyToConsume" #qtyToConsume="ngModel"
                        [(ngModel)]="expensesDataNgModel.qtyToConsume" required
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        class="form-control form-control-sm py-1" autofocus="autofocus" #qtyToConsumeElement1
                        [disabled]="true" />
                      <div class="alert alert-danger p-1 my-1" *ngIf="qtyToConsume.errors?.['required']">
                        La quantité à consommer requise
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Unité de mesure</label>
                    <div class="col-sm-7">
                      <select class="form-select form-select-sm py-1" [ngModel]="uomElement1DefaultValue" #uomElement1
                        [disabled]="true">
                        <option *ngFor="let unitOfMeasure of unitsOfMeasureList" [value]="unitOfMeasure.code">
                          {{ unitOfMeasure.displayName }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Profil</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control form-control-sm py-1" [value]="currentProfile.profil"
                        autofocus="autofocus" [disabled]="true" #createdByProfilElement1 />
                    </div>
                  </div>

                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Code d'équipement</label>
                    <div class="col-sm-7">
                      <input type="text" [value]="
                          currentTaskLineObjectClicked != null
                            ? currentTaskLineObjectClicked.codeTaskCategory
                            : ''
                        " class="form-control form-control-sm py-1" [disabled]="true" #codeTaskCategoryElement1 />
                    </div>
                  </div>
                  <div class="float-right mt-3">
                    <button type="button" class="btn btn-primary btn-sm mr-2" data-bs-dismiss="modal"
                      (click)="createNewLine('Expense')"
                      [disabled]="expenseNum == '' || quantity.errors?.['required'] || qtyToConsume.errors?.['required']">
                      <i class="fa fa-save mr-2"></i> Créer
                    </button>
                    <button class="btn btn-light btn-sm" (click)="onCancelNewLine()" data-bs-dismiss="modal">
                      <i class="fa fa-times mr-2"></i>Annuler
                    </button>
                  </div>
                </div>

                <div [hidden]="!toggleDataType" class="forms-sample">
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Scan</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control form-control-sm py-1" #scanItemInput
                        (keyup.enter)="scanItem($event)" />
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">
                      {{ NbfiledTitle }}
                    </label>
                    <div class="col-sm-7">
                      <ejs-dropdownlist #noElement2 (open)="onDropdownOpened($event, 'items')" [dataSource]="itemsList"
                        placeholder="Sélectionner un article" [fields]="itemsFields" [allowFiltering]="true"
                        (change)="onChangeEvent_($event)" (filtering)="onFiltering_($event)"
                        [value]="itemDesc"></ejs-dropdownlist>
                    </div>
                  </div>

                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Numéro d'article</label>
                    <div class="col-sm-7">
                      <span *ngIf="itemNum !== ''"> {{ itemNum }} </span>
                      <span style="color: red; font-size: 11px" *ngIf="itemNum == ''">Aucun article n'a été
                        sélectionné</span>
                    </div>
                  </div>

                  <!-- <div [hidden]="hideItemVariant" class="form-group row mb-0 py-1"> -->

                  <div class="form-group row mb-0 py-1" [hidden]="!isEraVariantExistCurrentItem">
                    <label class="col-sm-5 col-form-label py-1">variante
                      <span class="fas fa-star text-danger text-small mr-1 float-right"></span>
                    </label>
                    <div class="col-sm-7">
                      <select class="form-select form-select-sm py-1" [ngModel]="itemVariantElement2DefaultValue"
                        #itemVariantElement2 (change)="onAddItemVariantChange($event)">
                        <option [value]=""></option>
                        <option *ngFor="let itemVariant of itemVariants" [value]="itemVariant.code">
                          {{ itemVariant.description }}
                        </option>
                      </select>
                      <div class="alert alert-danger p-1 my-1" *ngIf="!itemVariantElement2DefaultValue">
                        La variante est requise
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Quantité</label>
                    <div class="col-sm-7">
                      <input type="number" name="quantity2" #quantity2="ngModel" (input)="inputEvent_item($event)"
                        [(ngModel)]="itemsDataNgModel.quantity" required
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        class="form-control form-control-sm py-1" autofocus="autofocus" #qtyElement2 />
                      <div class="alert alert-danger p-1 my-1" *ngIf="quantity2.errors?.['required']">
                        La quantité est requise
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Quantité à consommer</label>
                    <div class="col-sm-7">
                      <input type="number" name="qtyToConsume2" #qtyToConsume2="ngModel"
                        [(ngModel)]="itemsDataNgModel.qtyToConsume" required
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        class="form-control form-control-sm py-1" autofocus="autofocus" #qtyToConsumeElement2
                        [disabled]="true" />
                      <div class="alert alert-danger p-1 my-1" *ngIf="qtyToConsume2.errors?.['required']">
                        La quantité à consommer requise
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Unité de mesure</label>
                    <div class="col-sm-7">
                      <select class="form-select form-select-sm py-1" [ngModel]="uomElement2DefaultValue" #uomElement2
                        [disabled]="true">
                        <option *ngFor="let unitOfMeasure of unitsOfMeasureList" [value]="unitOfMeasure.code">
                          {{ unitOfMeasure.displayName }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Profil</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control form-control-sm py-1" [value]="currentProfile.profil"
                        autofocus="autofocus" [disabled]="true" #createdByProfilElement2 />
                    </div>
                  </div>

                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Emplacement</label>
                    <div class="col-sm-7">
                      <select class="form-select form-select-sm py-1" #locationCodeElement2>
                        <option *ngFor="let location of locationsList" [value]="location.code">
                          {{ location.displayName }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Code d'équipement</label>
                    <div class="col-sm-7">
                      <input type="text" [value]="
                          currentTaskLineObjectClicked != null
                            ? currentTaskLineObjectClicked.codeTaskCategory
                            : ''
                        " class="form-control form-control-sm py-1" [disabled]="true" #codeTaskCategoryElement2 />
                    </div>
                  </div>
                  <div class="float-right mt-3">
                    <button type="button" class="btn btn-primary btn-sm mr-2" data-bs-dismiss="modal"
                      (click)="createNewLine('Item')"
                      [disabled]="itemNum == '' || quantity2.errors?.['required'] || qtyToConsume2.errors?.['required'] || 
                      (isEraVariantExistCurrentItem && !itemVariantElement2DefaultValue && !editWorkOrderFeatures.EnableAddItemBtnWhenMissingVariant)">
                      <i class="fa fa-save mr-2"></i>Créer
                    </button>
                    <button class="btn btn-light btn-sm" (click)="onCancelNewLine()" data-bs-dismiss="modal">
                      <i class="fa fa-times mr-2"></i>Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="updateExpensesModal" tabindex="-1" aria-labelledby="updateExpensesModal" aria-hidden="true"
  data-bs-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-h6-title-container" id="updateExpensesModal">
          <i class="fa fa-edit modal-icon-title mr-2"></i>
          <span class="modal-title-style fw-bold">Modifier la dépense</span>
        </h6>
      </div>
      <div class="modal-body pt-2 pb-2">
        <div class="row mt-2">
          <div class="grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div [hidden]="toggleDataType" class="forms-sample">
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Description</label>
                    <div class="col-sm-7">
                      <span> {{ editSelectedLineObject.description }} </span>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Numéro de dépense</label>
                    <div class="col-sm-7">
                      <span> {{ editSelectedLineObject.no }} </span>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Quantité</label>
                    <div class="col-sm-7">
                      <input type="number" name="quantity_" #quantity_="ngModel"
                        (input)="inputEvent_edit_expenses($event)" [(ngModel)]="editSelectedLineObject.quantity"
                        required
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        class="form-control form-control-sm py-1" autofocus="autofocus" id="qtyEditElement1"
                        [disabled]="isQtyFiledExpenseItemDisabled()" />
                      <div class="alert alert-danger p-1 my-1" *ngIf="quantity_.errors?.['required']">
                        La quantité est requise
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Quantité à consommer</label>
                    <div class="col-sm-7">
                      <input type="number" name="qtyToConsume" #qtyToConsume="ngModel"
                        [(ngModel)]="editSelectedLineObject.qtyToConsume" required
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        class="form-control form-control-sm py-1" autofocus="autofocus" #qtyToConsumeEditElement1
                        [disabled]="isQtyToConsumeFieldExpenseItemDisabled()" />
                      <div class="alert alert-danger p-1 my-1" *ngIf="qtyToConsume.errors?.['required']">
                        La quantité à consommer est requise
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="">
          <button type="button" class="btn btn-primary btn-sm mr-2" data-bs-dismiss="modal"
            (click)="saveLineItemOrExpense('expenses')"
            [disabled]="quantity_.errors?.['required'] || qtyToConsume.errors?.['required'] || isUserBlocked">
            <i class="fa fa-save mr-2"></i>Modifier
          </button>
          <button class="btn btn-light btn-sm" (click)="onCancelNewLine()" data-bs-dismiss="modal">
            <i class="fa fa-times mr-2"></i>Annuler
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="updateItemsModal" tabindex="-1" aria-labelledby="updateItemsModal" aria-hidden="true"
  data-bs-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-h6-title-container" id="updateItemsModal">
          <i class="fa fa-edit modal-icon-title mr-2"></i>
          <span class="modal-title-style fw-bold">Modifier l'article</span>
        </h6>
      </div>
      <div class="modal-body pt-2 pb-2">
        <div class="row mt-2">
          <div class="grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div [hidden]="toggleDataType" class="forms-sample">
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Description</label>
                    <div class="col-sm-7">
                      <span> {{ editSelectedLineObject.description }} </span>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Numéro d'article</label>
                    <div class="col-sm-7">
                      <span> {{ editSelectedLineObject.no }} </span>
                    </div>
                  </div>

                  <!-- <div [hidden]="hideEditItemVariant" class="form-group row mb-0 py-1"> -->
                  <div class="form-group row mb-0 py-1" [hidden]="!editSelectedLineObject?.eraVariantExiste">
                    <label class="col-sm-5 col-form-label py-1">Variante
                      <span class="fas fa-star text-danger text-small mr-1 float-right"></span>
                    </label>
                    <div class="col-sm-7">
                      <select class="form-select form-select-sm py-1" (change)="onEditItemVariantChange($event)"
                        [ngModel]="editItemVariantElement2DefaultValue" #editItemVariantElement2
                        [disabled]="isUserBlocked">
                        <option [value]=""></option>
                        <option *ngFor="let editItemVariant of editItemVariants" [value]="editItemVariant.code">
                          {{ editItemVariant.description }}
                        </option>
                      </select>
                      <div class="alert alert-danger p-1 my-1" *ngIf="!editItemVariantElement2DefaultValue">
                        La variante est requise
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Quantité</label>
                    <div class="col-sm-7">
                      <input type="number" name="quantity_2" (input)="inputEvent_edit_item($event)"
                        #quantity_2="ngModel" [(ngModel)]="editSelectedLineObject.quantity" required
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        class="form-control form-control-sm py-1" autofocus="autofocus" id="qtyEditElement2"
                        [disabled]="isQtyFiledExpenseItemDisabled()" />
                      <div class="alert alert-danger p-1 my-1" *ngIf="quantity_2.errors?.['required']">
                        La quantité est requise
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Quantité à consommer</label>
                    <div class="col-sm-7">
                      <input type="number" name="qtyToConsume2_" #qtyToConsume2_="ngModel"
                        [(ngModel)]="editSelectedLineObject.qtyToConsume" required
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        class="form-control form-control-sm py-1" autofocus="autofocus" #qtyToConsumeEditElement2
                        [disabled]="isQtyToConsumeFieldExpenseItemDisabled()" />
                      <div class="alert alert-danger p-1 my-1" *ngIf="qtyToConsume2_.errors?.['required']">
                        La quantité à consommer est requise
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="">
          <button type="button" class="btn btn-primary btn-sm mr-2" data-bs-dismiss="modal"
            (click)="saveLineItemOrExpense('items')"
            [disabled]="quantity_2.errors?.['required'] || qtyToConsume2_.errors?.['required'] || isUserBlocked || 
            (editSelectedLineObject?.eraVariantExiste && !editItemVariantElement2DefaultValue && !editWorkOrderFeatures.EnableAddItemBtnWhenMissingVariant)">
            <i class="fa fa-save mr-2"></i>Modifier
          </button>
          <button class="btn btn-light btn-sm" (click)="onCancelNewLine()" data-bs-dismiss="modal">
            <i class="fa fa-times mr-2"></i>Annuler
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="substItemsModal" tabindex="-1" aria-labelledby="substItemsModal" aria-hidden="true"
  data-bs-backdrop="static">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-h6-title-container" id="substItemsModalTitle">
          <i class="mr-2 fa" class="fa fa-th-list modal-icon-title"></i> &nbsp;
          <span class="modal-title-style fw-bold">Substituts d'article
            <span class="text-danger fw-bold">{{ substitutsItemNo }}</span></span>
        </h6>
        <button type="button" class="btn btn-danger btn-sm my-1" (click)="onCancelSubstModal()" data-bs-dismiss="modal"
          id="closeSubstItemsModalBtn">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body pt-2 pb-2">
        <div class="row mt-2">
          <div class="grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <ejs-grid [dataSource]="substitutsItemData" gridLines="Horizontal" [allowSelection]="true"
                  [allowSorting]="true" rowHeight="30" [allowTextWrap]="true">
                  <e-columns>
                    <e-column headerText="N° Substitut" textAlign="Left">
                      <ng-template #template let-data>
                        <a rel="nofollow" style="cursor: pointer" class="text-info" (click)="
                            replaceSubstitut(
                              substitutsItemSysId,
                              substitutsItemNo,
                              data.substituteNo
                            )
                          ">{{ data.substituteNo }}
                        </a>
                      </ng-template>
                    </e-column>
                    <e-column field="description" headerText="Opération" textAlign="Left">
                    </e-column>
                    <e-column field="variantCode" headerText="Variante" textAlign="Left">
                    </e-column>
                    <e-column headerText="Quantité" textAlign="Right" headerTextAlign="Right">
                      <ng-template let-data #template>
                        <span class="rounded-pill px-2 py-1" [ngClass]="{
                            'badge bg-warning':
                              (data.inventory > data.inventoryCyclo &&
                                data.inventory <= data.inventoryBixi) ||
                              (data.inventory > data.inventoryBixi &&
                                data.inventory <= data.inventoryCyclo),
                            'badge bg-danger':
                              data.inventoryCyclo < data.inventory &&
                              data.inventoryBixi < data.inventory,
                            'badge bg-success':
                              data.inventory <= data.inventoryCyclo &&
                              data.inventory <= data.inventoryBixi
                          }">
                          {{ data.inventory }}
                        </span>
                      </ng-template>
                    </e-column>
                    <e-column field="inventoryBixi" headerText="Inventaire Bixi" headerTextAlign="Right"
                      textAlign="Right"></e-column>
                    <e-column field="inventoryCyclo" headerText="Inventaire Cyclo" headerTextAlign="Right"
                      textAlign="Right"></e-column>
                  </e-columns>
                </ejs-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="onRoueBTClickModal" tabindex="-1" aria-labelledby="onRoueBTClickModal" aria-hidden="true"
  data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-h6-title-container" id="onRoueBTClickModalTitle">
          <i class="fas fa-question-circle mr-2"></i>
          <span class="modal-title-style fw-bold">Confirmation</span>
        </h6>
        <button type="button" class="btn-close mt-1" (click)="closeOnRoueBTClickModal()"></button>
      </div>
      <div class="modal-body pt-3 pb-3">
        <span class="lead">Souhaitez-vous ouvrir ce bon de travail ou le remplacer ?</span>
      </div>

      <div class="modal-footer">
        <div class="">
          <button class="btn btn-primary btn-sm mr-2" (click)="openBTFromRoueBTModal()">
            <i class="fas fa-eye mr-2"></i>Ouvrir
          </button>
          <button class="btn btn-light btn-sm" [disabled]="isUserBlocked"
            *ngIf="editWorkOrderFeatures?.updateExpenseBtRoue" (click)="replaceBTFromRoueBTModal()">
            <i class="fas fa-exchange-alt mr-2"></i>Remplacer
          </button>
        </div>
      </div>
    </div>
  </div>
</div>