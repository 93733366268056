<nav class="sidebar sidebar-offcanvas position-fixed h-100" id="sidebar">
  <ul *ngIf="currentProfile.profil === 'Evaluator'" class="nav add-margin-top">
    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item dropend'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item dropend'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Scanner" class="nav-link" [routerLink]="
          '/home/' + currentUserCompany.id + '/' + currentProfile.id
        ">
        <i class="fa fa-qrcode menu-icon"></i>
        <span class="menu-title">Scanner</span>
      </a>
    </li>
    <!-- <li class="nav-item d-none">
      <a data-toggle="tooltip" data-placement="right" title="Validation" class="nav-link" [routerLink]="'/validationworkorder/'+currentUserCompany.id+'/'+currentProfile.id +'/serial-number/'+equipementNo" href="#">
        <i class="fa fa-check menu-icon"></i>
        <span class="menu-title">Valider les bons de travail</span>
      </a>
    </li> -->

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item d-none'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item dropend'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item d-none'
          : ''
      ">
      <a class="nav-link" data-toggle="tooltip" data-placement="right" title="Archives" [routerLink]="
          '/archiveworkorder/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo
        ">
        <i class="fa fa-folder-open menu-icon"></i>
        <span class="menu-title">Bons de travail archivés</span>
      </a>
    </li>

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item d-none'
          : pageName == 'bikepartslist'
          ? 'nav-item d-none'
          : ''
      ">
      <a data-toggle="tooltip" (click)="onEquipementAssocieClick()" data-placement="right" title="Équipements associés"
        class="nav-link pe-auto" href="javascript:" data-bs-whatever="@mdo">
        <i class="fa fa-cog menu-icon"></i>
        <span class="menu-title">Equipements associés</span>
      </a>
    </li>

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item d-none'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Suivi de consommation" class="nav-link pe-auto" href="#"
        data-bs-toggle="modal" data-bs-target="#consumptionTrackModal" data-bs-whatever="@mdo">
        <i class="fa fa-eye menu-icon"></i>
        <span class="menu-title">Suivi de consommation</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="sidebarFeatures.readTemplate">
      <a class="nav-link" data-toggle="tooltip" data-placement="right" title="Templates" [routerLink]="
          '/templates/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo
        " href="#">
        <i class="fa fa-cubes menu-icon"></i>
        <span class="menu-title">Templates</span>
      </a>
    </li>

    <!-- <li class='nav-item dropend'>
      <a data-toggle="tooltip" data-placement="right" title="Revue avant confirmation finale" class="nav-link pe-auto"
        href="#" data-bs-toggle="modal" data-bs-target="#reviewBeforeConfirmModal">
        <i class="fa fa-check menu-icon"></i>
        <span class="menu-title">Revue avant confirmation finale</span>
      </a>
    </li> -->
  </ul>

  <ul *ngIf="currentProfile.profil === 'Mechanic'" class="nav add-margin-top">
    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item dropend'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item dropend'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Scanner" class="nav-link" [routerLink]="
          '/home/' + currentUserCompany.id + '/' + currentProfile.id
        ">
        <i class="fa fa-qrcode menu-icon"></i>
        <span class="menu-title">Scanner</span>
      </a>
    </li>
    <!-- <li class="nav-item d-none">
      <a data-toggle="tooltip" data-placement="right" title="Validation" class="nav-link" [routerLink]="'/validationworkorder/'+currentUserCompany.id+'/'+currentProfile.id +'/serial-number/'+equipementNo" href="#">
        <i class="fa fa-check menu-icon"></i>
        <span class="menu-title">Valider les bons de travail</span>
      </a>
    </li> -->

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item d-none'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item dropend'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item d-none'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Archives" class="nav-link" [routerLink]="
          '/archiveworkorder/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo
        ">
        <i class="fa fa-folder-open menu-icon"></i>
        <span class="menu-title">Bons de travail archivés</span>
      </a>
    </li>

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item d-none'
          : ''
      ">
      <a data-toggle="tooltip" (click)="onEquipementAssocieClick()" data-placement="right" title="Équipements associés"
        class="nav-link pe-auto" href="javascript:" data-bs-whatever="@mdo">
        <i class="fa fa-cog menu-icon"></i>
        <span class="menu-title">Equipements associés</span>
      </a>
    </li>
    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item d-none'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Suivi de consommation" class="nav-link pe-auto" href="#"
        data-bs-toggle="modal" data-bs-target="#consumptionTrackModal" data-bs-whatever="@mdo">
        <i class="fa fa-eye menu-icon"></i>
        <span class="menu-title">Suivi de consommation</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="sidebarFeatures.readTemplate">
      <a data-toggle="tooltip" data-placement="right" title="Templates" class="nav-link" [routerLink]="
          '/templates/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo
        " href="#">
        <i class="fa fa-cubes menu-icon"></i>
        <span class="menu-title">Templates</span>
      </a>
    </li>
  </ul>

  <ul *ngIf="currentProfile.profil === 'Supervisor'" class="nav add-margin-top">
    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item dropend'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item dropend'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Scanner" class="nav-link" [routerLink]="
          '/home/' + currentUserCompany.id + '/' + currentProfile.id
        ">
        <i class="fa fa-qrcode menu-icon"></i>
        <span class="menu-title">Scanner</span>
      </a>
    </li>
    <!-- <li [class]="pageName == 'openWorkorder' ? 'nav-item d-none' : pageName == 'detailWorkorder' ? 'nav-item d-none' : pageName == 'home' ? 'nav-item dropend' : pageName == 'archiveWorkorder' ? 'nav-item d-none' : pageName == 'templateMaker' ? 'nav-item d-none' : pageName == 'templates' ? 'nav-item d-none' : pageName == 'validationWorkorder' ? 'nav-item d-none': '' ">
      <a data-toggle="tooltip" data-placement="right" title="Validation" class="nav-link" [routerLink]="'/validationworkorder/'+currentUserCompany.id+'/'+currentProfile.id +'/serial-number/'+equipementNo">
        <i class="fa fa-check menu-icon"></i>
        <span class="menu-title">Valider les bons de travail</span>
      </a>
    </li> -->

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item d-none'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item dropend'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item d-none'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Archives" class="nav-link" [routerLink]="
          '/archiveworkorder/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo
        " href="#">
        <i class="fa fa-folder-open menu-icon"></i>
        <span class="menu-title">Bons de travail archivés</span>
      </a>
    </li>

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item d-none'
          : pageName == 'bikepartslist'
          ? 'nav-item d-none'
          : ''
      ">
      <a data-toggle="tooltip" (click)="onEquipementAssocieClick()" data-placement="right" title="Équipements associés"
        class="nav-link pe-auto" href="javascript:" data-bs-whatever="@mdo">
        <i class="fa fa-cog menu-icon"></i>
        <span class="menu-title">Equipements associés</span>
      </a>
    </li>

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item d-none'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Suivi de consommation" class="nav-link pe-auto" href="#"
        data-bs-toggle="modal" data-bs-target="#consumptionTrackModal" data-bs-whatever="@mdo">
        <i class="fa fa-eye menu-icon"></i>
        <span class="menu-title">Suivi de consommation</span>
      </a>
    </li>
    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item dropend'
          : pageName == 'archiveWorkorder'
          ? 'nav-item dropend'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      " *ngIf="sidebarFeatures.readTemplate">
      <a data-toggle="tooltip" data-placement="right" title="Templates" class="nav-link" [routerLink]="
          '/templates/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo
        " href="#">
        <i class="fa fa-cubes menu-icon"></i>
        <span class="menu-title">Templates</span>
      </a>
    </li>
  </ul>

  <ul *ngIf="currentProfile.profil === 'Administrator'" class="nav add-margin-top">
    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item dropend'
          : pageName == 'archiveWorkorder'
          ? 'nav-item dropend'
          : pageName == 'templateMaker'
          ? 'nav-item dropend'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item dropend'
          : ''
      " *ngIf="sidebarFeatures.readTemplate">
      <a data-toggle="tooltip" data-placement="right" title="Templates" class="nav-link" [routerLink]="
          '/templates/' +
          currentUserCompany.id +
          '/' +
          currentProfile.id +
          '/serial-number/' +
          equipementNo
        " href="#">
        <i class="fa fa-cubes menu-icon"></i>
        <span class="menu-title">Templates</span>
      </a>
    </li>
    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item d-none'
          : pageName == 'bikepartslist'
          ? 'nav-item d-none'
          : ''
      ">
      <a data-toggle="tooltip" (click)="onEquipementAssocieClick()" data-placement="right" title="Équipements associés"
        class="nav-link pe-auto" href="javascript:" data-bs-whatever="@mdo">
        <i class="fa fa-cog menu-icon"></i>
        <span class="menu-title">Equipements associés</span>
      </a>
    </li>

    <li [class]="
        pageName == 'openWorkorder'
          ? 'nav-item dropend'
          : pageName == 'detailWorkorder'
          ? 'nav-item dropend'
          : pageName == 'home'
          ? 'nav-item d-none'
          : pageName == 'archiveWorkorder'
          ? 'nav-item d-none'
          : pageName == 'templateMaker'
          ? 'nav-item d-none'
          : pageName == 'templates'
          ? 'nav-item d-none'
          : pageName == 'validationWorkorder'
          ? 'nav-item d-none'
          : pageName == 'bikepartslist'
          ? 'nav-item dropend'
          : ''
      ">
      <a data-toggle="tooltip" data-placement="right" title="Suivi de consommation" class="nav-link pe-auto" href="#"
        data-bs-toggle="modal" data-bs-target="#consumptionTrackModal" data-bs-whatever="@mdo">
        <i class="fa fa-eye menu-icon"></i>
        <span class="menu-title">Suivi de consommation</span>
      </a>
    </li>



  </ul>
</nav>

<div class="modal fade" id="reviewBeforeConfirmModal" tabindex="-1" aria-labelledby="reviewBeforeConfirmModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" style="width: 65%">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-customized-title" id="reviewBeforeConfirmModalLabel">
          <i class="fa fa-check me-2"></i><span class="fw-bold">Revue avant confirmation finale</span>
        </h6>
        <button type="button" class="btn btn-danger btn-sm my-1 d-none" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-none">
          <table class="border border-secondary bg-light px-3 py-3 d-block" style="width: 100%;">
            <tr style="width:100%">
              <td style="width:80%">
                <h2>Tâches non terminées</h2>
                <div class="alert alert-success alert-dismissible fade show d-none_ d-block" role="alert">
                  <h4 class="mb-0">
                    <strong><span class="fa fa-check-circle mr-2"></span></strong>
                    Toutes les tâches sont terminées
                  </h4>
                </div>
              </td>
              <td style="width:300px">
              </td>
              <td></td>
            </tr>
            <tr style="width:100%" class="d-none">
              <td style="width:90%">
                <!-- <h4>1. VICO - Problème Vis Endommagée</h4>
                <h4>2. EAV - Remplacer Pneu et Tube</h4>
                <h4>3. VE2 - Ajuster Couette GPS</h4>
                <h4>4. VICO - Remplacer Plastique Guidon Gauche Haut</h4> -->
                <h4>1. description dépense</h4>
                <h4>2. description dépense</h4>
                <h4>3. description dépense</h4>
                <h4>4. description dépense</h4>
              </td>
              <td style="width:300px">
              </td>
              <td style="width:10%">
                <input type="checkbox" aria-label="Checkbox for following text input">
              </td>
            </tr>
          </table>
          <table class="border-none mt-4 bg-light px-3 py-3 d-block">
            <tr style="width:100%">
              <td style="width:80%">
                <h2>Articles non utilisés</h2>
                <div class="alert alert-success alert-dismissible fade show d-none_" role="alert">
                  <h4 class="mb-0">
                    <strong><span class="fa fa-check-circle mr-2"></span></strong>
                    Tous les articles sont terminés
                  </h4>
                </div>
              </td>
              <td style="width:300px">
              </td>
              <td></td>
            </tr>
            <tr class="d-none">
              <td style="width:90%">
                <h4>1. Code article - Code variante- Description</h4>
                <h4>2. Code article - Code variante- Description</h4>
                <h4>3. Code article - Code variante- Description</h4>
                <h4>4. Code article - Code variante- Description</h4>
              </td>
              <td style="width:300px">
              </td>
              <td style="width:10%">
                <input type="checkbox" aria-label="Checkbox for following text input">
              </td>
            </tr>
          </table>

          <div class="alert alert-warning alert-dismissible fade show mt-3 d-none" role="alert">
            <strong><span class="fa fa-info mr-2"></span></strong> Un ordre de transfert retour
            contenant les articles non utilisés sera automatiquement crée suite à la validation et un nouvel onglet vous
            permettra d'imprimer l'étiquette.
            <!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button> -->
          </div>
          <div class="alert alert-info alert-dismissible fade show mt-3" role="alert">
            <strong><span class="fa fa-check-circle mr-2"></span></strong> Aucun article ne devra être retourné : aucun
            ordre de transfert retour ne sera crée suite à la validation

            <!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button> -->
          </div>
        </div>
        <div class="d-none_">
          <div class="alert alert-success alert-dismissible fade show d-none_" role="alert">
            <h4 class="mb-0">
              <strong><span class="fa fa-check-circle mr-2"></span></strong>
              Ordre de transfert retour a été créé avec succès correctement, veuillez imprimer le code-barres associé
              avant de terminer
            </h4>
          </div>
          <img src="assets/imgs/codebardemo.png" class="d-none_ mx-auto text-center mt-3" style="margin-left:100px">

          <div class="alert alert-danger_ alert-dismissible fade show d-none" role="alert">
            <h4 class="mb-0"><strong><span class="fa fa-times-circle mr-2"></span></strong> Aucun ordre de retour n'est
              crée, veuillez terminer</h4>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <div class="d-none w-100">
          <button type="button" class="btn btn-primary mr-2 float-left">
            <i class="fa fa-arrow-left mr-2"></i>Retour
          </button>
          <!-- <button type="button" class="btn btn-success float-right">
            <i class="fa fa-check mr-2"></i>Valider
          </button> -->
          <button type="button" class="btn btn-success float-right">
            <i class="fa fa-check mr-2"></i>Valider et terminer
          </button>
        </div>
        <div class="d-none_ w-100">
          <button type="button" class="btn btn-primary mr-2 float-left">
            <i class="fa fa-print mr-2"></i>Imprimer
          </button>
          <button type="button" class="btn btn-success float-right">
            <i class="fa fa-check mr-2 ml-2"></i>Terminer
          </button>
        </div>
      </div>
    </div>
  </div>
</div>